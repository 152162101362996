import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {Block, Button, f7, Link, ListInput, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import appJsBridge from "../../androidjsbridge/AppJsBridge";
import {showSimpleToast} from "#/ui-components/toast/toast";
import {EditorModel, editorModel} from "../../mc/editor/EditorModel";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";
import i18n from "i18next";
import "../../vo/project/a11y/actions/action-common.scss"
import {CreateActionListUI} from "../../vo/project/a11y/actions/ActionUIList";
import {installedAppsModel} from "../../mc/installedapp/InstalledAppsModel";
import {Trans} from "react-i18next";
import {ProjectBase} from "#/htmls/index/vo/project/base/ProjectBase";
import {backOrToUrl} from "#/libs/fanfanlo/f7/f7RouteUtils";
import {MainRouter} from "#/htmls/index/pages/main/Main";
import {actionListRouter} from "#/htmls/index/pages/actionList/ActionList";
import {createSubUrlParamsString} from "#/htmls/index/pages/RhinoScriptEdtor/projectEditorUtils";
import {AddActionMultiButton} from "#/htmls/index/pages/RhinoScriptEdtor/AddActionMultiButton";
import {autobind} from "core-decorators";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {ScriptCodeTextAreaUI} from "#/htmls/index/pages/RhinoScriptEdtor/ScriptCodeTextArea";
import {runProject} from "#/htmls/index/vo/project/base/runProject";

let contentIndex = 0;
export class RhinoScriptEdtorContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.contentIndex = contentIndex++;
        const state = {script: "", packages:null, conf:null,
            // actionList:editorModel.actionList,
            project:editorModel.editingProject}
        this.state = state
    }

    onResume(){
        super.onResume();
        this.updateCode(editorModel.getActionListCode(), editorModel.actionList)
    }
    addEvents(){
        editorModel.addEventListener(EditorModel.events.codeChange, this.onCodeChange);
        let project = this.state.project;
        if(project){
            this.initProject(project);
        }
    }

    removeEvents(){
        editorModel.removeEventListener(EditorModel.events.codeChange, this.onCodeChange);
        let p = this.state.project;
        if(p){
            p.removeDomainEventListener(this);
        }
    }
    onCodeChange=(e)=>{
        this.updateCode(editorModel.getActionListCode(), editorModel.actionList)
    }

    updateCode=(code, actionList)=>{
        this.setState({script:code, actionList})
    }

    onTestClick2 =()=>{
        let script = ``;
        script = Dom7("#ta").val();
        if(!script || script.length < 3)return;
        let injects = [["dispatcher"], ["quicker"]]
        const s = JSON.stringify({script, injects})
        window.android.callScriptRunner(s);
    }

    onTestClick =()=>{
        runProject(this.state.project);
        // const s = this.state.project.projectScript;
        // window.android.callScriptRunner(s);
    }
    getPackages =()=>{
        const script = `com.fanfanlo.droid.js.JsReceiver.readAllPackages();`
        let s = appJsBridge.callScript(script);
        try{
            let packages = JSON.parse(s)
            this.setState({packages})
        }catch (e){
            showSimpleToast(`aa应用列表解析错误 ${s},${e}`)
        }
    }
    taOnChange =(e)=>{
        let code = Dom7("#ta").val();
        // editorModel.updateCode(code)
    }
    updatePageParams =(params)=>{
        if(params && params.id && this.state.pageParams?.id == params?.id){
            return;
        }
        this.setState({pageParams:params});
        this.initEditData(params.id)
    }
    initEditData =(id)=>{
        // let project = editorModel.setOrCreateEditProjectById(id);
        let project = editorModel.myProjects.getProjectById(id);
        if(!project){
            this.noProject();
            return;
        }
        let p = this.state.project;
        if(p == project)return;
        p?.removeDomainEventListener(this);
        this.initProject(project)
    }
    noProject =()=>{
        f7.dialog.alert(i18n.t("pageContents.scriptEditor.project.projectNotExist"), ()=>{
            backOrToUrl(MainRouter.path)
        })
    }
    initProject =(project)=>{
        // let projUrl = `${RhinoScriptEdtorRouter.p}${project.id}`
        // f7.views.main.router.updateCurrentUrl(projUrl)
        this.setState({project})
        editorModel.setEditProject(project);
        project.addDomainEventListener(this, ProjectBase.eventTypes.projectUpdated, this.onProjectUpdated);
        this.updateProject(project);
    }
    onProjectUpdated=(e)=>{
        let project = e.target;
        this.updateProject(project);
        // this.setState({project});
        // this.updateCode(project.actionListCode, project.editingTask.actionList)
    }
    updateProject(project){
        this.setState({project});
        this.updateCode(project.actionListCode, project.editingTask.actionList)
    }
    onTemproaryStorageClick =()=>{
        let bl = editorModel.myProjects.save()
        showSimpleToast(i18n.t(bl ? "pageContents.scriptEditor.temporaryStorageOk" :  "pageContents.scriptEditor.temporaryStorageFailed"))
    }
    onSyncStorageClick =()=>{

    }
    onProjectNameChange =(e)=>{
        let v = e?.target?.value
        this.state.project.javaData.name = v;
    }
    renderEmpty(){
        return (
            <>
                <div>wait for project</div>
            </>
        )
    }
    onActionListClick =()=>{
        // actionListModel.addEventListener()
    }
    render() {
        let p = this.state.project;
        if(!p){
            return this.renderEmpty();
        }
        // let link = `${actionListRouter.path}?caller=${f7.views.main.router.currentRoute.url}`;
        let eventType = ""
        if(p?.editingTask){
            // eventType = editorModel.myProjects.getTaskEditEventType(ProjectEditEnum.Add, p.editingTask)
            eventType = p?.editingTask.getEventType(ProjectEditEnum.Add)
        }
        // let link = `${actionListRouter.path}?caller=${f7.views.main.router.currentRoute.url}&event=${eventType}`;
        let link = `${actionListRouter.path}?${createSubUrlParamsString(f7.views.main.router.currentRoute.url, eventType)}`
        let actionList = p ?p.editingTask?.actionList : [];
        if(!p){
            return this.renderEmpty()
        }
        return (
            <div>
                <div className={"grid grid-cols-2"}>
                    <Button onClick={this.onTemproaryStorageClick}>
                        {i18n.t("pageContents.scriptEditor.buttonTemproaryStorage")}
                    </Button>
                    <Button onClick={this.onSyncStorageClick}>
                        {i18n.t("pageContents.scriptEditor.buttonStorage")}
                    </Button>
                </div>

                <div>

                    <form className={"list"} style={{margin:"0px"}}>
                        <ul>
                            <ListInput label={i18n.t("pageContents.scriptEditor.project.labelEditor.name.label")}
                                       onChange={this.onProjectNameChange}
                                       name="name" type="name" clearButton
                                       defaultValue={this.state.project?.javaData.name}
                                       placeholder={i18n.t("pageContents.scriptEditor.project.labelEditor.name.placeholder")}/>
                            <ListInput label={i18n.t("pageContents.scriptEditor.project.labelEditor.id.label")}
                                       name="id" type="text" readonly
                                       defaultValue={this.state.project?.javaData.id}
                                       placeholder={i18n.t("pageContents.scriptEditor.project.labelEditor.id.placeholder")}/>
                        </ul>
                    </form>
                </div>

                <div className={"grid grid-cols-3"}>
                    <Link onClick={this.onActionListClick} href={link}>{i18n.t(`routes.${actionListRouter.id}`)}</Link>

                    <AddActionMultiButton project={this.state.project} />
                    <Button id={"btnid1"} onClick={this.onTestClick}>
                        <Trans>
                            pageContents.scriptEditor.buttonRun
                        </Trans>
                    </Button>
                </div>
                <CreateActionListUI actionList={actionList}/>
                <ScriptCodeTextAreaUI project={this.state.project} />
            </div>
        )
    }
}
let rhinoIndex = 0;
@autobind
export class RhinoScriptEdtor extends ComponentBase {
    constructor(props) {
        super(props);
        installedAppsModel.checkHasMockMockPackages();
        this.rhinoIndex = rhinoIndex ++;
        this.content = React.createRef();
        // this.state = {pageParams: null};// {pageParams:{path:"/RhinoScriptEdtor/:params here"}}
        this.checkFetchInstalledAppList();
    }
    checkFetchInstalledAppList =()=>{
        if(!editorModel.editingProject)return;
        if(editorModel.actionList.length == 0)return;
        installedAppsModel.refreshPackages();
    }
    addEvents() {
        super.addEvents();
        Dom7(`#${RhinoScriptEdtorRouter.pageId}`).on("page:init", this.onPageInit);
    }

    onResume(){
        super.onResume();
        editorModel.dispatchEvent(new DataEvent(EditorModel.events.editorPageIn))
    }
    onPause(){
        super.onPause();
        editorModel.dispatchEvent(new DataEvent(EditorModel.events.editorPageOff))
    }
    removeEvents() {
        super.removeEvents();
        Dom7(`#${RhinoScriptEdtorRouter.pageId}`).off("page:init", this.onPageInit);
    }

    onPageInit = (e, page) => {
        if (!page) return;
        if(this.intialized){
            console.log("rhino page was intallized")
            return;
        }
        this.intialized = true;
        const params = page.route.params;
        this.content.current.updatePageParams(params)
    }
    onBackClick =(e)=>{
        const r = f7.views.main.router;
        // logs.router.print("fn onBackclick history is", r.history, "router is", r )
        if(r.history.length == 1 && r.currentRoute.url == r.history[0]){
            // console.log("force back")
            // logs.router.print("fn onBackclick force back")
            r.navigate(MainRouter)
            return;
        }
        r.back();
    }
    render() {
        let title = i18n.t(`routes.RhinoScriptEdtor`)
        return (
            <Page id={"page-id-RhinoScriptEdtor"} pageContent={false} className={"no-toolbar"} >
                <Navbar >

                    <div className="left">
                        <a href="#" onClick={this.onBackClick} className="link ">
                            <i className="icon icon-back"></i>
                            <span></span>
                        </a>
                    </div>
                    <div className={"title"}>
                        {title}
                    </div>
                </Navbar>
                {/*<OrderListFab/>*/}
                <PageContent>
                    <RhinoScriptEdtorContent ref={this.content} p={this}/>
                </PageContent>
            </Page>
        )
    }
}
// const RPage = memo(RhinoScriptEdtor)
export const RhinoScriptEdtorRouter = {
    id:"RhinoScriptEdtor",
    pageId: "page-id-RhinoScriptEdtor",
    p: "/RhinoScriptEdtor/",
    path: "/RhinoScriptEdtor/:id",
    component: RhinoScriptEdtor,
    name: "RhinoScriptEdtor"
}
