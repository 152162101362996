import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {AccordionContent, Button, f7, Link, List, ListItem, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import i18n from "i18next";
import {editorModel} from "#/htmls/index/mc/editor/EditorModel";
import {RhinoScriptEdtorRouter} from "#/htmls/index/pages/RhinoScriptEdtor/RhinoScriptEdtor";
import {showSimpleToast} from "#/ui-components/toast/toast";
import {MyProjects} from "#/htmls/index/mc/editor/my-projects";

class Project extends ComponentBase{
    constructor(props) {
        super(props);
        this.state = {};
    }
    onDelClick=()=>{
        this.showDelDialog();
    }
    showDelDialog=()=>{
        let p = this.props.project;
        let text = i18n.t("pageContents.myProjects.project.delDialogMsg", {id:p.name || p.id})
        f7.dialog.confirm(text, i18n.t("pageContents.myProjects.project.delDialogTitle"), ()=>{
            let bl =  editorModel.myProjects.removeProject(p);
            if(bl){
                editorModel.myProjects.save();
                showSimpleToast(i18n.t("pageContents.myProjects.project.delOk", {id:p.name || p.id}))
            }else{
                showSimpleToast("delete project failed")
            }
        })
    }
    render(){
        let project = this.props.project;

        return(
            <ListItem accordionItem>
                <div  slot={"title"}>
                    <div>
                        id:{project.id}
                    </div>
                    <div>
                        name:{project.name}
                    </div>
                </div>
                <AccordionContent>
                    <div>

                        <div className={"grid-cols-2 grid-gap"}>
                            <Link href={RhinoScriptEdtorRouter.p + project.id}>{i18n.t("pageContents.myProjects.project.buttonEdit")}</Link>
                            <Button onClick={this.onDelClick}>{i18n.t("pageContents.myProjects.project.buttonDel")}</Button>
                        </div>
                    </div>
                </AccordionContent>
            </ListItem>
        )
    }
}


export class ProjectListContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {projectList: editorModel.myProjects.projectList};
    }
    addEvents() {
        super.addEvents();
        editorModel.myProjects.addDomainEventListener(this, MyProjects.events.projectListUpdated, this.onProjectListUpdated)
    }

    onProjectListUpdated = ()=>{
        let list = editorModel.myProjects.projectList;
        this.setState({projectList: list});
        editorModel.myProjects.compareProjects(list);
    }
    waitingRender(){

        return(
            <div>i18n.t("pageContents.myProjects.list.waitingForList")</div>
        )
    }
    emptyRender(){

        return(<div>
            <div>{i18n.t("pageContents.myProjects.list.emptyList")}</div>
        </div>)
    }

    render() {

        let list = this.state.projectList;
        if(!list)return this.waitingRender;
        if(list.length == 0)return this.emptyRender();
        return (
            <>
                <List accordionList  style={{margin:"2px"}}>
                    <ul>
                        {list.map((project, index)=>{
                            let my = editorModel.myProjects;
                            return <Project project={project} key={index.toString()} />
                        })}
                    </ul>
                </List>
            </>
        )
    }
}
export class MyProjectsContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {myProjects:editorModel.myProjects}
    }

    render() {
        return (
            <>
                <ProjectListContent />
            </>
        )
    }
}

let pageCount = 0;

class MyProjectsPage extends ComponentBase {
    constructor(props) {
        super(props);
        this.pageId = `myProjectsRouter-` + (++pageCount);
        this.pageSelectId = "#" + this.pageId;
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    onPageInit = (e, page) => {
        if (!page) return;
        this.setState({pageParams: page.route.params})
    }

    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={i18n.t(`routes.myProjects}`)} backLink="返回"/>

                <PageContent>
                    <MyProjectsContent/>
                </PageContent>
            </Page>
        )
    }
}

//<Link href={myProjectsRouter.path}>{myProjectsRouter.name}</Link>
export const myProjectsRouter = {
    id: "MyProjects",
    p: "/my-projects/",
    path: "/my-projects/",
    component: MyProjectsPage,
    name: "授权操作"
}
