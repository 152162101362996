
import * as i18n from "i18next";
import {Block, f7} from "framework7-react";
import React from "react";
import appJsBridge from "../../androidjsbridge/AppJsBridge";
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import Member from "../../../../libs/fanfanlo/remotes/Member";
import TMs from "../../../../remotes/TMs";
import {VisitModel, visitModel} from "../../../../mc/VisitModel";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";
import {showSimpleToast} from "../../../../ui-components/toast/toast";
import {routeMap} from "../../routes";
import {mainModelBase} from "../../../../mc/MainModelBase";
import {websiteConf} from "../../../../confs/local.conf";

const sha256 = require('sha256');
const ClipboardJS = require('clipboard')
export class UserContent extends ComponentBase{
  constructor(props) {
    super(props);
    this.state = {globalUser:visitModel.globalUser, logged:visitModel.logged};

  }
  refreshUser = () =>{
    console.log("rrrrrrrrrrrrrrefresh")
    let state = {globalUser:visitModel.globalUser, logged:visitModel.logged}
    this.setState(state)
  }
  onResume() {
    super.onResume();
    new ClipboardJS('.user-uuid-copy');
  }
  addEvents() {
    super.addEvents();
    visitModel.addEventListener(VisitModel.events.logChanged, this.refreshUser)
  }

  onLogoutClick =() =>{

    let member = this._logoutMember = new Member(TMs.TUser, TMs.MUserLogout, {}, (res)=>{
      console.log(`tms.TSession, tms.MSessionLogout res is`, res)
      visitModel.logout();
      appJsBridge.callUserLogout();
    }, (member)=>{
      console.log(`tms.TSession, tms.MSessionLogout net error is`, member.netError, member.netErrorInfo);
    }, (member)=>{
      console.log(`tms.TSession, tms.MSessionLogout data error is`, member.dataError, member.dataErrorInfo);
    }, (member) =>{
      console.log(`tms.TSession, tms.MSessionLogout complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
    }, null, true, true);
    // mainModel.dispatchEvent(new Event2(MainModel.events.addWaitingMember, member.send));
    mainModelBase.addWaitingMember(member.send)
  }
  onSetPwClick =()=>{
    // 转到动态配置里面去
    let title = i18n.t("components.userContent.dialog.password.title");
    let text = i18n.t("components.userContent.dialog.password.text")
    let d = f7.dialog.prompt(text, title, (code)=>{
      console.log("ok", code)
      if(code.length >= 8){
        this.updatePw(code)
      }else{
        let simple = i18n.t("components.userContent.dialog.password.simple")
        showSimpleToast(simple)
      }
    }, ()=>{
      console.log("canceled")
    }, "");
  }
  updatePw =(code)=>{
    VisitModel.createPw(code, (pw)=>{
      this._updatePw(pw)
    })
  }
  _updatePw = (pw)=>{
    console.log("ppppppppppppppppp", pw);
    let member = new Member(TMs.TUser, TMs.MUserUpdatePw, {pw}, (res)=>{
      console.log(`TMs.TUser, TMs.MUserUpdatePw res is`, res)
      showSimpleToast(i18n.t("components.userContent.toast.updatePwOk"))
    }, (member)=>{
      console.log(`TMs.TUser, TMs.MUserUpdatePw net error is`, member.netError, member.netErrorInfo);
    }, (member)=>{
      console.log(`TMs.TUser, TMs.MUserUpdatePw data error is`, member.dataError, member.dataErrorInfo);
    }, (member) =>{
      console.log(`TMs.TUser, TMs.MUserUpdatePw complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
    }, null, true, true);
    // mainModel.dispatchEvent(new Event2(MainModel.events.addWaitingMember, member.send));
    mainModelBase.addWaitingMember(member.send);
  }
  render = ()=> {
    if(this.state.isLogged == false){
      return (<Block>未登录</Block>)
    }
    let platform = websiteConf.platformConf.platform;
    return (
        <>
            <Block>
              {platform == "cn" &&

                <div>

                  <span>{i18n.t("components.userContent.email.name")}</span>
                  <span>{this.state.globalUser.mobile}</span>
                </div>
              }
              {platform == "play" &&
                <div>
                  <span>{i18n.t("components.userContent.email.name")}</span>
                  <span>{this.state.globalUser.email}</span>
                </div>
              }
            </Block>
          <Block>

          </Block>
          <Block>
            <div>
              <a  onClick={this.onSetPwClick} className="button button-outline color-green inline">
                {i18n.t("components.userContent.password.buttonName")}
              </a>
              <a  onClick={this.onLogoutClick} className="button button-outline color-green inline">
                {i18n.t("components.userContent.logout.buttonName")}
              </a>
            </div>
          </Block>
          <Block>
            <div>
              <a href={routeMap.UnsubscribeRouter.path}>
                {i18n.t("components.userContent.cancelAccount.buttonName")}
              </a>
            </div>
          </Block>
        </>
    )
  }
}
