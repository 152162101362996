import {EventDispatcher} from "../../../../libs/fanfanlo/events/EventDispatcher";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";
import {WebViewJs} from "#/android/WebViewJs";
import {IActionAlias} from "#/htmls/index/vo/project/base/IAction";
import {IProject} from "#/htmls/index/vo/project/base/IProject";
import {MyProjects} from "#/htmls/index/mc/editor/my-projects";
import {ProjectBase} from "../../vo/project/base/ProjectBase";

const events = {
    codeChange:"codeChange",
    codeInsert:"codeInsert",
    editorPageIn:"editorPageIn",
    editorPageOff:"editorPageOff",
    addOpenAppPackageSelected:"addOpenAppPackageselected",
    addBackToMainSelected:"addBackToMainSelected",
    openAppSelected : "openAppSelected",
    backToMainSelected:"backToMainSelected"
}


export class EditorModel extends EventDispatcher{
    static events = events
    // 单条编辑时使用这个来判断是新增还是编辑
    static itemEditType = {
        add:"add",
        edit:"edit"
    }

   code = ``;
   myProjects!:MyProjects;
   installedAppList:any[] = []
    editAction?:IActionAlias;
    itemEditType = "add"
    textarea = null;
    editingProject?:IProject

    constructor() {
        super();
        if(WebViewJs.instance.isInApp == false){

            try{
                let s = require('../../pages/actionSelectOpenApp/mock1.json')
                this.installedAppList = s;
            }catch (e){ console.log("err", e) }finally {}
        }
    }

    get actionList(){
        return this.editingProject?.editingTask?.actionList
    }
    init=()=>{
        this.myProjects = new MyProjects();
        this.myProjects.init();
    }
    setEditProject =(proj?:IProject)=>{
        this.editingProject = proj;
        return proj
    }
    addAction =(action:IActionAlias, index:number)=>{
        this.editingProject?.editingTask?.addAction(action, index);
    }
    updateCode =()=>{
        this.dispatchEvent(new DataEvent(EditorModel.events.codeChange, this.getActionListCode()))
    }
    insertCode = (c:any)=>{
        this.dispatchEvent(new DataEvent(EditorModel.events.codeInsert, c))
    }
    getActionListCode=()=>{
        let code = ``
        let list = this.editingProject?.editingTask?.actionList;
        if(!list)return "";
        for (let i = 0; i < list.length; i++) {
            code += `\n${list[i].getMissionCode()}`
        }
        return code ? `[${code}]` : '';
    }


}


export var editorModel = new EditorModel()