import React from 'react';
import i18n from "i18next";
import {Block, Link, ListItem} from "framework7-react";
import {ComponentBase} from "../../../../../../libs/fanfanlo/react/components/ComponentBase";
import {Trans} from "react-i18next";
import {CreateDeleteActionButton} from "#/htmls/index/vo/project/a11y/actions/del-action-button";
import Dom7 from "dom7";
import {a11yNodeProperties, a11yNodeRealizedKeys, nodeActions} from "#/htmls/index/vo/project/a11y/actions/A11yNode";
import {A11yNodeProperties} from "#/htmls/index/vo/project/a11y/actions/node/A11yNodeProperties";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {openHtmlShowA11yNodeInfoButton} from "#/htmls/accessibility-info-buttons/html-show-a11y-node-info-button-conf";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import {A11yNodeWaitUI} from "#/htmls/index/vo/project/a11y/actions/node/A11yNodeWait";



class ActionRadioGroup extends ComponentBase{
    constructor(props) {
        super(props)
        this.state = {count:0}
    }
    addEvents() {
        super.addEvents();
        this.props.action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onActionUpdated);
    }

    removeEvents() {
        super.removeEvents();
        this.props.action.removeDomain(this);
    }

    onChange =()=>{
        let x = Dom7('input[name="action-radio"]:checked')
        let v = x.val();
        if(!v)return;
        let data = nodeActions[v]
        this.props.action.updateNodeAction(data)
    }
    onSetTextChange =(e)=>{
        let shellData = this.props.action?.shellData;
        if(!shellData){
            alert("no shell data")
            return;
        }
        console.log("text change", e.target.value);
        shellData.setText = e.target.value;
        this.props.action.dispatchUpdateEvent();
    }
    onActionUpdated=()=>{
        this.setState({count:this.state.count + 1})
    }
    render(){
        let shellData = this.props.action?.shellData
        if(!shellData){
            return (<div>waiting for node selected</div>)
        }
        let nodeAction = shellData?.action;
        let keys = Object.keys(nodeActions)
        return (
            <div className={"border-rect"}>
                <div className={"padding-12"}>
                    {i18n.t("project.task.action.node.act.title.name")}
                </div>
                <ul>
                    {
                        keys.map((key, index) => {
                            let data = nodeActions[key];
                            i18n.t("pageContents.actionUI.a11yNode")
                            let title = i18n.t(`project.task.action.node.act.${data.name}.name`);
                            return (
                                <ListItem
                                    key={index.toString()}
                                    onChange={this.onChange}
                                    radio
                                    checked={nodeAction?.value === data.value}
                                    radioIcon="start"
                                    name="action-radio"
                                    value={data.value}
                                    title={title}
                                >
                                    {data.hasInput &&

                                        <div className="item-content item-input item-input-outline">
                                            <div className="item-media">
                                                <i className="icon demo-list-icon"></i>
                                            </div>
                                            <div className="item-inner">
                                                <div
                                                    className="item-title item-label">{i18n.t("project.task.action.node.act.setText.name")}</div>
                                                <div className="item-input-wrap">
                                                    <input type="text"
                                                           placeholder={i18n.t("project.task.action.node.act.setText.input.placeholder")}
                                                           value={shellData.setText || ""} onChange={this.onSetTextChange}/>
                                                    <span className="input-clear-button"></span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </ListItem>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

export class ActionA11yNodeUI extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async onChangeAppClick() {
        let et = this.props.action.getEventType(ProjectEditEnum.Update);
        openHtmlShowA11yNodeInfoButton(et);
    }
    addEvents() {
        super.addEvents();
        this.props.action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onActionUpdate);
    }

    removeEvents() {
        super.removeEvents();
        this.props.action.removeDomain(this);
    }

    onActionUpdate=(e)=>{
        this.setState({updated:true});
        console.log("ui linstened update", e);
    }
    render() {
        let action = this.props.action;
        let shellData = action?.shellData;
        let javaData = action?.javaData;
        let info = shellData?.info;
        let node = info?.node;
        return (
            <>
                <div>
                    <ActionRadioGroup action={action}/>
                    <A11yNodeWaitUI action={action} />
                    <A11yNodeProperties action={action} node={node}/>
                    <div>
                        <div>
                            <span>pn:</span><span>{info?.pn}</span>
                        </div>
                        <div>
                            <span>an:</span><span>{info?.activeActivity}</span>
                        </div>
                    </div>

                    <div className={"grid grid-gap  grid-cols-2"}>
                        <CreateDeleteActionButton action={this.props.action}/>
                        <Link onClick={this.onChangeAppClick.bind(this)} href={"#"}>
                            <Trans>
                                pageContents.actionUI.changeButton.label
                            </Trans>
                        </Link>
                    </div>

                </div>
            </>
        )
    }
}

export function CreateA11yNodeUI(action) {
    // return <div>ooxx2</div>
    return <ActionA11yNodeUI action={action}/>
}