import {Navbar, Page, PageContent} from "framework7-react";
import React from 'react';

import "./terms.css"
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
export class TermsContent extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="main-warp"  style={{backgroundColor: "rgb(255, 255, 255); color: rgb(0, 0, 0)", color:"#000000"}}>
                    <h1 className="title-h1 text-center"><span className="app-name">爱省</span>软件许可及服务协议</h1>

                    <section className="section-warp">
                        欢迎您使用“<span className="app-name">爱省</span>”软件及相关服务！
                    </section>
                    <section className="section-warp text-indent-2">
                        请务必认真阅读和理解本《“<span className="app-name">爱省</span>”软件许可及服务协议》（以下简称“本协议”）中规定的所有权利和限制。在您开始使用“<span
                        className="app-name">爱省</span>”软件及相关服务之前，请您务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后下载本软件。
                    </section>

                    <section className="section-warp">
                        除非您完全接受本协议的全部内容，否则您无权下载、安装、使用（以下统称“使用”）“<span className="app-name">爱省</span>”软件，或者通过任何方式使用“<span
                        className="app-name">爱省</span>”服务，或者获得“<span className="app-name">爱省</span>”软件提供的任何服务（本协议中统称“使用”）。若您使用“<span
                        className="app-name">爱省</span>”软件及相关服务，则视为您已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">1. 适用范围</h3>
                        <p className="line text-indent-2">1.1
                            本协议是您与爱省平台开发者北京惠省科技有限公司（以下简称“平台”）之间就您下载、安装、使用“<span
                                className="app-name">爱省</span>”软件，并获得“<span className="app-name">爱省</span>”软件提供的相关服务所订立的协议。就本协议项下涉及的某些服务，可能会由平台的关联平台、控制平台向您提供，您知晓并同意接受上述服务内容，即视为接受双方之间的相关权利义务关系亦受本协议约束。
                        </p>
                        <p className="line text-indent-2">1.2 “用户”指所有直接或间接获取和使用“<span className="app-name">爱省</span>”软件及相关服务的使用者，包括自然人、法人和其他组织等。在本协议中称为“用户”或称“您”。
                        </p>
                        <p className="line text-indent-2">1.3 “<span
                            className="app-name">爱省</span>”指由平台合法拥有并运营的、标注名称为“<span
                            className="app-name">爱省</span>”的客户端应用程序，平台向用户提供相关的技术服务。</p>
                        <p className="line text-indent-2">1.4
                            本协议内容同时包括平台已经发布及后续可能不断发布的关于“<span className="app-name">爱省</span>”软件及相关服务的相关协议、规则等内容。前述内容一经正式发布，并以适当的方式送达用户（系统通知等），即为本协议不可分割的组成部分，您应同样遵守。
                        </p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">2. 使用“<span className="app-name">爱省</span>”软件及相关服务</h3>
                        <p className="line text-indent-2">2.1
                            您使用“<span className="app-name">爱省</span>”软件及相关服务，可以通过预装、平台已授权的第三方下载等方式获取“<span
                                className="app-name">爱省</span>”客户端应用程序。若您并非从平台或经平台授权的第三方获取本软件的，平台无法保证非官方版本的“<span
                                className="app-name">爱省</span>”软件能够正常使用，您因此遭受的损失与平台无关。</p>
                        <p className="line text-indent-2">2.2 平台可能为不同的终端设备开发了不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。</p>
                        <p className="line text-indent-2">2.3 您可根据自行需要使用“<span className="app-name">爱省</span>”软件及相关服务或更新“<span
                            className="app-name">爱省</span>”版本，如您不再需要使用“<span className="app-name">爱省</span>”软件及相关服务可自行卸载。
                        </p>
                        <p className="line text-indent-2">2.4
                            为更好的提升用户体验及服务，平台将不定期提供软件更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）。为保证“<span
                                className="app-name">爱省</span>”软件及相关服务安全、提升用户服务，本软件及相关服务更新或部分服务内容更新后，在可能的情况下，平台将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。
                        </p>
                        <p className="line text-indent-2">2.5除非得到平台事先书面授权，您不得以任何形式对“<span className="app-name">爱省</span>”软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。
                        </p>
                        <p className="line text-indent-2">2.6
                            您理解，您使用“<span className="app-name">爱省</span>”软件及相关服务需自行准备与软件及相关服务有关的终端设备（如手机等），一旦您在其终端设备中打开“<span
                                className="app-name">爱省</span>”软件，即视为您使用“<span className="app-name">爱省</span>”软件及相关服务。为充分实现“<span
                                className="app-name">爱省</span>”的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。</p>
                        <p className="line text-indent-2">2.7
                            平台许可您个人的、不可转让的、非独占地和非商业的合法使用“<span className="app-name">爱省</span>”软件及相关服务的权利。本协议未明示授权的其他一切权利仍由平台保留，您在行使该些权利时须另行获得平台的书面许可，同时平台如未行使前述任何权利，并不构成对该权利的放弃。
                        </p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line"> 3. 用户行为规范</h3>
                        <h4 className="title-h4 line">3.1 用户行为要求</h4>
                        <p className="line">您应当对您使用本产品及相关服务的行为负责，除非法律允许或者经平台事先书面许可，您使用“<span
                            className="app-name">爱省</span>”软件及相关服务不得具有下列行为：</p>
                        <p className="line text-indent-2">3.1.1 使用未经平台授权或许可的任何插件、外挂、系统或第三方工具对“<span
                            className="app-name">爱省</span>”软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</p>
                        <p className="line text-indent-2">3.1.2 利用或针对“<span className="app-name">爱省</span>”软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：
                        </p>
                        <p className="line text-indent-2">（1）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</p>
                        <p className="line text-indent-2">（2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</p>
                        <p className="line text-indent-2">（3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</p>
                        <p className="line text-indent-2">（4）使用未经许可的数据或进入未经许可的服务器/账号；</p>
                        <p className="line text-indent-2">（5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</p>
                        <p className="line text-indent-2">（6）未经许可，企图探查、扫描、测试“<span className="app-name">爱省</span>”系统或网络的弱点或其它实施破坏网络安全的行为；
                        </p>
                        <p className="line text-indent-2">（7）企图干涉、破坏“<span className="app-name">爱省</span>”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
                        </p>
                        <p className="line text-indent-2">（8）伪造TCP/IP数据包名称或部分名称。</p>
                        <p className="line text-indent-2">3.1.3 对“<span className="app-name">爱省</span>”软件进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码。
                        </p>
                        <p className="line text-indent-2">3.1.4 违反法律法规、本协议、平台的相关规则及侵犯他人合法权益的其他行为。</p>
                        <p className="line ">
                            如果我们发现或收到他人举报或投诉用户违反本协议约定的，我们有权不经通知随时对举报和投诉的内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁
                            、设备封禁
                            、功能封禁
                            的处罚，且通知用户处理结果。</p>
                        <p className="line text-indent-2">3.1.5 因违反本协议被封禁的用户，可向<span className="app-name">爱省</span>的客服进行申诉。我们将对申诉进行审查，并自行合理判断决定是否变更处罚措施。
                        </p>
                        <h4 className="title-h4 line">3.2
                            用户使用“<span className="app-name">爱省</span>”软件及相关服务应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。用户不得使用“<span
                                className="app-name">爱省</span>”软件及相关服务录制、制作、复制、发布、传播含有下列内容的信息：</h4>
                        <p className="line text-indent-2">（1）反对宪法确定的基本原则的；</p>
                        <p className="line text-indent-2">（2）危害国家安全，泄露国家秘密的；</p>
                        <p className="line text-indent-2">（3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；</p>
                        <p className="line text-indent-2">（4）损害国家荣誉和利益的；</p>
                        <p className="line text-indent-2">（5）宣扬恐怖主义、极端主义的；</p>
                        <p className="line text-indent-2">（6）宣扬民族仇恨、民族歧视，破坏民族团结的；</p>
                        <p className="line text-indent-2">（7）煽动地域歧视、地域仇恨的；</p>
                        <p className="line text-indent-2">（8）破坏国家宗教政策，宣扬邪教和迷信的；</p>
                        <p className="line text-indent-2">（9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；</p>
                        <p className="line text-indent-2">（10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；</p>
                        <p className="line text-indent-2">（11）侵害未成年人合法权益或者损害未成年人身心健康的；</p>
                        <p className="line text-indent-2">（12）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；</p>
                        <p className="line text-indent-2">（13）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；</p>
                        <p className="line text-indent-2">（14）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</p>
                        <p className="line text-indent-2">（15）侮辱或者诽谤他人，侵害他人合法权益的；</p>
                        <p className="line text-indent-2">（16）对他人进行暴力恐吓、威胁，实施人肉搜索的；</p>
                        <p className="line text-indent-2">（17）涉及他人隐私、个人信息或资料的；</p>
                        <p className="line text-indent-2">（18）散布污言秽语，损害社会公序良俗的；</p>
                        <p className="line text-indent-2">（19）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；</p>
                        <p className="line text-indent-2">（20）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；</p>
                        <p className="line text-indent-2">（21）使用本网站常用语言文字以外的其他语言文字评论的；</p>
                        <p className="line text-indent-2">（22）其他违反法律法规、政策及公序良俗、干扰“<span className="app-name">爱省</span>”正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
                        </p>
                        <h4 className="title-h4 line">3.3
                            平台设立公众投诉、举报平台，用户可按照平台公示的投诉举报制度向<span className="app-name">爱省</span>的客服进行投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，我们将及时受理和处理用户投诉举报，以共同营造风清气正的网络空间。
                        </h4>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">4、个人信息保护</h3>
                        <h4 className="title-h4 line">4.1
                            我们与您一同致力于您个人信息的保护，保护用户个人信息是平台的基本原则之一。在使用“<span className="app-name">爱省</span>”的过程中，您可能需要提供您的个人信息，以便我们向您提供更好的服务和相应的技术支持。您理解并同意，平台有权在遵守法律法规、本协议以及《<span
                                className="app-name">爱省</span>
                            – 隐私政策》的前提下，获取、使用、储存和分享您的个人信息。</h4>
                        <h4 className="title-h4 line">4.2
                            平台将运用加密技术、匿名化处理等其他与<span className="app-name">爱省</span>相匹配的技术措施及其他安全措施保护您的个人信息，防止您的信息被不当使用或被未经授权的访问、使用或泄漏，并为前述目的建立完善的管理制度。
                        </h4>
                        <h4 className="title-h4 line">4.3 未经您本人事先明确的授权同意，我们不会向除平台及其关联方外的任何第三方共享您的个人信息，下列情形除外：</h4>
                        <p className="line text-indent-2">（一）您自行向第三方共享的；</p>
                        <p className="line text-indent-2">（二）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；</p>
                        <p className="line text-indent-2">
                            （三）根据适用的法律法规的要求、强制性的行政和司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的。在符合法律法规的前提下，当我们收到前述披露信息的请求时，我们会要求对方必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；
                        </p>
                        <p className="line text-indent-2">
                            （四）在法律法规允许的范围内，为维护“<span className="app-name">爱省</span>”其他用户、平台及其关联方的生命、财产等重大合法权益，或为“<span
                            className="app-name">爱省</span>”软件及相关服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等（不包括违反《<span
                            className="app-name">爱省</span>
                            – 隐私政策》中所做的承诺而为获利目的对外公开或提供个人信息）；</p>
                        <p className="line text-indent-2">（五）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
                        <p className="line text-indent-2">（六）法律法规规定的其他情形。</p>
                        <h4 className="title-h4 line">4.4
                            如“<span className="app-name">爱省</span>”包含链接至第三方提供的信息或其他服务（包括网站），您知悉并理解，运营该等服务的第三方可能会要求您提供个人信息。我们特别提醒您，需要认真阅读该等第三方的用户协议、隐私政策以及其他相关的条款，妥善保护自己的个人信息，仅在必要的情况下向该等第三方提供。本协议（以及其他与“<span
                                className="app-name">爱省</span>”相关的协议和规则，包括但不限于《<span className="app-name">爱省</span>
                            – 隐私政策》）不适用于任何第三方提供的服务，平台对任何因第三方使用由您提供的个人信息所可能引起的后果不承担任何法律责任。 更多关于用户个人信息保护的内容，请参看《隐私政策》。
                        </h4>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">5. “<span className="app-name">爱省</span>”数据使用规范</h3>
                        <h4 className="title-h4 line">未经平台书面许可，用户不得自行或授权、允许、协助任何第三人对本协议“<span
                            className="app-name">爱省</span>”软件及相关服务中信息内容进行如下行为：</h4>
                        <p className="line text-indent-2">5.1 复制、读取、采用“<span className="app-name">爱省</span>”软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；
                        </p>
                        <p className="line text-indent-2">5.2 擅自编辑、整理、编排“<span className="app-name">爱省</span>”软件及相关服务的信息内容后在“<span
                            className="app-name">爱省</span>”软件及相关服务的源页面以外的渠道进行展示；</p>
                        <p className="line text-indent-2">5.3
                            采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对“<span className="app-name">爱省</span>”软件及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；
                        </p>
                        <p className="line text-indent-2">5.4 其他非法获取“<span className="app-name">爱省</span>”软件及相关服务的信息内容的行为。
                        </p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">6. 违约处理</h3>
                        <p className="line text-indent-2">6.1
                            针对您违反本协议或其他服务条款的行为，平台有权独立判断并视情况采取措施。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。</p>
                        <p className="line text-indent-2">
                            6.2因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致平台及其关联平台、控制平台向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿平台及其关联平台、控制平台因此遭受的全部损失。
                        </p>
                        <p className="line text-indent-2">
                            6.3平台尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用“<span className="app-name">爱省</span>”软件及相关服务制作、复制、发布、传播的信息时不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。针对第三方提出的全部权利主张，您应自行承担全部法律责任；如因您的侵权行为导致平台及其关联平台、控制平台遭受损失的（包括经济、商誉等损失），您还应足额赔偿平台及其关联平台、控制平台遭受的全部损失。
                        </p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">7. 服务的变更、中断和终止</h3>
                        <p className="line text-indent-2">7.1
                            您理解并同意，平台提供的“<span className="app-name">爱省</span>”软件及相关服务是按照现有技术和条件所能达到的现状提供的。平台会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，平台不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
                        </p>
                        <p className="line text-indent-2">7.2 您理解并同意，平台为了服务整体运营的需要，有权在公告通知后修改、中断、中止或终止“<span
                            className="app-name">爱省</span>”软件及相关服务，而无须向用户负责或承担任何赔偿责任。</p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">8. 广告</h3>
                        <p className="line text-indent-2">8.1
                            您使用“<span className="app-name">爱省</span>”软件及相关服务过程中，充分理解并同意：本服务中可能包括平台针对个人或企业推出的信息、广告发布或品牌推广服务，您同意平台有权在“<span
                                className="app-name">爱省</span>”软件及相关服务中展示“<span className="app-name">爱省</span>”软件及相关服务相关和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。
                        </p>
                        <p className="line text-indent-2">8.2 如您不同意该广告，您有权选择关闭该广告信息；“<span
                            className="app-name">爱省</span>”推送通知服务的，您有权自行关闭该服务或可停止使用“<span className="app-name">爱省</span>”软件及相关服务。
                        </p>
                        <p className="line text-indent-2">8.3
                            平台依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，用户应自行承担，平台不予承担责任。
                        </p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">9. 知识产权</h3>
                        <p className="line text-indent-2">
                            9.1平台在“<span className="app-name">爱省</span>”软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于平台所有。平台提供本服务时所依托的软件的著作权、专利权及其他知识产权均归平台所有。未经平台许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载“<span
                            className="app-name">爱省</span>”软件及相关服务中的内容）。</p>
                        <p className="line text-indent-2">9.2 平台为“<span
                            className="app-name">爱省</span>”开发、运营提供技术支持，并对“<span
                            className="app-name">爱省</span>”软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。</p>
                        <p className="line text-indent-2">9.3 请您在任何情况下都不要私自使用平台的包括但不限于
                            “<span className="app-name">爱省</span>”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经平台事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用平台上述商标、标识等给平台或他人造成损失的，由您承担全部法律责任。
                        </p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">10. 免责声明</h3>
                        <h4 className="title-h4 line">10.1 您理解并同意，“<span className="app-name">爱省</span>”软件及相关服务可能会受多种因素的影响或干扰，平台不保证(包括但不限于)：
                        </h4>
                        <p className="line text-indent-2">10.1.1平台完全适合用户的使用要求；</p>
                        <p className="line text-indent-2">10.1.2 平台不受干扰，及时、安全、可靠或不出现错误；用户经由平台取得的任何软件、服务或其他材料符合用户的期望；</p>
                        <p className="line text-indent-2">10.1.3软件中任何错误都将能得到更正。</p>
                        <h4 className="title-h4 line">10.2
                            用户理解并同意，在使用“<span className="app-name">爱省</span>”软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，平台将努力在第一时间及时修复，但因不可抗力给用户造成了损失，用户同意平台不承担责任。
                        </h4>
                        <h4 className="title-h4 line">10.3
                            平台依据本协议约定获得处理违法违规内容的权利，该权利不构成平台的义务或承诺，平台不能保证及时发现违法行为或进行相应处理。</h4>
                        <h4 className="title-h4 line">10.4
                            用户阅读、理解并同意：关于本协议服务，平台不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。</h4>
                        <h4 className="title-h4 line">10.5
                            用户阅读、理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，平台在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证平台判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。
                        </h4>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">11. 未成年人使用条款</h3>
                        <p className="line text-indent-2">11.1 若用户是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用“<span
                            className="app-name">爱省</span>”软件及相关服务。</p>
                        <p className="line text-indent-2">11.2
                            平台重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用“<span
                                className="app-name">爱省</span>”软件及相关服务。</p>
                        <p className="line text-indent-2">11.3 未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</p>
                        <h4 className="title-h4 line">11.4未成年人用户特别提示：</h4>
                        <p className="line text-indent-2">11.4.1
                            青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。</p>
                        <p className="line text-indent-2">11.4.2 青少年用户必须遵守《全国青少年网络文明公约》：</p>
                        <p className="line text-indent-2">（1）要善于网上学习，不浏览不良信息；</p>
                        <p className="line text-indent-2">（2）要诚实友好交流，不侮辱欺诈他人；</p>
                        <p className="line text-indent-2">（3）要增强自护意识，不随意约会网友；</p>
                        <p className="line text-indent-2">（4）要维护网络安全，不破坏网络秩序；</p>
                        <p className="line text-indent-2">（5）要有益身心健康，不沉溺虚拟时空。</p>
                    </section>

                    <section className="section-warp">
                        <h3 className="title-h3 line">12. 其他</h3>
                        {/*<p className="line text-indent-2">12.1*/}
                        {/*    本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。*/}
                        {/*</p>*/}
                        {/*<p className="line text-indent-2">12.2*/}
                        {/*    本协议的签署地点为中华人民共和国深圳市南山区，若您与平台发生争议的，双方应尽量友好协商解决，协商不成，您同意应将争议提交至深圳市南山区法院管辖。*/}
                        {/*</p>*/}
                        <p className="line text-indent-2">12.1
                            本协议中的相关条款会持续完善，并在软件测试和对外发布过程中，持续改进和调整。我们会将完善后的条款以适当的方式予以发布，并主动通知您查阅。前述完善后的条款一经正式发布，即作为本协议不可分割的组成部分并具有等同于本协议的法律效力。在本协议完善后，如果您继续使用“<span
                                className="app-name">爱省</span>”，即视为您认可并接受修改后的协议条款。如果您对修改后的条款存有异议，请您立即停止使用“<span
                                className="app-name">爱省</span>”。</p>
                        <p className="line text-indent-2">12.2 本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。</p>
                        <p className="line text-indent-2">12.3
                            您和平台均是独立的主体，在任何情况下本协议不构成平台对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。</p>
                        <p className="line text-indent-2">12.4 本协议的版权为平台所有，平台保留一切解释和修改的权利。</p>
                    </section>


                </div>
            </>
        )
    }
}

export class Terms extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page pageContent={false} className={"no-toolbar"}>
                <Navbar title={TermsRouter.name} backLink="返回"/>
                <PageContent>
                    <TermsContent/>
                </PageContent>
            </Page>
        )
    }
}

export const TermsRouter = {
    path: "/Terms/",
    component: Terms,
    name: "Terms"
}
