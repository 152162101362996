import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import {Button, f7, List, ListItem, Page, PageContent} from "framework7-react";
import React from 'react';
import {
    CreateFloatPermissionCheckButton
} from "../../../../ui-components/links/permissions/float/floatPermissionCheckButton";
import {CreateScriptButton} from "#/htmls/index/pages/RhinoScriptEdtor/create-script-button";

import {MyProjectsContent} from "#/htmls/index/pages/myprojects/my-projects";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {openHtmlShowA11yNodeInfoButton} from "#/htmls/accessibility-info-buttons/html-show-a11y-node-info-button-conf";
import {runProjectScriptDemo} from "#/droid/java_js/fanfanlo/javajs/v1/project/demo/project-script-builder";
import {killSelf} from "#/droid/rhino/scripts/sys/killSelf";
import {A11yPermissionCheckButtonUI} from "#/ui-components/links/permissions/a11y/A11yPermissionCheckButton";
import {testEventDispatcher} from "#/libs/fanfanlo/events/eventDispatcherTest";
import {Editor} from "@monaco-editor/react";
// import {CreateUseDataBindTestContainer} from "#/libs/fanfanlo/react/hooks/useDataBindTest";

testEventDispatcher();

export class MainContent extends ComponentBase {
    constructor(props) {
        super(props);
    }

    onOpenA11yClick = () => {
        // checkRunQuickButtonPanel("layout|appInfo");
        AutoWebViewJs.instance.onCallOpenA11yPermission();
    }
    onOpenNodeInfoClick = () => {
        openHtmlShowA11yNodeInfoButton("");
    }
    killSelf = () => {
        f7.dialog.create({text: "正在咽气中。。。请稍安勿躁，马上完事！！！"}).open();
        killSelf();
    }

    async onTestClick() {
        // runDemoProject06();
        runProjectScriptDemo();
    }

    editorDidMount(editor, monaco) {
        console.log('editorDidMount', editor);
        editor.focus();
    }

    onChange(newValue, e) {
        console.log('onChange', newValue, e);
    }

    render() {
        const code = "this.state.code";
        const options = {
            selectOnLineNumbers: true
        };

        return (
            <>
                <div className={"grid"}>

                    <List simpleList>

                        <ListItem>
                            {/*<div><a onClick={this.onOpenA11yClick}>打开辅助功能工具</a>卧槽</div>*/}
                            {/*<div><a onClick={this.onOpenA11yClick}>辅助功能设置</a></div>*/}
                            <div><A11yPermissionCheckButtonUI/></div>
                        </ListItem>
                        <ListItem>
                            <div><CreateFloatPermissionCheckButton/></div>
                        </ListItem>
                        <ListItem>
                            <Button onClick={this.onTestClick}>脚本样例-支付宝</Button>
                        </ListItem>
                        <ListItem>
                            <Button onClick={this.onOpenNodeInfoClick}>节点信息</Button>
                        </ListItem>
                        <ListItem>
                            <Button onClick={this.killSelf}>关闭全部任务并退出</Button>
                        </ListItem>
                        <ListItem>
                            {/*<div><Link href={RhinoScriptEdtorRouter.p+"-1"}>{i18n.t("pageContents.scriptEditor.buttonNewScript")}</Link></div>*/}
                            <div>
                                <CreateScriptButton/>
                            </div>
                        </ListItem>
                    </List>
                    <MyProjectsContent/>
                    {/*<CreateUseDataBindTestContainer />*/}

                    {/*<Editor width={"100%"} height={"20rem"} defaultLanguage="javascript" defaultValue="// some comment" />*/}

                </div>
            </>
        )
    }
}

let mainIndex = 0;

export class Main extends ComponentBase {
    constructor(props) {
        super(props);
        this.mainIndex = mainIndex++;
    }

    render() {
        return (
            <Page pageContent={false}>
                <PageContent>
                    <MainContent/>
                </PageContent>
            </Page>
        )
    }
}

export const MainRouter = {
    id: "main",
    // url:"/",
    path: "/",
    component: Main,
    iconIos: "f7:house_fill",
    iconAurora: "f7:house_fill",
    iconMd: "material:home",
    name: "main"
}
