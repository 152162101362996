import {IProject} from "#/htmls/index/vo/project/base/IProject";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import to from "await-to-js";
import {runPermissionGroup} from "#/android/components/permissionGroupCreator";
import {PermissionGroup} from "#/android/components/PermissionGroup";


export async function runProject(project:IProject){
    let permissions = project.getPermissions();
    if(permissions){
        let [err, res] = await to<PermissionGroup>(runPermissionGroup(...permissions))
        if(err){
            console.log("permission ask error", err);
            return;
        }
    }
    let script = project.projectScript;
    AutoWebViewJs.instance.callScriptRunner(script)
}