import {EditorModel, editorModel} from "./EditorModel";
import {OpenApp} from "../../vo/project/a11y/actions/OpenApp";
import {ActionA11yToMainActivity} from "../../vo/project/a11y/actions/ActionA11yToMainActivity";
import {getRouter} from "#/libs/fanfanlo/f7/f7RouteUtils";


class EditorController{
    constructor() {

    }

    init =()=>{
        // editorModel.addEventListener(EditorModel.events.addOpenAppPackageSelected, this._onOpenAppPackageSelecte )
        // editorModel.addEventListener(EditorModel.events.addBackToMainSelected, this._onBackToMainSelecte )
        editorModel.addEventListener(EditorModel.events.backToMainSelected, this._onBackToMainSelecte )
        editorModel.addEventListener(EditorModel.events.openAppSelected, this._onOpenAppSelected);
        editorModel.init()
        // let ps = ProjectBase.readProjectsFromLocalStorage()
        // let projectsData = readProjectsFromLocalStorage();
    }

    onEditorPageIn =()=>{
        this.subscribe();
    }

    onEditorPageOff =()=>{
        this.unsubscribe();
    }
    subscribe=()=>{
    }

    unsubscribe=()=>{
    }

    _onOpenAppSelected = (e)=>{
        const isAdd = editorModel.itemEditType == EditorModel.itemEditType.add;
        let action = isAdd ? new OpenApp() : editorModel.editAction;
        action.javaData = e.data;
        isAdd ? editorModel.addAction(action) : editorModel.updateCode()
    }
    _onBackToMainSelecte =(e)=>{
        const isAdd = editorModel.itemEditType == EditorModel.itemEditType.add;
        let action = isAdd ? new ActionA11yToMainActivity() : editorModel.editAction;
        action.javaData = e.data;
        isAdd ? editorModel.addAction(action) : editorModel.updateCode()
    }
    _addOrUpdateAction=(action, isAdd)=>{
        // let proj =
        let url = getRouter().currentRoute.url;

    }
}

// (()=>{
//     webViewJsListen("WebViewJsDispatcher", "a11yEditNodeSelected", "a11yEditNodeSelected", false, function (node){
//         let action = new ActionA11yNode();
//         action.node = node;
//         editorModel.addAction(action);
//         const script = `com.fanfanlo.lib.intent.Funcs.openSelfByPackage()`
//         appJsBridge.callScript(script);
//     })
// })();

export var editorController = new EditorController();

