import {scriptTools as t} from "#/droid/rhino/scripts/scriptTools";
let script = `
var bl = com.fanfanlo.lib.permissions.FloatPermission.openPermission(function(bl){
        webViewJs.callJsFun("onFloatPermissionSettingResult", bl);
    });
    webViewJs.callJsFun("onOpenFloatPermissionSettingResult", bl);
`
const conf = {
    forceStopTime:0,
    onlyRunSingle :true,
    stopPrevIfOnlyRunSingle: true,
    injects:["webViewJs"],
    script,
    // script:`${t.unShell(script.toString())}`,
    params:{
        "keyName":"keyValue will be replaced in script"
    }
}

// function script(){
//     // var someData = `${keyName}`
//     function onJavaCallTerminate(){
//
//     }
//
//     var bl = com.fanfanlo.lib.permissions.FloatPermission.openPermission(function(bl){
//         webViewJs.callJsFun("onFloatPermissionSettingResult", bl);
//     });
//     webViewJs.callJsFun("onOpenFloatPermissionSettingResult", bl);
// }

// 这个方法可以废弃，现在在autoWebView里添加了这个方法
export function openFloatPermission(){
    t.callScript(conf)
}
