import React, {ChangeEvent} from "react";
import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {
    a11yNodeProperties,
    A11yNodePropertyInfo,
    a11yNodePropertyKeys,
    A11yNodePropertyType,
    a11yNodeProperyEnum,
    a11yNodeRealizedKeys,
    IA11yNode
} from "#/htmls/index/vo/project/a11y/actions/A11yNode";
import {ActionA11yNode} from "#/htmls/index/vo/project/a11y/actions/ActionA11yNode";
import i18n from "i18next";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";

class PropertyProps{
    node!:IA11yNode
    action!:ActionA11yNode
    A11yPropertyInfo!:A11yNodePropertyInfo
}
class PropertyState{
    count = 0;
}
export class Property extends ComponentBase<PropertyProps, PropertyState>{
    static count = 0;
    constructor(props:PropertyProps) {
        super(props);
        this.state = new PropertyState();
    }
    addEvents() {
        super.addEvents();
        this.props.action.addDomainEventListener(this, ActionA11yNode.eventType.selectePropertiesChanged, this.onActionSelectPropertiesChanged);
    }
    removeEvents() {
        super.removeEvents();
        this.props.action.removeDomain(this);
    }

    onActionSelectPropertiesChanged = ()=>{
        this.setState({count:this.state.count + 1})
    }
    onCheckedChange =(e:ChangeEvent<HTMLInputElement>)=>{
        let selected = e.target.checked;
        let index = this.props.action.shellData.selectedProperties.indexOf(this.props.A11yPropertyInfo.name);
        if(selected){
            if(index == -1){
                this.props.action.shellData.selectedProperties.push(this.props.A11yPropertyInfo.name)
            }
        }else{
            if(index > -1){
                this.props.action.shellData.selectedProperties.splice(index, 1)
            }
        }
        this.props.action.dispatchSelectePropertiesChanged();
    }
    onInputTextChange =(e:ChangeEvent<HTMLInputElement>)=>{
        let key = this.props.A11yPropertyInfo.name;
        let v = this.props.node[key]
        // @ts-ignore
        this.props.action.shellData.regexProperties[key] = e.target.value
        // @ts-ignore
        // this.props.node[key] = e.target.value;
        console.log("eeeeeeeeeeeeeeeeee", e.target.value, v);
    }
    render(){
        let key = this.props.A11yPropertyInfo.name;
        let v = this.props.node[key]
        let seleted = this.props.action.shellData.selectedProperties.indexOf(key) > -1;
        let type = this.props.A11yPropertyInfo.type;
        // 库里没做其它几个文本的regex，以后要处理的时候需要java和这里都做。
        let isText = type == A11yNodePropertyType.text
            && ((key == a11yNodeProperyEnum.text));
        // let isText = type == A11yNodePropertyType.text
        //     && ((key == a11yNodeProperyEnum.text) || (key == a11yNodeProperyEnum.contentDescription)
        //     || key == a11yNodeProperyEnum.hintText);
        return(
            <div>
                <label className="item-checkbox item-content">
                    <input type="checkbox" onChange={this.onCheckedChange} checked={this.props.action.shellData.selectedProperties.indexOf(key) > -1}/>
                    <i className="icon icon-checkbox"></i>
                    <div className="item-inner">
                        <span>
                            <span style={{color: "#9a1616", paddingRight:"3px"}}>
                                {this.props.A11yPropertyInfo.name}
                            </span>
                                <span style={{color: "#1c80f5", paddingRight:"3px"}}>
                                :
                            </span>
                                <span>
                                    {isText &&
                                        <span>
                                            <input onChange={this.onInputTextChange}
                                                defaultValue={
                                                v?.toString() ?? i18n.t("project.task.action.node.selector.noValue.name")
                                            }/>
                                        </span>
                                    }
                                    {!isText && (v?.toString() ??
                                        <span style={{color:"gray"}}>
                                            {i18n.t("project.task.action.node.selector.noValue.name")}
                                        </span>)
                                    }
                            </span>
                        </span>
                    </div>
                </label>
            </div>
        )
    }
}

class PropertiesProps {
    action!: ActionA11yNode
    node!: IA11yNode
}

class PropertiesState {
    count = 1
}

export class A11yNodeProperties extends ComponentBase<PropertiesProps, PropertiesState> {
    constructor(props:PropertiesProps) {
        super(props);
        this.state = new PropertiesState();
    }
    addEvents() {
        super.addEvents();
        this.props.action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onActionUpdated)
    }

    removeEvents() {
        super.removeEvents();
        this.props.action.removeDomain(this);
    }

    onActionUpdated =()=>{
        this.setState({count:this.state.count + 1})
    }
    render(){
        let node = this.props.action.shellData?.info?.node
        if(!node){
            return (<></>)
        }
        return (
            <div className={"border-rect"}>
                <div >

                    <div className={"padding-12"}>{i18n.t("project.task.action.node.selector.title.name")}</div>
                    {a11yNodeRealizedKeys.map((key:a11yNodePropertyKeys, index:number)=>{
                        let p = a11yNodeProperties[key];
                        return <Property node={node!} action={this.props.action} key={index.toString()} A11yPropertyInfo={p}/>
                    })}
                </div>
            </div>
        )
    }
}