import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import {Block, Button, Page, PageContent} from "framework7-react";
import React from 'react';
import {VisitModel, visitModel} from "../../../../mc/VisitModel";
import {UserContent} from "./UserContent";
import {Trans} from "react-i18next";
import {PrintLogModelUI} from "#/htmls/index/pages/me/PrintLogModelUI";
import {BrowserPerformanceRouterLink} from "#/htmls/index/pages/browserPerformance/BrowserPerformance";
import {WebViewJs} from "#/android/WebViewJs";
const version = require("#/confs/version.json")
const appVersion = {
    isConnecting:false,
    listeners:[],
    getServerAppVersion:function (listener){
        if(this.listeners.indexOf(listener) == -1){
            this.listeners.push(listener);
        }
        if(this.isConnecting)return;

    },
    onBack:function (isOk, err, data){
        let listeners = this.listeners;
        this.listeners = [];
        listeners.forEach(f=>{
            f(isOk, err, data)
        })
    }
}


export class MePage extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {globalUser:visitModel.globalUser, logged:visitModel.logged, serverAppVersionErrorInfo:"",serverAppVersion:null, receiveMessage:false,
        googleWidth:300, googleHeight:200};
    }
    onResume = () => {
        super.onResume();
        this.refreshData()
        appVersion.getServerAppVersion(this.onGetServerAppVersionBack);
    }
    addEvents = ()=> {
        visitModel.addEventListener(VisitModel.events.logChanged, this.onUserLoggedChanged);
    }
    removeEvents = ()=> {
        console.log("me page event removed")
    }
    onUserLoggedChanged =(event) =>{
        this.refreshData()
    }
    refreshData = () => {
        let state = {globalUser:visitModel.globalUser, logged:visitModel.logged};
        this.setState(state);
    }
    onToggleDebugClick=()=>{
        // appJsBridge.callToggleDebugFloatWindow()
    }
    onGetServerAppVersionBack=(isOk, err, data) =>{
        if(!isOk || err || !data){
            if(!this.state.serverAppVersion){
                this.setState({serverAppVersionErrorInfo: err ? err.toString() : "没有获取到数据"})
            }
            return;
        }
        this.setState({serverAppVersion:data, serverAppVersionErrorInfo:""})
    }
    onSendLogsClick =()=>{
        // appJsBridge.callSendLogs();
    }
    onUpdateAppClick =()=>{
        // appJsBridge.callAppUpdate();
    }
    render = () => {
        // let versionInfo = appJsBridge.callAppVersion();
        let versionInfo = "119;226"
        let v = 0;
        let n = "";
        if(versionInfo){
            let arr = versionInfo.split(";");
            if(arr.length == 2){
                v = arr[0];
                n = arr[1];
            }
        }
        return (
            <Page>
                <PageContent>
                    {!this.state.logged &&
                    (<Block>i18next: initialized
                        <div>
                            <a href={"/login/"} className="button button-outline color-green inline">
                                <Trans>components.sign.signin.name</Trans>
                                /
                                <Trans>components.sign.signup.name</Trans>
                            </a>
                        </div>
                    </Block>)}
                    {this.state.logged && (
                        <UserContent />
                    )}
                    {/*{versionInfo && <Block>*/}
                    {/*    <List>*/}
                    {/*        <ListItem>*/}
                    {/*            <div>*/}
                    {/*                <div>本地版本：{v}</div>*/}
                    {/*                {this.state.serverAppVersion && <div>&nbsp;&nbsp;最新：{this.state.serverAppVersion.version_code_android}</div> }*/}
                    {/*                {this.state.serverAppVersion && this.state.serverAppVersion.version_code_android > v && <div>&nbsp;&nbsp;<Link onClick={this.onUpdateAppClick}>升级</Link></div>}*/}
                    {/*            </div>*/}
                    {/*        </ListItem>*/}
                    {/*        <ListItem>*/}
                    {/*            版本名：{n}*/}
                    {/*        </ListItem>*/}
                    {/*    </List>*/}
                    {/*</Block>}*/}

                    <Block>
                        {/*<Link href={routeMap.PrivacyTermsRouter.path}>{routeMap.PrivacyTermsRouter.name}</Link>*/}
                    </Block>
                    <Block>
                        <div>
                            <span>
                                <span>performance start:</span>
                                <span>{new Date(performance.timeOrigin).toLocaleString()}
                                </span>
                            </span>
                        </div>
                        <Button onClick={()=>WebViewJs.instance.reload()}>reload html</Button>
                        <BrowserPerformanceRouterLink />
                    </Block>
                    <Block>
                        <div>html version:{version.version}</div>
                        <div>html create time {version.createTime}</div>
                    </Block>
                    <PrintLogModelUI />
                </PageContent>
            </Page>)
    }
}

export const MineRouter = {
    id:"me",
    path: "/me/",
    component: MePage,
    iconIos:"f7:gear",
    iconAurora:"f7:gear",
    iconMd:"material:settings",
    name: "me"
}
