import {android} from "../../../../android/jsbridge";
import mock1 from "../../pages/actionSelectOpenApp/mock1.json";
import {storage} from "../../../../common/storage/storage";
import {showSimpleToast} from "../../../../ui-components/toast/toast";
import {EventDispatcher} from "../../../../libs/fanfanlo/events/EventDispatcher";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import i18n from "i18next";


const storageKey_actionSelectOpenAppPageApps = "actionSelectOpenAppPageApps"
export class InstalledAppsModel extends EventDispatcher{
    static events = {
        appListUpdated:"appListUpdated",
        appListFetching:"appListFetching",
    }
    constructor() {
        super()
        this.appList = [];
    }

    getInstalledAppByPackageName =(pn)=>{
        for (let i = 0; i < this.appList.length; i++) {
            let app = this.appList[i];
            if(app.packageName === pn)return app;
        }
        return null;
    }
    refreshPackages =()=>{
        setTimeout(this.getPackages, 100 * 10);
    }

    getPackages =()=>{
        if(!android){
            this.getPackagesByMock();
        }else{
            this.getPackagesFromAndroid()
        }
    }
    getPackagesByMock =()=>{
        // this.parseAppsString(mock1)
        try {

            this.setPackages(mock1)
        }catch (e) {
            console.log("getPackagesByMock has error", e, mock1)
        }
    }
    getPackagesFromAndroid =()=>{
        const script = `com.fanfanlo.droid.js.JsReceiver.readAllPackages();`
        let {error, data, errMsg} = AutoWebViewJs.instance.callScript(script)
        if (error){
            return showSimpleToast(errMsg);
        }
        this.parseAppsString(data);
    }
    parseAppsString =(s)=>{
        try{
            let packages = JSON.parse(s)
            this.setPackages(packages)
            storage.setItem(storageKey_actionSelectOpenAppPageApps, s)
        }catch (e){
            console.log("parse data error", s, e);
            let msg = i18n.t("pageContents.appList.render.noRender.content")
            showSimpleToast(`${msg} ${s},${e}`)
        }
    }
    setPackages =(packages)=>{
        this.appList = packages;
        this.dispatchEvent(new DataEvent(InstalledAppsModel.events.appListUpdated, packages))
    }
    checkPackagesFromCatch =()=>{
        if(this.checkHasMockMockPackages())return;
        this.getPackages();
    }
    checkHasMockMockPackages =()=>{
        if(this.appList.length > 0)return true;
        let s = storage.getItem(storageKey_actionSelectOpenAppPageApps);
        if(!s)return false;
        try{
            this.dispatchEvent(new DataEvent(InstalledAppsModel.events.appListFetching))
            let packages = JSON.parse(s)
            this.setPackages(packages)
        }catch (e) {

        }
        return true;
    }
}

export const installedAppsModel = new InstalledAppsModel()