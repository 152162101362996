
import {f7, Link, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import appJsBridge from "../../androidjsbridge/AppJsBridge";
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import {routeMap} from "../../routes";


export class PrivacyTermsContent extends ComponentBase {
    constructor(props) {
        super(props);
    }
    onCancleClick=()=>{
        appJsBridge.callWritePrivacyTerms(false);
        if(this.props.isPage)f7.view.main.router.back();
        this.showAlert();
    }
    showAlert =()=>{
        f7.dialog.alert("您拒绝了我们的协议，所以无法给您提供服务，请关闭应用或同意协议继续使用。");
    }
    onOkClick=()=>{
        appJsBridge.callWritePrivacyTerms(true);
        if(this.props.isPage)f7.view.main.router.back();
    }
    render() {
        return (
            <>

                <div className="main-warp">
                    <h1 className="title-h1 text-center"><span className="app-name">服务协议与隐私政策</span></h1>

                    <section className={"section-warp text-indent-2"}>
                        欢迎您使用爱省!我们非常重视您的隐私保护和个人信息保护，特别提示您说读并充分理解“服务协议”和“隐私政策”各条款。
                    </section>

                    <section className={"section-warp text-indent-2"}>
                        我们会严格按照法律规定存储和使用您的个人信息，未经您同意，我们不会提供给任何第三方进行使用。我们会采用业界领先的安全措施保护您的个人信息安全。
                    </section>

                    <section className={"section-warp text-indent-2"}>
                        您同意仅在适用的法律法规、公认惯例或指南的允许下使用本软件为一般公众用户编写、改编、传播、分发应用程序、代码片段和脚本，并将保护这些用户的隐私和合法权利。您同意不将本软件以及使用本软件编写、改编、编译、运行的代码片段、应用程序、二进制、脚本用于任何非法用途。
                        您可以阅读
                        {/*<a className={"external"} href="terms.html">*/}
                       <a href={routeMap.TermsRouter.path}>《软件许可及服务协议》</a>和<a href={routeMap.Privacy2Router.path}>《隐私政策》</a>
                        全文了解详细信息。如您同意，请点击“同意”开始接受我们的服务。
                    </section>
                    <div style={{padding:"1px"}}>
                        <Link href={"#"} onClick={this.onCancleClick}>拒绝</Link>
                        <Link href={"#"} onClick={this.onOkClick}>同意</Link>
                    </div>
                </div>
            </>
        )
    }
}

export class PrivacyTerms extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page pageContent={false} className={"no-toolbar"}>
                <Navbar title={PrivacyTermsRouter.name} backLink="返回"/>
                <PageContent>
                    <PrivacyTermsContent  isPage={true}/>
                </PageContent>
            </Page>
        )
    }
}

export const PrivacyTermsRouter = {
    path: "/PrivacyTerms/",
    component: PrivacyTerms,
    name: "PrivacyTerms"
}
