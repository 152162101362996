import {f7} from "framework7-react";
import {logs} from "#/htmls/index/utils/log";

export function f7TryBack(url){
    let count = 0;
    tryBack();
    function tryBack(){
        let now = f7.views.main.router.currentRoute.url;
        if(count++ > 10){
            console.log("f7 try back too much, point url is", url, "now is", now)
            return;
        }
        if(url == now){
            return
        }
        let router =f7.views.main.router;
        router.once("routeChanged", ()=>{
            tryBack(url);
        })
        f7.views.main.router.back(url);
    }
}

export function backOrToUrl(url){
    let print = logs.router.sub(false, "backOrToUrl-backOrToUrl").print;

    let router = f7.views.main.router;
    let nowUrl = router.currentRoute.url;
    print("now url is", nowUrl, "and force back url is", url, "history is", router.history, router.history.length, router.history[0] != url)
    if(router.history.length <= 1 && router.history[0] != url){
        // router.back(url);
        router.navigate(url, {reloadCurrent:true})
        print("force back");
        return
    }
    print("back")
    router.back()
}

export function getRouter(){
    return f7.views.main.router
}