import {ulid} from "ulid";
import _ from "lodash";

/**
 *
 *     public static final int VERSION_UNKNOWN = -1;
 *     public static final int VERSION_DEFAULT = 0;
 *     public static final int VERSION_1_0 = 100;
 *     public static final int VERSION_1_1 = 110;
 *     public static final int VERSION_1_2 = 120;
 *     public static final int VERSION_1_3 = 130;
 *     public static final int VERSION_1_4 = 140;
 *     public static final int VERSION_1_5 = 150;
 *     public static final int VERSION_1_6 = 160;
 *     public static final int VERSION_1_7 = 170;
 *     public static final int VERSION_1_8 = 180;
 *     public static final int VERSION_ES6 = 200;
 *
 */
export enum ScriptLanguageVersion {
    VERSION_UNKNOWN = -1,
    VERSION_DEFAULT = 0,
    VERSION_1_0 = 100,
    VERSION_1_1 = 110,
    VERSION_1_2 = 120,
    VERSION_1_3 = 130,
    VERSION_1_4 = 140,
    VERSION_1_5 = 150,
    VERSION_1_6 = 160,
    VERSION_1_7 = 170,
    VERSION_1_8 = 180,
    VERSION_ES6 = 200,
}

export type IScriptConfInjects = string | string[] | { name: string, alias: string }[];

export interface IScriptConfParams {
    //此id是调用者的webviewjs.webViewJsId()，它会注入到被调用者的script和webViewJs窗口中（如果脚本会创建新的html窗口）
    parentWebViewJsId?: string
    //javaRunnerId.id，它会注入到被调用者的script和webViewJs窗口中（如果脚本会创建新的html窗口）
    parentScriptId?: string
    // java创建webviewjs时可以传这个数据给webviewjs，html可以调用window.android.callJavaToJsDataString()方法来获取这个字符串
    javaToJsDataString?: string

    [key: string]: any
}

export interface IScriptConf {
    bridgeId?: string
    forceStopTime?: number
    onlyRunSingle?: boolean
    stopPrevIfOnlyRunSingle: boolean
    script: string
    languageVersion?: number
    optimizationLevel?:number
    printScript?:boolean

    // 数组条目使用规则
    // ["javaRunner"]数组只有一条内容，启用javaRunner，注入的名字也是javaRunner
    // ["quicker", "quick"] 启用quicker，注入的名字是quick
    // {name:"a11yMode",alias:"model"} 启用a11yModel，注入的名字是model
    // "droid" 启用droid，注入的名字是droid
    // injects:[["javaRunner"], ["quicker", "quick"],{name:"a11yMode",alias:"model"}, "droid"],
    injects?: IScriptConfInjects
    params: IScriptConfParams
    scriptId?:string
}

export type IScriptConfOptional = {
    [key in keyof IScriptConf]?: IScriptConf[key];
};

export function createDefaultScriptConf(): IScriptConf {
    return {
        bridgeId: ulid(),
        forceStopTime: 0,
        injects: [],
        onlyRunSingle: true,
        params: {},
        script: "",
        stopPrevIfOnlyRunSingle: true,
        languageVersion: ScriptLanguageVersion.VERSION_ES6,
        optimizationLevel:-1,
        printScript:false,
        scriptId:undefined,
    }
}

export function mergeScriptConf(conf: IScriptConfOptional): IScriptConf {
    return _.merge({}, createDefaultScriptConf(), conf);
}