import {RhinoScriptEdtorRouter} from "#/htmls/index/pages/RhinoScriptEdtor/RhinoScriptEdtor";
import {editorModel} from "#/htmls/index/mc/editor/EditorModel";
import {getRouter} from "#/libs/fanfanlo/f7/f7RouteUtils";
import {logs} from "#/htmls/index/utils/log";

export function parseCallerProjectId(caller?:string){
    if(!caller)return "";
    // /RhinoScriptEdtor/1709691235147
    // 应该正则提取数字或者是ulid之类的
    let id = caller.replace(RhinoScriptEdtorRouter.p, "");
    return id;
}
export function getProjectByCaller(caller?:string){
    let id = parseCallerProjectId(caller);
    if(!id)return null
    let proj = editorModel.myProjects.getProjectById(id);
    return proj;
}

export function getProjectByRouterCaller(){
    let print = logs.scriptEditFlow.sub(false, "fn_getProjectByRouterCaller-getProjectByRouterCaller").print;
    let caller = getRouter().currentRoute.query?.caller;
    print("caller is", caller);
    if(caller)return getProjectByCaller(caller);
    let url = getRouter().currentRoute.url;
    let proj = getProjectByCaller(url);
    print("get project by router calller", url)
    return proj;
}


