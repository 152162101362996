import {Block, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import "./privacy2.css";
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
export class Privacy2Content extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Block>
                <div className="privacy_bg width">
                    <div className="privacy_title">爱省隐私权政策</div>

                    <div className="privacy_tx">生效日期：2022年11月10日</div>
                    <div className="privacy_tx">最近更新日期：2023年02月20日</div>
                    <div className="privacy_tx">
                        您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取
                        相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，爱省服务提供者（或简称
                        “我们”）制定本《隐私权政策》（下称“本政策/本隐私权政策”）并提醒您：
                    </div>
                    <div className="privacy_tx">
                        本政策适用于爱省提供的所有产品和服务。如我们（范围详见定义部分）的产品或服务中
                        使用了爱省提供的产品或服务（例如使用爱省平台账户登录）但未设独立隐私权政策的，
                        则本政策同样适用于该部分产品或服务。我们向您提供的产品或服务单独设立有隐私权政策
                        的，则相应产品或服务适用相应隐私权政策。
                    </div>
                    <div className="privacy_tx">
                        需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于爱省中
                        已另行独立设置隐私权政策的产品或服务。例如爱省上的卖家依托爱省向您提供服务时，
                        您向卖家提供的个人信息不适用本政策。
                    </div>
                    <div className="privacy_tx">
                        在使用爱省各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗
                        体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如对本政策内
                        容有任何疑问、意见或建议，您可通过爱省提供的各种联系方式与我们联系。
                    </div>
                    <div className="privacy_p1">
                        第一部分定义
                    </div>
                    <div className="privacy_tx">爱省：指爱省客户端。</div>
                    <div className="privacy_tx">爱省服务提供者：指爱省的互联网信息及软件技术服务提供者北京惠省科技有限公司。
                    </div>
                    <div className="privacy_tx">个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份
                        或者反映特定自然人活动情况的各种信息。
                    </div>
                    <div className="privacy_tx">个人敏感信息：指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、
                        交易信息。
                    </div>
                    <div className="privacy_tx">个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可
                        被检索、访问的状态。
                    </div>
                    {/*<dvi className="privacy_tx">*/}
                    {/*    除另有约定外，本政策所用定义与《用户服务协议》中的定义具有相同的涵义。*/}
                    {/*</dvi>*/}
                    <div className="privacy_p1">
                        第二部分隐私权政策
                    </div>
                    <div className="privacy_tx">本隐私权政策部分将帮助您了解以下内容：</div>
                    <div className="privacy_tx">一、我们如何收集和使用您的信息</div>
                    <div className="privacy_tx">二、我们如何使用Cookie和同类技术</div>
                    <div className="privacy_tx">三、我们如何共享、转让、公开披露您的信息</div>
                    <div className="privacy_tx">四、我们如何保护您的信息</div>
                    <div className="privacy_tx">五、您如何管理您的信息</div>
                    <div className="privacy_tx">六、我们如何处理未成年人的信息</div>
                    <div className="privacy_tx">七、您的信息如何在全球范围转移</div>
                    <div className="privacy_tx">八、本隐私权政策如何更新</div>
                    <div className="privacy_tx">九、如何联系我们</div>
                    <div className="1">一、我们如何收集和使用您的信息</div>
                    <div className="privacy_tx">在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下
                        两种：
                    </div>
                    <div className="privacy_tx">1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。
                        如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；
                    </div>
                    <div className="privacy_tx">2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。
                        如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不
                        会影响您正常使用我们产品及/或服务的基本功能。
                    </div>
                    <div className="privacy_tx">您理解并同意：</div>
                    <div className="privacy_tx">1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类
                        众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集
                        使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；
                    </div>
                    <div className="privacy_tx">2、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会
                        不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的
                        或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收
                        集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、
                        使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过爱省提供的各种联系方
                        式与我们联系，我们会尽快为您作出解答。
                    </div>
                    <div className="privacy_tx">我们会为实现本政策下述的各项功能，收集和使用您的个人信息：</div>
                    <div className="privacy_p1">（一）帮助您成为我们的会员</div>
                    <div className="privacy_tx">1、基础会员服务</div>
                    <div className="privacy_tx">我们通过爱省平台账户为您提供基础会员服务。为注册成为会员并使用我们的会员服务，
                        您需要提供手机号码、密码用于创建爱省平台账户。如果您仅需使用浏览、搜索服务，您
                        不需要注册成为我们的会员及提供上述信息。
                    </div>
                    <div className="privacy_tx">对于需要通过爱省平台账户才能使用的服务，我们可能会根据您提供的上述信息校验您的
                        会员身份，确保我们是在为您本人提供服务。
                    </div>
                    <div className="privacy_tx">我们会根据您的会员账户使用情况及平台设置的会员等级计算规则确定您当前的会员等级，
                        并为您提供相应会员等级所对应的基本权益。
                    </div>
                    <div className="privacy_tx">2、附加会员服务</div>
                    <div className="privacy_tx">授权登录：我们可能经您同意后向第三方共享您的账户信息（头像、昵称及其他页面提示的
                        信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据您的授权从
                        第三方处获取您的第三方账户信息，并与您的爱省平台账户进行绑定，使您可通过第三方
                        账户直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使用您的相关信
                        息。
                    </div>
                    <div className="privacy_tx">3、账户信息展示：如果您已拥有爱省平台账户，我们可能会在爱省平台服务中显示您
                        的上述个人信息，以及您在爱省平台上或与爱省平台账户相关联的产品和服务中执行的
                        操作（包括通过爱省平台账户集中展示您的个人资料、优惠权益、交易订单。我们会尊重
                        您对爱省平台服务和爱省平台账户设置所做的选择。
                    </div>
                    <div className="privacy_p1">(二）为您提供商品或服务信息展示</div>
                    <div className="privacy_tx">在您使用我们服务过程中，为识别账号异常状态、了解产品适配性、向您提供更契合您需求
                        的页面展示和搜索结果，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：
                    </div>
                    <div className="privacy_tx">设备信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的
                        设备相关信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境等软
                        硬件特征信息）
                        {/*、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙*/}
                        {/*和基站等传感器信息）。*/}
                    </div>
                    <div className="privacy_tx">服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我
                        们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、以及IP地址、浏览器类型、使用语言、访问日期和时间。
                    </div>
                    <div className="privacy_tx">请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这
                        类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则
                        在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，
                        我们会将这类信息做匿名化、去标识化处理。
                    </div>
                    <div className={"privacy_tx"}>
                        已安装应用列表信息：我们会读取您设备中已安装的应用列表信息，用于分享资讯。
                    </div>
                    {/*<div className="privacy_tx">为向您提供更便捷、更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的设*/}
                    {/*    备信息和服务日志信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、*/}
                    {/*    推送信息和可能的商业广告。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方*/}
                    {/*    式进行退订或关闭。在您使用我们提供的站内搜索服务时，我们也同时提供了不针对您个人*/}
                    {/*    特征的选项。*/}
                    {/*</div>*/}
                    <div className="privacy_tx">此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。</div>
                    <div className="privacy_p1">
                        （三）帮助向您完成商品或服务的交付
                    </div>
                    <div className="privacy_tx">为保证您购买的商品及/或服务能够顺利、安全、准确送达，我们会向为爱省平台提供物
                        流信息系统和披露订单相关配送信息，并由其根据商品及/或服务提供主体的指定向相应的
                        物流配送主体同步相关配送信息。您知晓并同意相应物流配送主体不可避免地获知及使用您
                        的配送信息，用于完成交付目的。
                    </div>
                    <div className="privacy_tx">为使我们及时获悉并确认交付进度及状态，向您提供售后与争议解决服务，您同意我们可自
                        物流相关服务主体处收集与交付进度相关信息。
                    </div>
                    <div className="privacy_p1">
                        （四）客服及争议处理
                    </div>
                    <div className="privacy_tx">当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我
                        们需要您提供必要的个人信息以核验您的会员身份。
                    </div>
                    <div className="privacy_tx">为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存
                        您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提
                        供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建
                        议的，我们会使用您的账号信息和订单信息。
                    </div>
                    <div className="privacy_tx">为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服
                        联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
                    </div>
                    <div className="privacy_p1">（五）为您提供评论、问答等信息公开发布功能</div>
                    <div className="privacy_tx">您可通过我们为您提供的评论、问答、论坛、晒单分享、直播及其他信息发布功能公开发布
                        信息，包括作为会员可发布图文/视频/直播内容、发表评价及问答内容，以及作为卖家发布
                        商品及/或服务相关信息、店铺相关信息。
                    </div>
                    <div className="privacy_tx">请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评
                        价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚
                        至公开分享相关信息。
                    </div>
                    <div className="privacy_p1">
                        （六）为您提供安全保障
                    </div>
                    <div className="privacy_tx">为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众
                        的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、
                        网络侵入等安全风险，更准确地识别违反法律法规或爱省相关协议规则的情况，我们可能
                        使用或整合您的会员信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙
                        伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测
                        及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
                    </div>
                    {/*<div className="privacy_p1">*/}
                    {/*    （七）为您提供其他附加服务*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提*/}
                    {/*    供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您*/}
                    {/*    使用爱省的浏览、搜索、购买等基本服务，但您可能无法获得这些附加服务给您带来的用*/}
                    {/*    户体验。这些附加服务包括：*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">1、基于位置信息的个性化推荐服务：我们会在您开启位置权限后访问获取您的位置信息，*/}
                    {/*    根据您的位置信息为您提供更契合您需求的页面展示、产品及/或服务，比如向您推荐附近*/}
                    {/*    的优惠信息。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">2、基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行扫码用于*/}
                    {/*    登录、购物、领取优惠信息。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">3、基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限*/}
                    {/*    后使用该功能上传您的照片/图片/视频，以实现更换头像、发表评论/分享、拍照购物或与*/}
                    {/*    客服沟通提供证明等功能。我们可能会通过您所上传的照片/图片来识别您需要购买的商品*/}
                    {/*    或服务，或使用包含您所上传照片或图片的评论信息。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">4、基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风实现语音购*/}
                    {/*    物功能，或与客服联系或与客服机器人实现语音交互，在这些功能中我们会收集您的录音内*/}
                    {/*    容以识别您的购物需求，或响应您的客服及争议处理等需求。请您知晓，即使您已同意开启*/}
                    {/*    麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制视频时通过麦克风获取语*/}
                    {/*    音信息。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">5、基于通讯录信息的附加服务：我们将在您开启通讯录权限后收集您的通讯录信息使您在*/}
                    {/*    购物时可以更便利地取用您通讯录内的联系人信息，无需再手动输入，此外，为提升您在使*/}
                    {/*    用我们产品及/或服务过程中的社交互动分享乐趣，与您认识的人分享购物体验，在经您同*/}
                    {/*    意的前提下，我们也可能对您联系人的姓名和电话号码进行加密收集，帮助您判断您的通讯*/}
                    {/*    录联系人是否同为我们的会员进而在爱省为你们的交流建立联系，同时，会员可选择开启*/}
                    {/*    或关闭好友隐私权限决定自己是否可被其他会员通过手机号码搜索、联系。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">6、基于日历的附加服务：在您开启我们可读取/写入您日历的权限后，我们将收集您的日历*/}
                    {/*    信息用于向您提供购物或领取权益相关记录及提醒。*/}
                    {/*</div>*/}
    {/*                <div className="privacy_tx">*/}
    {/*                    您理解并同意，上述附加服务可能需要您在您的设备中开启您的日历,推送通知的访问权限，*/}
    {/*                    以实现这些权限所涉及信息的收集和使用。*/}
    {/*                    <span className="yellow">*/}
    {/*  您可在您的设备设置或我们客户端“我的爱省*/}
    {/*  -设置”中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，*/}
    {/*  您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一*/}
    {/*  旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信*/}
    {/*  息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的*/}
    {/*  授权所进行的信息收集及使用。*/}
    {/*</span>*/}
    {/*                </div>*/}
                    <div className="privacy_p1">（七）其他</div>
                    <div className="privacy_tx">1、若你提供的信息中含有其他用户的个人信息，在向爱省提供这些个人信息之前，您需
                        确保您已经取得合法的授权。
                    </div>
                    <div className="privacy_tx">2、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于
                        其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
                    </div>
                    <div className="privacy_tx">若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已
                        依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给
                        我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，
                        如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团
                        队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。
                        我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息
                        进行保护。
                    </div>
                    <div className="privacy_tx">3、征得授权同意的例外您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们
                        可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
                    </div>
                    <div className="privacy_tx">（1）与国家安全、国防安全有关的；</div>
                    <div className="privacy_tx">（2）与公共安全、公共卫生、重大公共利益有关的；</div>
                    <div className="privacy_tx">（3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</div>
                    <div className="privacy_tx">（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
                    <div className="privacy_tx">（5）您自行向社会公众公开的个人信息；</div>
                    <div className="privacy_tx">（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>
                    <div className="privacy_tx">（7）根据与您签订和履行相关协议或其他书面文件所必需的；</div>
                    <div className="privacy_tx">（8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/
                        或服务的故障；
                    </div>
                    <div className="privacy_tx">（9）为合法的新闻报道所必需的；</div>
                    <div className="privacy_tx">（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述
                        的结果时，对结果中所包含的个人信息进行去标识化处理的；
                    </div>
                    <div className="privacy_tx">（11）法律法规规定的其他情形。</div>
                    <div className="privacy_tx">请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得
                        数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地
                        研究、统计分析和预测，用于改善爱省的内容和布局，为商业决策提供产品或服务支撑，
                        以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处
                        理后数据的使用无需另行向您通知并征得您的同意。
                    </div>
                    <div className="privacy_tx">4、如我们停止运营爱省产品或服务，我们将及时停止继续收集您个人信息的活动，将停
                        止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人
                        信息进行删除或匿名化处理。
                    </div>
                    <div className="p1 yellow">
                        二、我们如何使用Cookie和同类技术
                    </div>
                    <div className="tx yellow">（一）Cookie</div>
                    <div className="tx yellow">为确保网站正常运转、为您获得更轻松的访问体验，我们会
                        在您的计算机或移动设备上存储Cookie，或浏览器（或关联应用程序）提供
                        的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助
                        于Cookie，网站能够存储您的偏好或购物车内的商品等数据。
                    </div>
                    <div className="tx yellow">如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。
                        有关详情，请参见AboutCookies.org。但如果您这么做，在某些情况下可能会影响您安全访
                        问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。
                    </div>
                    <div className="tx yellow">（二）Cookie同类技术</div>
                    <div className="tx yellow">除Cookie外，我们还会在网站上使用网站信标、像素标签、ETag等其他同类技术。</div>
                    <div className="tx yellow">例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链
                        接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好，以便于我们主动改善客户
                        服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的
                        像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，
                        则可以随时从我们的寄信名单中退订。
                    </div>
                    <div className="tx yellow">ETag（实体标签）是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代
                        替Cookie。ETag可以帮助我们避免不必要的服务器负载，提高服务效率，节省资源、能源，
                        同时，我们可能通过ETag来记录您的身份，以便我们可以更深入地了解和改善我们的产品
                        或服务。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功
                        能中进行相应的数据清除操作。但请注意，如果停用ETag，您可能无法享受相对更佳的产
                        品或服务体验。
                    </div>
                    <div className="privacy_p1">
                        三、我们如何共享、转让、公开披露您的信息
                    </div>
                    <div className="privacy_p1">（一）共享</div>
                    <div className="privacy_tx">我们不会与爱省服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况
                        除外：
                    </div>
                    <div className="privacy_tx">1、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、
                        司法机关依法提出的要求，对外共享您的个人信息。
                    </div>
                    <div className="privacy_tx">2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信
                        息。
                    </div>
                    <div className="privacy_tx">3、在您主动选择情况下共享：您通过爱省平台购买商品或服务，我们会根据您的选择，
                        将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易
                        及售后服务需求。
                    </div>
                    <div className="privacy_tx">4、与关联公司间共享：为便于我们基于爱省平台账户向您提供产品和服务，
                        识别会员账号异常，保护爱省关联公司或其他用户或公众的人身财产安全
                        免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只
                        会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感
                        信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
                    </div>
                    {/*<div className="privacy_tx">5、与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职*/}
                    {/*    能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，*/}
                    {/*    授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">目前，我们的授权合作伙伴包括以下类型：</div>*/}
                    {/*<div className="privacy_tx">（1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身*/}
                    {/*    份信息与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面*/}
                    {/*    和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处*/}
                    {/*    理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结*/}
                    {/*    合，以执行我们委托的广告服务或决策建议。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">（2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服*/}
                    {/*    务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服*/}
                    {/*    务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">我们会对授权合作伙伴获取有关信息的应用程序接口<span className="yellow">（API）、软件工具开发包（SDK）</span>进行*/}
                    {/*    严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、*/}
                    {/*    服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。*/}
                    {/*</div>*/}
                    <div className="privacy_p1">（二）转让</div>
                    <div className="privacy_tx">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</div>
                    <div className="privacy_tx">
                        1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信
                        息；
                    </div>
                    <div className="privacy_tx">2、在爱省服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清
                        算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本
                        政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
                    </div>
                    <div className="privacy_p1">（三）公开披露</div>
                    <div className="privacy_tx">我们仅会在以下情况下，公开披露您的个人信息：</div>
                    <div className="privacy_tx">1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</div>
                    <div className="privacy_tx">2、如果我们确定您出现违反法律法规或严重违反爱省平台相关协议及规则的情况，或为
                        保护爱省平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意
                        的情况下披露关于您的个人信息，包括相关违规行为以及爱省平台已对您采取的措施。例
                        如，若您因出售假冒商品而严重违反爱省规则，我们可能会公开披露您的店铺认证主体信
                        息与违规情况。
                    </div>
                    <div className="privacy_p1">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</div>
                    <div className="privacy_tx">以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</div>
                    <div className="privacy_tx">1、与国家安全、国防安全有关的；</div>
                    <div className="privacy_tx">2、与公共安全、公共卫生、重大公共利益有关的；</div>
                    <div className="privacy_tx">3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</div>
                    <div className="privacy_tx">4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
                    <div className="privacy_tx">5、您自行向社会公众公开的个人信息；</div>
                    <div className="privacy_tx">6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>
                    <div className="privacy_tx">请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得
                        数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露
                        无需另行向您通知并征得您的同意。
                    </div>
                    <div className="privacy_p1">四、我们如何保护您的信息</div>
                    <div className="privacy_tx">（一）我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭
                        到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间
                        交换数据时受SSL协议加密保护；我们同时对爱省网站提供HTTPS协议安全浏览方式；我
                        们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到
                        恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们
                        会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
                    </div>
                    <div className="privacy_tx">（二）我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组
                        织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
                    </div>
                    <div className="privacy_tx">（三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策
                        所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，例如《中华人民共
                        和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。
                        而我们判断前述期限的标准包括：
                    </div>
                    <div className="privacy_tx">1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉;</div>
                    <div className="privacy_tx">2、保证我们为您提供服务的安全和质量；</div>
                    <div className="privacy_tx">3、您是否同意更长的留存期间；</div>
                    <div className="privacy_tx">4、是否存在保留期限的其他特别约定。</div>
                    <div className="privacy_tx">
                        在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿
                        名化处理。
                    </div>
                    {/*<div className="privacy_tx">（四）互联网并非绝对安全的环境，使用爱省平台服务时，*/}
                    {/*    您可以通过我们的服务建立联系和相互分享。当您*/}
                    {/*    通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为*/}
                    {/*    能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。*/}
                    {/*</div>*/}
                    <div className="privacy_tx">在使用爱省服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己
                        的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向
                        他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络爱省
                        客服，以便我们根据您的申请采取相应措施。
                    </div>
                    <div className="privacy_tx">请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信
                        息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相
                        关信息。
                    </div>
                    <div className="privacy_tx">请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的
                        安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披
                        露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
                    </div>
                    <div className="privacy_tx">（五）我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通
                        过爱省公告方式获得。
                    </div>
                    <div className="privacy_tx">（六）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的
                        基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建
                        议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话等方式告知您，
                        难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
                    </div>
                    <div className="privacy_tx">同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</div>
                    <div className="privacy_p1">五、您如何管理您的信息</div>
                    <div className="privacy_tx">您可以通过以下方式访问及管理您的信息：</div>
                    <div className="privacy_p1">
                        （一）查询、更正和补充您的信息
                    </div>
                    <div className="privacy_tx">您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：</div>
                    <div className="tx yellow">1、登录爱省，进入“我的爱省”首页，点击个人账户设置后查询、更正个人资料及个
                        人账户相关信息；
                    </div>
                    <div className="tx yellow">2、登录手机爱省客户端，进入“我的爱省-设置”对个人资料、等信息进行查询、更正
                        或补充；
                    </div>
                    <div className="privacy_p1">（二）删除您的信息</div>
                    <div className="privacy_tx">您可以通过“（一）查询、更正和补充您的信息”中列明的方式删除您的部分信息。</div>
                    <div className="privacy_tx">在以下情形中，您可以向我们提出删除个人信息的请求：</div>
                    <div className="privacy_tx">1、如果我们处理个人信息的行为违反法律法规；</div>
                    <div className="privacy_tx">2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</div>
                    <div className="privacy_tx">3、如果我们处理个人信息的行为严重违反了与您的约定；</div>
                    <div className="privacy_tx">4、如果您不再使用我们的产品或服务，或您主动注销了账号；</div>
                    <div className="privacy_tx">5、如果我们永久不再为您提供产品或服务。</div>
                    <div className="privacy_tx">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，
                        并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
                    </div>
                    <div className="privacy_tx">当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份
                        系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直
                        到备份可以清除或实现匿名。
                    </div>
                    <div className="privacy_p1">
                        （三）改变您授权同意的范围
                    </div>
                    <div className="privacy_tx">每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”）。当您
                        收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您
                        的授权而开展的个人信息处理。
                    </div>
                    <div className="privacy_p1">
                        （四）注销您的账户
                    </div>
                    <div className="privacy_tx">您可以通过以下方式申请注销您的账户：</div>
                    <div className="privacy_tx">打开爱省app-设置-注销账号，申请注销您的账户。
                    </div>
                    {/*<div className="privacy_tx">或者您也可以点击这个链接<a href={routeMap.UnsubscribeRouter.path}>注销账号</a>。*/}
                    {/*</div>*/}
                    <div className="privacy_tx">在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个
                        人信息，或使其匿名化处理。
                    </div>
                    <div className="privacy_tx">您在注销账户后60天内请不要用改账号再次登录以及进行操作，避免注销账号进程停止。
                    </div>
                    <div className="privacy_p1">
                        （五）约束信息系统自动决策
                    </div>
                    <div className="privacy_tx">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。
                        如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害爱省
                        商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
                    </div>
                    <div className="privacy_p1">
                        （六）响应您的上述请求
                    </div>
                    <div className="privacy_tx">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您
                        验证自己的身份，然后再处理您的请求。
                    </div>
                    <div className="privacy_tx">我们将在15天内做出答复。如您不满意，还可以通过爱省客服发起投诉。</div>
                    <div className="privacy_tx">对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术
                        手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不
                        切实际的请求，我们可能会予以拒绝。
                    </div>
                    <div className="privacy_tx">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
                    <div className="privacy_tx">1、与国家安全、国防安全有关的；</div>
                    <div className="privacy_tx">2、与公共安全、公共卫生、重大公共利益有关的；</div>
                    <div className="privacy_tx">3、与犯罪侦查、起诉、审判和执行判决等有关的；</div>
                    <div className="privacy_tx">4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</div>
                    <div className="privacy_tx">5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</div>
                    <div className="privacy_tx">6、涉及商业秘密的。</div>
                    <div className="privacy_p1">六、在某些特定业务场景下，为提升业务处理能力，我们可能会接入由第三方提供的软件开发包（SDK）来为您提供服务。我们接入的部分第三方SDK可能会收集使用您的个人信息，这些SDK包括</div>

                    <div className="privacy_tx">腾讯bugly SDK : 为了优化APP各项服务功能，我们使用了腾讯Bugly SDK以统计APP的报错信息（如APP闪退日志、卡顿日志等），为此需要获取您的唯一设备标识。</div>
                    <div className="privacy_tx">腾讯开放平台 SDK : 需要获取您的已安装应用列表，用于资讯分享。</div>
                    {/*<div className="privacy_p1">六、我们如何处理未成年人的信息</div>*/}
                    {/*<div className="privacy_tx">在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您*/}
                    {/*    的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们*/}
                    {/*    的服务或向我们提供信息。*/}
                    {/*</div>*/}
                    {/*<div className="privacy_tx">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会*/}
                    {/*    在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转*/}
                    {/*    让或披露此信息。*/}
                    {/*</div>*/}
                    <div className="privacy_p1">
                        七、您的信息如何在全球范围转移
                    </div>
                    <div className="privacy_tx">我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</div>
                    <div className="privacy_tx">1、适用的法律有明确规定；</div>
                    <div className="privacy_tx">2、获得您的明确授权；</div>
                    <div className="privacy_tx">3、您通过互联网进行跨境交易等个人主动行为。</div>
                    <div className="privacy_tx">针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。</div>
                    <div className="privacy_p1">八、本隐私权政策如何更新</div>
                    <div className="privacy_tx">我们的隐私权政策可能变更。</div>
                    <div className="privacy_tx">未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。我们会在专门页面上
                        发布对隐私权政策所做的任何变更。
                    </div>
                    <div className="privacy_tx">对于重大变更，我们还会提供更为显著的通知（包括我们会通过爱省公示的方式进行通知
                        甚至向您提供弹窗提示）。
                    </div>
                    <div className="privacy_tx">本政策所指的重大变更包括但不限于：</div>
                    <div className="privacy_tx">1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信
                        息的使用方式等；
                    </div>
                    <div className="privacy_tx">2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</div>
                    <div className="privacy_tx">3、个人信息共享、转让或公开披露的主要对象发生变化；</div>
                    <div className="privacy_tx">4、您参与个人信息处理方面的权利及其行使方式发生重大变化</div>
                    <div className="privacy_tx">5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</div>
                    <div className="privacy_tx">6、个人信息安全影响评估报告表明存在高风险。</div>
                    <div className="privacy_tx">我们还会将本隐私权政策的旧版本在爱省专门页面存档，供您查阅。</div>
                    <div className="privacy_p1">九、如何联系我们</div>
                    <div className="privacy_tx">您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</div>
                    <div className="privacy_tx">邮箱：fanfanlo@outlook.com</div>
                    <div className="privacy_tx">微信：baobeidaola_kefu1</div>
                    {/*<div className="privacy_tx">1、如对本政策内容有任何疑问、意见或建议，您可通过爱省客服及爱省服务中心在线*/}
                    {/*    客服与我们联系；*/}
                    {/*</div>*/}
                    <div className="privacy_tx">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，
                        您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
                    </div>

                </div>
            </Block>
        )
    }
}

export class Privacy extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page pageContent={false} className={"no-toolbar"}>
                <Navbar title={Privacy2Router.name} backLink="返回"/>
                <PageContent>
                    <Privacy2Content/>
                </PageContent>
            </Page>
        )
    }
}

export const Privacy2Router = {
    path: "/Privacy/",
    component: Privacy,
    name: "Terms"
}
// http://192.168.177.180:17001/privacy.html
// https://shell.web.fanli.app.baobeidaola.com/shell/privacy.html
// https://shell.web.fanli.app.baobeidaola.com/shell/index.html?app#/Privacy/
