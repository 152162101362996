import {IAction} from "#/htmls/index/vo/project/base/IAction";
import {IPermissionUser} from "#/htmls/index/vo/project/base/IMissionData";


export function mergeActionListPermissions(list:IPermissionUser[]){
    let res:string[] = [];
    for (let i = 0; i < list.length; i++) {
        let ps = list[i].getPermissions();
        if(!ps)continue
        for (let j = 0; j < ps.length; j++) {
            let p = ps[j];
            if(res.indexOf(p) > -1)continue
            res.push(p)
        }
    }
    return res;
}