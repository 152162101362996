import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {PropertyChangeEvent} from "#/libs/fanfanlo/events/PropertyChangeEvent";
import {dispatchPropertyChangeEvent} from "#/libs/fanfanlo/events/dispatchPropertyChangeEvent";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {showSimpleToast} from "#/ui-components/toast/toast";

// window.global ||= window;
if(!window.global){
  window.global = window
}
class AppData {

}

// function dispatchPropertyChangeEvent(oldValue:any, newValue:any, propertyName:string, owner:any, eventName:string, checkIsSame:boolean){
//   if(checkIsSame && oldValue === newValue)return;
//   owner[eventName] = newValue;
//   let event = new PropertyChangeEvent(AppJsBridge.eventHasFloatPermissionChanged, oldValue, newValue, PropertyChangeEvent.kindUpdate);
//   owner.dispatchEvent(event);
// }
export class AppJsBridge extends EventDispatcher {
  public loadedCalled: boolean = false;

  get android(){
    return window.android;
  }
  callLoaded = () => {
    if (this.loadedCalled || !window.android) return;
    window.android.onDocumentReady();
  }
  callSetUseQuickFloatTools = (bl: Boolean) => {
    if (this.loadedCalled || !window.android) return;
    window.android.onCallSetUseQuickFloatTools(bl);
  }
  callPauseAccessibility = (bl: Boolean) => {
    if (this.loadedCalled || !window.android) return;
    window.android.onCallPauseAccessibility(bl);
  }
  callToggleDebugFloatWindow = () => {
    if (this.loadedCalled || !window.android) return;
    window.android.onCallToggleDebugFloatWindow();
  }
  callWxScanXcx = (url) => {
    if (this.loadedCalled || !window.android) return;
    window.android.onCallWxScanXcx(url);
  }
  callSendLogs = () => {
    if (!window.android) return;
    window.android.onCallSendLogs();
    alert("数据已发送，我们将尽快解决问题，非常感谢您的支持！！！")
  }
  callFloatPermiss = () => {
    if (!window.android) return;
    window.android.onCallFloatPremiss();
  }
  callOpenAccessibility = () => {
    if (!window.android) return;
    window.android.onCallOpenAccessibility();
  }
  callCloseAccessibility = () => {
    if (!window.android) return;
    window.android.onCallCloseAccessibility();
  }
  callGetAccessibilityPermission =()=>{
    if (!window.android || !window.android.onCallGetAccessibilityPermission) return;
    const bl = window.android.onCallGetAccessibilityPermission();
    return bl;
  }
  callOnCreateUserLoginCode = (code: String) => {
    if (!window.android) return;
    window.android.onCreateUserLoginCode(code);
  }
  callUserLogout = () => {
    if (!window.android) return;
    window.android.onUserLogout();
  }
  callJdUrl = (url: string) => {
    if (!window.android) return;
    window.android.onCallJdUrl(url);
  }
  callTaobaoUrl = (url: String) => {
    if (!window.android) return;
    window.android.onCallTaobaoUrl(url);
  }
  callVipUrl = (url: String) => {
    if (!window.android) return;
    window.android.onCallVipUrl(url);
  }
  callPddUrl = (url: String) => {
    if (!window.android) return;
    window.android.onCallPddUrl(url);
  }
  callSetAppMulti = (conf: string) => {
    if (!window.android) return;
    window.android.onCallSetAppMulti(conf);
  }

  callGetAppMulti = () => {
    if (!window.android) return;
    let s = window.android.onCallGetAppMulti();
    console.log("aaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbb", s);
    return s;
  }
  callReadPrivacyTerms = () => {
    if (!window.android) return true;
    let bl = window.android.onCallReadPrivacyTerms();
    this.privacyTermsOk = bl;
    return bl;
  }
  callAppVersion =()=>{
    if (!window.android) return "";
    let s = window.android.onCallReadAppVersion();
    return  s;
  }
  callAppUpdate =()=>{
    if(!window.android)return;
    window.android.onCallAppUpdate();
  }
  callWritePrivacyTerms = (bl) => {
    if (!window.android) return;
    window.android.onCallWritePrivacyTerms(bl);
    this.privacyTermsOk = bl;
    console.log("ccccccccccccccccc", bl)
  }

  callOpenXcx = (id:string, path:string)=>{
    if (!window.android) return;
    window.android.onCallOpenXcx(id, path);
  }

  callOpenUrl = (url:string)=>{
    if (!window.android) return;
    window.android.onCallOpenUrl(url);
  }

  callOpenSchema = (schema:string)=>{
    if (!window.android) return;
    window.android.onCallOpenSchema(schema);
  }
  private _privacyTermsOk:string = "privacyTermsOk";
  static readonly eventPrivacyTermsOkChanged = "eventPrivacyTermsOkChanged";
  get privacyTermsOk():string{
      return this._privacyTermsOk;
  }
  set privacyTermsOk(value:string){
      dispatchPropertyChangeEvent(value, "_privacyTermsOk", this, AppJsBridge.eventPrivacyTermsOkChanged, value == this["_privacyTermsOk"]);
  }
  private _appMulti: string = "";
  static readonly eventAppMultiChanged = "eventAppMultiChanged";

  get appMulti(): string {
    return this._appMulti;
  }

  set appMulti(value: string) {
    dispatchPropertyChangeEvent(value, "_appMulti", this, AppJsBridge.eventAppMultiChanged, value == this["_appMulti"]);
  }

  private _hasFloatPermission: boolean = false;
  static readonly eventHasFloatPermissionChanged = "eventHasFloatPermissionChanged";

  get hasFloatPermission(): boolean {
    return this._hasFloatPermission;
  }

  set hasFloatPermission(value: boolean) {
    dispatchPropertyChangeEvent(value, "_hasFloatPermission", this, AppJsBridge.eventHasFloatPermissionChanged, true);
  }


  private _hasAccessibility: boolean = false;
  static readonly eventHasAccessibilityChanged = "eventHasAccessibilityChanged";

  get hasAccessibility(): boolean {
    return this._hasAccessibility;
  }

  set hasAccessibility(value: boolean) {
    dispatchPropertyChangeEvent(value, "_hasAccessibility", this, AppJsBridge.eventHasAccessibilityChanged, true);
  }

  private _useQuickFloatToolsValue: boolean = false;
  static readonly eventUseQuickFloatToolsValueChanged = "eventUseQuickFloatToolsValueChanged";

  get useQuickFloatToolsValue(): boolean {
    return this._useQuickFloatToolsValue;
  }

  set useQuickFloatToolsValue(value: boolean) {
    dispatchPropertyChangeEvent(value, "_useQuickFloatToolsValue", this, AppJsBridge.eventUseQuickFloatToolsValueChanged, value == this["_useQuickFloatToolsValue"]);
  }


  private _rebate_float_panel_remote_by_app:boolean = false;
  static readonly eventRebate_float_panel_remote_by_appChanged = "eventRebate_float_panel_remote_by_appChanged";
  get rebate_float_panel_remote_by_app():boolean{
      return this._rebate_float_panel_remote_by_app;
  }
  set rebate_float_panel_remote_by_app(value:boolean){
    dispatchPropertyChangeEvent(value, "_rebate_float_panel_remote_by_app", this, AppJsBridge.eventRebate_float_panel_remote_by_appChanged, value == this["_rebate_float_panel_remote_by_app"]);
  }
  private _pauseAccessibility: boolean = false;
  static readonly eventPauseAccessibilityChanged = "eventPauseAccessibilityChanged";

  get pauseAccessibility(): boolean {
    return this._pauseAccessibility;
  }

  set pauseAccessibility(value: boolean) {
    dispatchPropertyChangeEvent(value, "_pauseAccessibility", this, AppJsBridge.eventPauseAccessibilityChanged, value == this["_pauseAccessibility"]);
  }


  private _showPath: any = null;
  static readonly eventShowPathChanged = "eventShowPathChanged";

  get showPath(): any {
    return this._showPath;
  }

  set showPath(value: any) {
    dispatchPropertyChangeEvent(value, "_showPath", this, AppJsBridge.eventShowPathChanged, value == this["_showPath"]);
  }

  static readonly eventOnResume = "onResume"
  dispatchOnResume = () => {
    let event = new DataEvent(AppJsBridge.eventOnResume);
    this.dispatchEvent(event)
  }
  static readonly eventGotoTransfer = "gotoTransfer"
  dispatchGotoTransfer =(data) =>{
    let event = new DataEvent(AppJsBridge.eventGotoTransfer, data)
    this.dispatchEvent(event)
  }
  static readonly eventEditShareGoods = "editShareGoods"
  dispatchEditShareGoods =(data) =>{
    let event = new DataEvent(AppJsBridge.eventEditShareGoods, data)
    this.dispatchEvent(event)
  }
  static readonly eventEditInvite = "editInvite"
  dispatchEditInvite = ()=>{
    let event = new DataEvent(AppJsBridge.eventEditInvite)
    this.dispatchEvent(event);
  }
  static readonly eventGotoTransfer2 = "gotoTransfer2"
  dispatchGotoTransfer2 =(data) =>{
    let event = new DataEvent(AppJsBridge.eventGotoTransfer2, data)
    this.dispatchEvent(event)
  }
  callWxWaiter=(type)=>{
    if (!window.android) return;
    if(!window.android.onCallWxWaiter)return;
    window.android.onCallWxWaiter(type);
  }
  callWxWaiterDirectly=(url)=>{
    if (!window.android) return;
    if(!window.android.onCallWxWaiterDirectly)return;
    window.android.onCallWxWaiterDirectly(url);
  }
  callGoodsUrlTransfer=()=>{
    if (!window.android) return;
    if(!window.android.onCallGoodsUrlTransfer)return;
    window.android.onCallGoodsUrlTransfer();
  }
  callReadClipboardWhenAppActivity =()=>{
    if (!window.android) return false;
    if(!window.android.onCallReadReadClipboardWhenAppActivity)return false;
    return window.android.onCallReadReadClipboardWhenAppActivity();
  }
  callSetReadClipboardWhenAppActivity =(bl:Boolean)=>{
    if (!window.android) return;
    if(!window.android.onCallWriteReadClipboardWhenAppActivity)return;
    window.android.onCallWriteReadClipboardWhenAppActivity(bl)
  }



  callReadRebateFloatPanelRemoteByApp =()=>{
    if (!window.android) return false;
    if(!window.android.onCallReadRebateFloatPanelRemoteByApp)return false;
    return window.android.onCallReadRebateFloatPanelRemoteByApp();
  }
  callSetRebateFloatPanelRemoteByApp =(bl:Boolean)=>{
    if (!window.android) return false;
    if(!window.android.onCallSetRebateFloatPanelRemoteByApp)return false;
    window.android.onCallSetRebateFloatPanelRemoteByApp(bl);
    return true;
  }
// 微信分享的几个场景
//   分享到对话:
//       SendMessageToWX.Req.WXSceneSession
//   分享到朋友圈:
//       SendMessageToWX.Req.WXSceneTimeline ;
//   分享到收藏:
//       SendMessageToWX.Req.WXSceneFavorite
  // public static final int WXSceneSession = 0;
  // public static final int WXSceneTimeline = 1;
  // public static final int WXSceneFavorite = 2;
  // public static final int WXSceneSpecifiedContact = 3;
  // public static final int WXSceneStatus = 4;
  callShareUrlToWx = (url: String, title: String, description: String, thumbUrl: String, scene:Number)=>{
    if(!window.android)return;
    if(!window.android.onCallShareUrlToWx)return;
    window.android.onCallShareUrlToWx(url, title, description, thumbUrl, scene)
  }

  callShareTextToWx(text:String, description:String, scene:Number){
    if(!window.android)return;
    if(!window.android.onCallShareTextToWx)return;
    window.android.onCallShareTextToWx(text, description, scene)
  }

  callShareTextToOtherApp(text:String){
    if(!window.android)return;
    if(!window.android.onCallShareTextToOtherApp)return;
    window.android.onCallShareTextToOtherApp(text)
  }

  callShareImgToWx(url:String, scene:Number){
    console.log("share image to wx 1")
    if(!window.android)return;
    console.log("share image to wx 2")
    if(!window.android.onCallShareImgToWx)return;
    console.log("share image to wx 3")
    window.android.onCallShareImgToWx(url, scene)
  }

  callShareXcxToWx(userName:String, path:String, title:String, description: String, thumbUrl: String, scene: Number){
    if(!window.android)return;
    if(!window.android.onCallShareXcx)return;
    window.android.onCallShareXcx(userName, path, title, description, thumbUrl, scene)
  }
  callReadClipboard(){
    if(!window.android)return "";
    if(!window.android.onCallReadClipboard)return "";
    return window.android.onCallReadClipboard();
  }
  callWxInvite(url:String, title:String, description: String, thumbUrl: String){
    console.log("cccccc")
    if(!window.android)return "";
    if(!window.android.wxInvite)return "";
    return window.android.wxInvite(url, title, description, thumbUrl);
  }
  callRhino1(script:String){
    console.log("callRhino1")
    if(!window.android)return "";
    if(!window.android.callRhino1)return "";
    return window.android.callRhino1(script);
  }
  readAllPackages(){
    console.log("callRhino1")
    if(!window.android)return "";
    if(!window.android.readAllPackages)return "";
    return window.android.readAllPackages();
  }
  openApp(pn){
    if(!window.android)return "";
    if(!window.android.openApp)return "";
    return window.android.openApp(pn);
  }
  openHelperTools(){
    if(!window.android)return "";
    if(!window.android.openHelperTools)return "";
    return window.android.openHelperTools();
  }
  callScript(script){
    if(!window.android)return "";
    if(!window.android.callScript)return "";
    return window.android.callScript(script);
  }
  onTest(){
    if(!window.android)return "";
    if(!window.android.onTest)return "";
    return window.android.onTest();
  }
  createWindow(json){
    if(!window.android)return "";
    if(!window.android.createWindow)return "";
    return window.android.createWindow(json);
  }
}

const appJsBridge = new AppJsBridge();

(() => {
  global["floatPermissionUpdated"] = function (hasPermission: boolean) {
    appJsBridge.hasFloatPermission = hasPermission;
    // console.log("js receiver floatPermissionUpdated called'", hasPermission);
    return "floatPermissionUpdated"
  }
  global["accessibilityUpdated"] = function (hasPermission: boolean) {
    appJsBridge.hasAccessibility = hasPermission;
    // console.log("js receiver accessibilityUpdated called'", hasPermission);
    return "accessibilityUpdated"
  }
  global["updateFloatAndAccessibility"] = function (s: string) {
    let data = JSON.stringify(s)
    console.log('ssssssssssssssssss', data)
    return "updateFloatAndAccessibility"
  }
  global["setUseQuickFloatTools"] = function (bl: boolean) {
    appJsBridge.useQuickFloatToolsValue = bl
    console.log('setUseQuickFloatTools aaaaaaaaaaaaaaaaaa', bl)
    return "setUseQuickFloatTools"
  }
  global["setShowPath"] = function (s: any) {
    appJsBridge.showPath = s
    return "setShowPath"
  }
  global["onResume"] = function () {
    appJsBridge.dispatchOnResume();
    return "onResume"
  }

  // android called
  global["printAndroidInfo"] = function (info){
    console.log("android call printAndroidInfo", info);
    try{
      const s = JSON.stringify(info);
      showSimpleToast(s)
      return true
    }catch (e){
      return e.message;
    }
    return "printAndroidInfo"
  }
  global["gotoTransfer"] = function (data){
    console.log("gotoTransfer gotoTransfer gotoTransfer", data)
    appJsBridge.dispatchGotoTransfer(data)
    return "gotoTransfer"
  }
  global["gotoTransfer2"] = function (data:String){
    console.log("gotoTransfer2 gotoTransfer2 gotoTransfer2", data)
    appJsBridge.dispatchGotoTransfer2(data)
    return "gotoTransfer2"
  }
  global["editShareGoods"] = function (url:String){
    console.log("editShareGoods editShareGoods editShareGoods", url)
    appJsBridge.dispatchEditShareGoods(url)
    return "editShareGoods"
  }

  global["editInvite"] = function (){
    console.log("editInvite_call_from_android")
    appJsBridge.dispatchEditInvite()
  }
})()
export default appJsBridge;
// export default AppJsBridge;
