import {LibPathKey, runJavaLibScript} from "#/droid/java_js/fanfanlo/javajs/v1/script/runner/scriptRunner";
import script07 from "./projectScript07.js?raw";
function parseUsedLib(s:string){
    let res:LibPathKey[] = [];
    let reg = /javaLib\[\"(.*)\"\]/g
    let r = s.matchAll(reg);
    for(let a of r){
        res.push(a[1] as LibPathKey)
    }
    return res;
}
let beforeJavaLibCreateScript = `
let isScriptRunnable = true;
let scriptExitListeners = [];
function addScriptExitListener(f){
    removeScriptExitListener(f);
    scriptExitListeners.push(f);
}
function removeScriptExitListener(f){
    let index = scriptExitListeners.indexOf(f);
    if(index == -1)return;
    scriptExitListeners.splice(index, 1);
}
function onJavaCallTerminate(){
    callScriptExit();
    return true;
}
function callJavaExit(){

}
function jsCallComplete(){
    callScriptExit();
    callJavaExit();
}

function jsCallFailed(){
    callScriptExit();
    callJavaExit();
}
function callScriptExit(){
    isScriptRunnable = false;
    let res = ""
    console.log('before call exit', scriptExitListeners.length);
    scriptExitListeners.forEach((f)=>{f()});
    console.log('after call exit');
    return res;
}
    `

export function runProjectScriptDemo(){
    let script = script07;
    let dependencies:LibPathKey[] = parseUsedLib(script).concat(parseUsedLib(beforeJavaLibCreateScript));

    runJavaLibScript({script, injects:["console", "a11yModel"],
        optimizationLevel:-1, printScript:false,
        scriptId:"demo.project"
    }, dependencies, beforeJavaLibCreateScript)
}