import {
    SFActionClick, SFActionGlobalClick,
    SFActionLongClick,
    SFActionSetText,
    SFActionTryClick
} from "#/htmls/index/vo/project/a11y/actions/sfdata";


export enum a11yNodeProperyEnum {

    viewIdResourceName="viewIdResourceName",
    contentDescription="contentDescription",
    hintText="hintText",
    text="text",
    className="className",

    availableExtraData="availableExtraData",
    boundsInScreen="boundsInScreen",
    children="children",
    isAccessibilityDataSensitive="isAccessibilityDataSensitive",
    isAccessibilityFocused="isAccessibilityFocused",
    isCheckable="isCheckable",
    isChecked="isChecked",
    isClickable="isClickable",
    isContentInvalid="isContentInvalid",
    isContextClickable="isContextClickable",
    isDismissable="isDismissable",
    isEditable="isEditable",
    isEnabled="isEnabled",
    isFocusable="isFocusable",
    isFocused="isFocused",
    isHeading="isHeading",
    isImportantForAccessibility="isImportantForAccessibility",
    isLongClickable="isLongClickable",
    isMultiLine="isMultiLine",
    isPassword="isPassword",
    isScreenReaderFocusable="isScreenReaderFocusable",
    isScrollable="isScrollable",
    isSelected="isSelected",
    isShowingHintText="isShowingHintText",
    isTextEntryKey="isTextEntryKey",
    isTextSelectable="isTextSelectable",
    isVisibleToUser="isVisibleToUser",
    index="index",
    listIndex="listIndex"
}
export interface IA11yNode {

    [a11yNodeProperyEnum.viewIdResourceName]?:string,
    [a11yNodeProperyEnum.contentDescription]?:string,
    [a11yNodeProperyEnum.hintText]?:string,
    [a11yNodeProperyEnum.text]?: string,
    [a11yNodeProperyEnum.className]?: string,

    [a11yNodeProperyEnum.availableExtraData]?: string[],
    [a11yNodeProperyEnum.boundsInScreen]?: { bottom: 573, left: 88, right: 264, top: 501 },
    [a11yNodeProperyEnum.children]?: [],
    [a11yNodeProperyEnum.isAccessibilityDataSensitive]: boolean,
    [a11yNodeProperyEnum.isAccessibilityFocused]: boolean,
    [a11yNodeProperyEnum.isCheckable]: boolean,
    [a11yNodeProperyEnum.isChecked]: boolean,
    [a11yNodeProperyEnum.isClickable]: boolean,
    [a11yNodeProperyEnum.isContentInvalid]: boolean,
    [a11yNodeProperyEnum.isContextClickable]: boolean,
    [a11yNodeProperyEnum.isDismissable]: boolean,
    [a11yNodeProperyEnum.isEditable]: boolean,
    [a11yNodeProperyEnum.isEnabled]: boolean,
    [a11yNodeProperyEnum.isFocusable]: boolean,
    [a11yNodeProperyEnum.isFocused]: boolean,
    [a11yNodeProperyEnum.isHeading]: boolean,
    [a11yNodeProperyEnum.isImportantForAccessibility]: boolean,
    [a11yNodeProperyEnum.isLongClickable]: boolean,
    [a11yNodeProperyEnum.isMultiLine]: boolean,
    [a11yNodeProperyEnum.isPassword]: boolean,
    [a11yNodeProperyEnum.isScreenReaderFocusable]: boolean,
    [a11yNodeProperyEnum.isScrollable]: boolean,
    [a11yNodeProperyEnum.isSelected]: boolean,
    [a11yNodeProperyEnum.isShowingHintText]: boolean,
    [a11yNodeProperyEnum.isTextEntryKey]: boolean,
    [a11yNodeProperyEnum.isTextSelectable]: boolean,
    [a11yNodeProperyEnum.isVisibleToUser]: boolean,
    [a11yNodeProperyEnum.index]: number,
    [a11yNodeProperyEnum.listIndex]: number
}
export type a11yNodePropertyKeys = keyof IA11yNode
// 需要转码的contentDescription、hintText、text 这三个字段进出都需要转码
export class A11yNode implements IA11yNode{

    viewIdResourceName?:string|undefined
    contentDescription?:string|undefined
    hintText?:string|undefined
    text?: string|undefined
    boundsInScreen?: { "bottom": 573, "left": 88, "right": 264 ,"top": 501 }
    children?: []
    className?:string
    availableExtraData?: string[]
    
    index!: number
    listIndex!: number

    isAccessibilityDataSensitive= false
    isAccessibilityFocused= false
    isCheckable= false
    isChecked= false
    isClickable= false
    isContentInvalid= false
    isContextClickable= false
    isDismissable= false
    isEditable= false
    isEnabled = true
    isFocusable= false
    isFocused= false
    isHeading= false
    isImportantForAccessibility= false
    isLongClickable= false
    isMultiLine= false
    isPassword= false
    isScreenReaderFocusable= false
    isScrollable= false
    isSelected= false
    isShowingHintText= false
    isTextEntryKey= false
    isTextSelectable= false
    isVisibleToUser = true
}


export enum A11yNodePropertyType{
    bl = 'bl',
    text = 'text',
    num = 'num',
    arr = 'arr',
    rect = 'rect'
}
export class A11yNodePropertyInfo{
    constructor(public readonly name:a11yNodePropertyKeys, public readonly type:A11yNodePropertyType) {
    }
}
export const a11yNodeProperties:Record<a11yNodePropertyKeys, A11yNodePropertyInfo> = {
    index: new A11yNodePropertyInfo(a11yNodeProperyEnum.index, A11yNodePropertyType.num),
    isAccessibilityDataSensitive: new A11yNodePropertyInfo(a11yNodeProperyEnum.isAccessibilityDataSensitive, A11yNodePropertyType.bl),
    isAccessibilityFocused: new A11yNodePropertyInfo(a11yNodeProperyEnum.isAccessibilityFocused, A11yNodePropertyType.bl),
    isCheckable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isCheckable, A11yNodePropertyType.bl),
    isChecked: new A11yNodePropertyInfo(a11yNodeProperyEnum.isChecked, A11yNodePropertyType.bl),
    isClickable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isClickable, A11yNodePropertyType.bl),
    isContentInvalid: new A11yNodePropertyInfo(a11yNodeProperyEnum.isContentInvalid, A11yNodePropertyType.bl),
    isContextClickable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isContextClickable, A11yNodePropertyType.bl),
    isDismissable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isDismissable, A11yNodePropertyType.bl),
    isEditable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isEditable, A11yNodePropertyType.bl),
    isEnabled: new A11yNodePropertyInfo(a11yNodeProperyEnum.isEnabled, A11yNodePropertyType.bl),
    isFocusable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isFocusable, A11yNodePropertyType.bl),
    isFocused: new A11yNodePropertyInfo(a11yNodeProperyEnum.isFocused, A11yNodePropertyType.bl),
    isHeading: new A11yNodePropertyInfo(a11yNodeProperyEnum.isHeading, A11yNodePropertyType.bl),
    isImportantForAccessibility: new A11yNodePropertyInfo(a11yNodeProperyEnum.isImportantForAccessibility, A11yNodePropertyType.bl),
    isLongClickable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isLongClickable, A11yNodePropertyType.bl),
    isMultiLine: new A11yNodePropertyInfo(a11yNodeProperyEnum.isMultiLine, A11yNodePropertyType.bl),
    isPassword: new A11yNodePropertyInfo(a11yNodeProperyEnum.isPassword, A11yNodePropertyType.bl),
    isScreenReaderFocusable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isScreenReaderFocusable, A11yNodePropertyType.bl),
    isScrollable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isScrollable, A11yNodePropertyType.bl),
    isSelected: new A11yNodePropertyInfo(a11yNodeProperyEnum.isSelected, A11yNodePropertyType.bl),
    isShowingHintText: new A11yNodePropertyInfo(a11yNodeProperyEnum.isShowingHintText, A11yNodePropertyType.bl),
    isTextEntryKey: new A11yNodePropertyInfo(a11yNodeProperyEnum.isTextEntryKey, A11yNodePropertyType.bl),
    isTextSelectable: new A11yNodePropertyInfo(a11yNodeProperyEnum.isTextSelectable, A11yNodePropertyType.bl),
    isVisibleToUser: new A11yNodePropertyInfo(a11yNodeProperyEnum.isVisibleToUser, A11yNodePropertyType.bl),
    listIndex: new A11yNodePropertyInfo(a11yNodeProperyEnum.listIndex, A11yNodePropertyType.num),

    text: new A11yNodePropertyInfo(a11yNodeProperyEnum.text, A11yNodePropertyType.text),
    viewIdResourceName:  new A11yNodePropertyInfo(a11yNodeProperyEnum.viewIdResourceName, A11yNodePropertyType.text),
    contentDescription:  new A11yNodePropertyInfo(a11yNodeProperyEnum.contentDescription, A11yNodePropertyType.text),
    className:  new A11yNodePropertyInfo(a11yNodeProperyEnum.className, A11yNodePropertyType.text),
    hintText:  new A11yNodePropertyInfo(a11yNodeProperyEnum.hintText, A11yNodePropertyType.text),
    children:  new A11yNodePropertyInfo(a11yNodeProperyEnum.children, A11yNodePropertyType.arr),
    boundsInScreen:  new A11yNodePropertyInfo(a11yNodeProperyEnum.boundsInScreen, A11yNodePropertyType.rect),
    availableExtraData:  new A11yNodePropertyInfo(a11yNodeProperyEnum.availableExtraData, A11yNodePropertyType.arr)
}
export const a11yNodeRealizedKeys:a11yNodePropertyKeys[] = [
    a11yNodeProperyEnum.viewIdResourceName, a11yNodeProperyEnum.text, a11yNodeProperyEnum.contentDescription,
    a11yNodeProperyEnum.isClickable,
]
export interface IA11yNodeInfo{
    node:A11yNode
    pn:string
    an:string // 这个属性看似不存在，如果没有的话回头删掉
    activeActivity:string
}

export interface NodeAction{
    clazz:any,
    name:string,
    value:string,
    hasInput:boolean
}
export enum NodeActionKeys {
    click = "click",
    longClick = "longClick",
    setText = "setText",
    tryClick = "tryClick",
    globalClick = "globalClick"
}
export const nodeActions:Record<string, NodeAction> = {
    [NodeActionKeys.click]:{
        clazz:SFActionClick,
        name:NodeActionKeys.click,
        value:NodeActionKeys.click,
        hasInput:false,
    },
    [NodeActionKeys.longClick]: {
        clazz: SFActionLongClick,
        name: NodeActionKeys.longClick,
        value: NodeActionKeys.longClick,
        hasInput: false
    },
    [NodeActionKeys.setText]: {
        clazz: SFActionSetText,
        name: NodeActionKeys.setText,
        value: NodeActionKeys.setText,
        hasInput: true
    },
    [NodeActionKeys.tryClick]: {
        clazz: SFActionTryClick,
        name: NodeActionKeys.tryClick,
        value: NodeActionKeys.tryClick,
        hasInput: false
    },
    [NodeActionKeys.globalClick]: {
        clazz: SFActionGlobalClick,
        name: NodeActionKeys.globalClick,
        value: NodeActionKeys.globalClick,
        hasInput: false
    }
}