
export const libLoggerCode = `
console.paused = false;
let javaLibInitLogger = console.createSubLogger("lib9");
let projectStoreLogger = javaLibInitLogger.createSubLogger("store9");
let projectEventLogger = console.createSubLogger("event9");
let projectFailedLogger = console.createSubLogger("failed9");
let projectFlowLogger = console.createSubLogger("flow9");
projectFlowLogger.paused = false;
projectStoreLogger.paused = false;


`
export const libHeaderCode = `
;
const onLibReadyInitFuncs = [];
function onLibReady(f, info){
    console.log('reg onLiReady', info);
    onLibReadyInitFuncs.push({f, info});
}
;
`

export const libHeaderDeclare = `
declare function onLibReady(f, info)
`
export const libFooterCode = `
;
for(let i = 0; i < onLibReadyInitFuncs.length; i++){
    let data = onLibReadyInitFuncs[i];
    try{
        data.f();
    }catch(e){
        console.log("on lib ready init fun error", data.info, e);
    }
}
;
`