import {IActionJavaData} from "#/htmls/index/vo/project/base/IAction";
import {string} from "prop-types";

export class SFCond{
    type!:string
    next?:SFCond
}
export class SFCondId extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Id"
    value!:string
}
export class SFCondText extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Text"
    value!:string
}

export class SFCondMatchText extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.MatchText"
    value!:string
}

export class SFCondDesc extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Desc"
    value!:string
}

export class SFCondClickable extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Clickable"
    value!:boolean
}

export class ISFCondfType extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Type"
    value!:string
}

export class SFCondIds extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Ids"
    value!:string[]
}

export class SFCondDescs extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Descs"
    value!:string[]
}

export class SFCondTexts extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Texts"
    value!:string[]
}

export class SFCondRoot extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.SFRoot"
    pn!:string
    an?:string[]
    cn!:string
    // 此变量在设置了an时有效。当pn匹配而an不匹配时尝试点击返回键直至返回到首页为止。捎带的副作用就是必然会从首页开始执行下一步。
    // 在trigger中使用时可以先匹配pn，当pn匹配，an不匹配时判断这个变量，如果这个变量为true则执行后退，执行后等待下一个event，在event里再次判断执行。
    // true为尝试点击
    back:boolean = false

    getData(){
        return JSON.parse(JSON.stringify(this))
    }
    getCondRoot(){
        return {
            type:"com.fanfanlo.droidlib.auto.service.accessibility.krosxx.SFRootCond",
            pn:this.pn,
            an:this.an,
            cn:this.cn
        }
    }
}
export class SFCondTypes extends SFCond{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.SFTypes"
    value!:string[]
}
export interface IA11yNodeSFData {
    click:false
    postTest:"",
    longClick:false,
    scrollUp:false
    scrollDown:false
    scrollLeft:false
    scrollRight:false
}



export class SFAction{
    type!:string
    get data(){
        return {type:this.type}
    }
}

export class SFActionFocus extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Focus"
}
export class SFActionLearFocus extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.ClearFocus"
}
export class SFActionClick extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.Click"
}
export class SFActionTryClick extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.TryClick"
}
export class SFActionLongClick extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.LongClick"
}
export class SFActionGlobalClick extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.GlobalClick"
}
export class SFActionSetText extends SFAction{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.SetText"
    get data(){
        console.log("ssssfffffffffff set text", )
        return {type:this.type, value:this.value}
    }
    value!:string
}

export class SFWait{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.SFWait"
    time = 0
    interval = 0
    invisible = false
}
export class SFData{
    type = "com.fanfanlo.droidlib.auto.service.accessibility.krosxx.SFData"
    // type = "SFData"
    root?:SFCondRoot
    action?:SFAction
    wait?:SFWait
    get data(){
        return JSON.parse(JSON.stringify(this))
    }
}