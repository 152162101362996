import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {Button, f7, List, ListInput, ListItem, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import TMs from "#/remotes/TMs";
import {Member} from "#/libs/fanfanlo/remotes/Member";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {MainModel, mainModel} from "../../mc/main/MainModel"
import i18n from "i18next";
import {isEmail} from "../../../../libs/fanfanlo/utils/utils";
import {showSimpleToast} from "../../../../ui-components/toast/toast";
import {storage} from "../../../../common/storage/storage";
import {VisitModel, visitModel} from "../../../../mc/VisitModel";
import {mainModelBase} from "../../../../mc/MainModelBase";

const loginTypePw = "pw";
const loginTypeCaptcha ="captcha";
const pwMinLength = 8;
export class LoginByMailContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {emailOk:false, pwOk:false, email:"", captcha:"", loginType: loginTypeCaptcha, pw:"", captchaFetched:false}
    }

    onEmailUpdate =(e)=>{
        let v = e?.target?.value;
        let ok = isEmail(v)
        this.setState({emailOk: ok, email: v})
        console.log("eeeeeeee",v, ok,  e);
    }
    onCaptchaUpdate =(e)=>{
        let v = e?.target?.value;
        let bl = this.checkIsAvailableCaptcha(v);
        if(!bl){
            this.setState({captchaOk:false})
            return;
        }
        this.setState({captcha:v, captchaOk:true})
    }
    onCaptchaClick =()=>{
        if(!this.state.emailOk){
            return showSimpleToast(i18n.t("components.emailLogin.labels.email.invalidEmail"))
        }
        this.getCaptcha()
    }
    onPwUpdate = (e)=>{
        let v = e?.target?.value || "";
        let bl = this.checkPwIsAvailable(v);
        this.setState({pw:v, pwOk: bl})
    }
    checkPwIsAvailable =(pw)=>{
        return pw.length >= pwMinLength;
    }
    checkIsAvailableCaptcha =(s)=>{
        return s.length > 5;
    }
    getCaptcha =()=>{
        let email = this.state.email;
        let emailOk = isEmail(email);
        if(!emailOk){
            return showSimpleToast(i18n.t("components.emailLogin.labels.email.invalidEmail"))
        }
        let member = new Member(TMs.TUser, TMs.MUserCreateRegCode, {login_name:email, type:"email"}, (res)=>{
            console.log(`TMs.TUser, TMs.MUserCreateRegCode res is`, res)
            if(!res?.is_ok){
                console.log("不成功")
            }
        this.setState({captchaFetched:true})
            showSimpleToast(i18n.t("components.emailLogin.labels.captcha.captchaIsSent"))
        }, (member)=>{
            console.log(`TMs.TUser, TMs.MUserCreateRegCode net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`TMs.TUser, TMs.MUserCreateRegCode data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            console.log(`TMs.TUser, TMs.MUserCreateRegCode complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        }, null, true, true);
        mainModel.dispatchEvent(new DataEvent(MainModel.events.addWaitingMember, member.send));
    }
    login =()=>{

        if(this.state.loginType == loginTypePw){
            if(!this.checkPwIsAvailable(this.state.pw)){
                return showSimpleToast(i18n.t("components.emailLogin.labels.pw.invalid"))
            }
            VisitModel.createPw(this.state.pw, (pw)=>{
                this._login(pw)
            })
        }else{
            this._login("")
        }
    }
    _login =(pw)=>{
        let email = this.state.email;
        let emailOk = isEmail(email);
        if(!emailOk){
            return showSimpleToast(i18n.t("components.emailLogin.labels.email.invalidEmail"))
        }
        let data = {login_name:email, type:"email"}
        let memberType = ""
        if(this.state.loginType == loginTypePw){
            if(!this.checkPwIsAvailable(this.state.pw)){
                return showSimpleToast(i18n.t("components.emailLogin.labels.pw.invalid"))
            }
            data.pw = pw
            memberType = TMs.MUserLoginByPw;
        }
        if(this.state.loginType == loginTypeCaptcha){
            if(!this.checkIsAvailableCaptcha(this.state.captcha)){
                return showSimpleToast(i18n.t("components.emailLogin.labels.captcha.invalidCaptcha"))
            }
            data.code = this.state.captcha;
            memberType = TMs.MUserLoginByCode;
        }
        // let data = {login_name:email, code:captcha, type:"email"};
        // let member = new Member(TMs.TUser, TMs.MUserLoginByCode, data, (res)=>{
        let member = new Member(TMs.TUser, memberType, data, (res)=>{
            console.log(`TMs.TUser, TMs.MUserLoginByCode res is`, res)
            if(res && res.user_token){
                storage.setItem("user_token", res.user_token);
                visitModel.tryLogin();
                f7.views.main.router.back()
            }else{
                f7.dialog.alert(i18n.t("components.login.userOrPwError"))
            }
        }, (member)=>{
            console.log(`TMs.TUser, TMs.MUserLoginByCode net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`TMs.TUser, TMs.MUserLoginByCode data error is`, member.dataError, member.dataErrorInfo);
            f7.dialog.alert(i18n.t("components.login.userOrPwError"))
        }, (member) =>{
            console.log(`TMs.TUser, TMs.MUserLoginByCode complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        }, null, true, true);
        // mainModel.dispatchEvent(new Event2(MainModel.events.addWaitingMember, member.send));
        mainModelBase.addWaitingMember(member.send);
    }

    changeLoginType =()=>{
        let type = this.state.loginType == loginTypePw ? loginTypeCaptcha : loginTypePw;
        this.setState({loginType:type})
    }
    render() {
        let loginButtonClass = this.state.loginType == loginTypePw ?
            (this.state.pwOk ? "" : " invalid-element ") :
            (this.state.captchaOk && this.state.captchaFetched? "" : " invalid-element ")
        let captchaButtonClass = this.state.emailOk ? "" : " invalid-element ";
        // let loginButtonClass = this.state.captchaOk ? "" : "invalid-element";
        return (
            <>
                <form>
                    <ListInput label={i18n.t("components.emailLogin.labels.email.label")}
                               onChange={this.onEmailUpdate}
                               name="email" type="email" clearButton
                               placeholder={i18n.t("components.emailLogin.labels.email.placeholder")} />
                    {this.state.loginType == loginTypeCaptcha &&

                    <ListInput className={"list-item"} label={i18n.t("components.emailLogin.labels.captcha.label")}
                               onChange={this.onCaptchaUpdate}
                               name="captcha" type="text" clearButton
                               placeholder={i18n.t("components.emailLogin.labels.captcha.placeholder")}>

                        <div slot="content"><Button onClick={this.onCaptchaClick} className={captchaButtonClass}>
                            {i18n.t("components.emailLogin.labels.captcha.buttonName")}
                        </Button></div>
                    </ListInput>}

                    {this.state.loginType == loginTypePw &&
                    <ListInput label={i18n.t("components.emailLogin.labels.pw.label")}
                               onChange={this.onPwUpdate}
                               name="password" type="text" clearButton
                               placeholder={i18n.t("components.emailLogin.labels.pw.placeholder")} />}

                    <div className={loginButtonClass}><Button onClick={this.login}>{i18n.t("components.emailLogin.labels.login.buttonName")}</Button></div>
                    <div><Button onClick={this.changeLoginType}>{i18n.t(`components.emailLogin.labels.loginTypeButton.${this.state.loginType}`)}</Button></div>

                </form>
            </>
        )
    }
}

let pageCount = 0;

class LoginByMail extends ComponentBase {
    constructor(props) {
        super(props);
        this.pageId = `loginByMailRouter-` + (++pageCount);
        this.pageSelectId = "#" + this.pageId;
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    onPageInit = (e, page) => {
        if (!page) return;
        this.setState({pageParams: page.route.params})
    }

    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={i18n.t(`routes.loginByMail}`)} backLink="返回"/>

                <PageContent>
                    <LoginByMailContent/>
                </PageContent>
            </Page>
        )
    }
}

//<Link href={loginByMailRouter.path}>{loginByMailRouter.name}</Link>
export const loginByMailRouter = {
    id: "login-by-mail",
    p: "/login-by-mail/",
    path: "/login-by-mail/",
    component: LoginByMail,
    name: "授权操作"
}
