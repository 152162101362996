import React from 'react';
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import {openFloatPermission} from "../../../../droid/rhino/scripts/permissions/openFloatPermission";
import i18n from "i18next";
import {Trans} from "react-i18next";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {createLogger} from "#/libs/fanfanlo/log/Log";
import {Button} from "framework7-react";
import {showSimpleToast} from "#/ui-components/toast/toast";
import {Permission} from "#/android/components/Permission";
import {Manifest} from "#/android/components/Manifest";
import {createFloatPermission} from "#/android/components/floatPermission";
import {PermissionGroup} from "#/android/components/PermissionGroup";
import {createFloatA11yPermissionGroup} from "#/android/components/permissionGroupCreator";
import to from "await-to-js";
import {openPnAnInfoWindow } from "#/htmls/accessibility-pn-an-info/PnAnInfoWindow";

// import {PnAnResultData} from "#/htmls/accessibility-pn-an-info/PnAnInfoWindow";

function checkOrAsk(){
    testA11yFloatWindow();
    return;
    testA11yFloat();
    return;
    let floatPermission = createFloatPermission()
    // let bl = floatPermission.checkOrAsk();
    console.log("ccccccccccccccccccccc check or ask clicked")
    let group = new PermissionGroup([floatPermission]);
    group.addEventListener(PermissionGroup.eventComplete, (e)=>{
        console.log("ppppppppppppppppppppp ermission group is complete", floatPermission.isSuccess);
    });
    group.run()
    // return bl;
}

function testA11yFloat(){
    let group = createFloatA11yPermissionGroup()
    group.addEventListener(PermissionGroup.eventComplete, ()=>{
        console.log("aaaaaaaaaa11y float permision complete", group.isSuccess)
    });
    group.run();
}
async function testA11yFloatWindow(){
    let [err, res] = await to(openPnAnInfoWindow());
    console.log("testA11yFloatWindowtestA11yFloatWindowlllllllllll", err, res?.pn, res?.an)
}
function onClick(){
    openPermission();
}

function openPermission(){
    if(!window.android){
        return
    }
    openFloatPermission();
}
(()=>{

    if(!window.android){
        return
    }
    global["onOpenFloatPermissionSettingResult"] = function (bl){
        // ui.setState({hasPermission:bl})
    }

    global["onFloatPermissionSettingResult"] = function (bl){
        ui.setState({hasPermission:bl})
    }
    global["onCheckFloatPermissionHasPermissionResult"] = function (bl){
        console.log("fffffffffffffffffff onCheckFloatPermissionHasPermissionResult", bl)
        ui.setState({hasPermission:bl})
    }
})();

let bIndex = 0;
var ui;
@createLogger
export class CreateFloatPermissionCheckButton extends ComponentBase{
    constructor(props) {
        super(props);
        ui = this;
        this.index = bIndex++;
        // this.state = {hasPermission:undefined,btnText:props.btnText || "去设置"}
        this.state = {hasPermission:undefined,btnText:props.btnText || i18n.t("components.permissionCommon.gotoSetting")}
        // checkHasPermission();
    }
    checkPermission =()=>{
        let floatPermission = createFloatPermission()
        let bl = floatPermission.check();
        this.setState({hasPermission: bl})
    }
    checkPermission1 =()=>{
        let {error, errMsg, data} = AutoWebViewJs.instance.permissionHasFloat();
        let print = this._log.sub(false, "fn_checkPermission").print;
        if(error){
            showSimpleToast(errMsg)
            return;
        }
        this.setState({hasPermission: data})
    }
    onResume() {
        super.onResume();
        this.checkPermission()
    }
    addEvents() {
        super.addEvents();
        Permission.dispacher.addDomainEventListener(this, Manifest.Permission.SYSTEM_ALERT_WINDOW, this.onPermissionUpdated)
    }
    removeEvents() {
        super.removeEvents();
        Permission.dispacher.removeDomain(this);
    }

    onPermissionUpdated =()=>{
        this.checkPermission();
    }
    render(){
        let bl = this.state.hasPermission;
        var color = bl == undefined ? "#aaaaaa" : bl === true ? "#075218":"#ff0000";
        var text = bl == undefined? i18n.t("components.permissionCommon.checking") :
            bl == true ? i18n.t("components.permissionCommon.authorized"):
                i18n.t("components.permissionCommon.unauthorized");
        let print = this._log.sub(false, "fn_render").print;
        return <span>
            <span>
                <Trans>
                    components.floatPermissionCheck.name
                </Trans>
            </span>
            <span> </span>
            <span style={{color:color}}>{text}</span><span>
            <span> </span>
            <a id={"openFloatPermissionButton"} href={"#"} onClick={onClick} >{this.state.btnText}</a>
            {/*<Button onClick={checkOrAsk}>检测或申请权限</Button>*/}
        </span>
        </span>
    }
}