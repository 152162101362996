import React from 'react';
import {Block, Link, List, ListItem} from "framework7-react";
import {CreateDeleteActionButton} from "./del-action-button";
import {AndroidAppIcon} from "../../../../pages/components/AndroidAppIcon";
import {InstalledAppsModel, installedAppsModel} from "../../../../mc/installedapp/InstalledAppsModel";
import {ComponentBase} from "../../../../../../libs/fanfanlo/react/components/ComponentBase";
import {Trans} from "react-i18next";
import {logs} from "#/htmls/index/utils/log";
import {showSimpleToast} from "#/ui-components/toast/toast";
import to from "await-to-js";
import {openPnAnInfoWindow} from "#/htmls/accessibility-pn-an-info/PnAnInfoWindow";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import i18n from "i18next";

class UI extends ComponentBase{
    constructor(props) {
        super(props);
        this.state = {base64:installedAppsModel.getInstalledAppByPackageName(this.props.action.javaData.pn)?.iconBase64}
    }
    addEvents() {
        super.addEvents();
        installedAppsModel.addDomainEventListener(this, InstalledAppsModel.events.appListUpdated, this.onAppListUpdated);
        this.props.action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onJavaDataUpdated);
    }
    removeEvents() {
        super.removeEvents();
        installedAppsModel.removeDomain(this);
        this.props.action.removeDomain(this);
    }
    onJavaDataUpdated =()=>{
        this.updateAppInfo();
    }
    updateAppInfo=()=>{
        this.onAppListUpdated()
    }
    onAppListUpdated =(e)=>{
        this.setState({base64:installedAppsModel.getInstalledAppByPackageName(this.props.action.javaData.pn)?.iconBase64})
    }
    async onChangeAppClick (){
        let print = logs.scriptEditFlow.sub(false, "fn_UI-onChangeAppClick").print;
        print("action iseeee2", this.props)
        let [err, res] = await to(openPnAnInfoWindow());
        print(`err is ${err}, res is ${res?.pn} activityList is ${res?.an}`)
        if(res){
            showSimpleToast(`${res.pn}\n${res.an}`)
            // this.props.action.updateJavaData(res);
            this.props.action.updateJavaDataFromFloatTools(res);
        }else{
            showSimpleToast(`no package selected ${err}`)
        }
        const script = `com.fanfanlo.lib.intent.Funcs.openSelfByPackage()`
        AutoWebViewJs.instance.callScript(script);
    }
    onMaxBackTimesChange = (e)=>{
        try{
            let n = Number(e.target.value)
            this.props.action.updateMaxBackTimes(n)
        }catch (e){
            console.log("action a11y node wait set interval error", e)
        }
    }
    render(){
        return (
            <>
                <Block>
                    <div>
                        {this.state.base64 && <AndroidAppIcon base64={this.state.base64}/>}
                    </div>
                    <div className={"grid grid-gap  grid-cols-2"}>
                        <CreateDeleteActionButton action={this.props.action}/>
                        <Link onClick={this.onChangeAppClick.bind(this)} href={"#"}>
                            <Trans>
                                pageContents.actionUI.changeButton.label
                            </Trans>
                        </Link>
                    </div>

                    <div>
                        <label className=" item-content">
                            <input type={"number"} onChange={this.onMaxBackTimesChange} style={{width: "7rem"}}
                                   min={0}
                                   defaultValue={this.props.action.shellData.maxBackTimes}/>
                            <div className="item-inner">
                            <span>
                                {i18n.t("project.task.action.backToMain.maxBackTimes.name")}
                            </span>
                            </div>
                        </label>
                    </div>
                    <List>
                        <ListItem title={"pn"}/>
                        <ListItem title={this.props.action?.javaData?.pn}/>
                        <ListItem title={"activityList"}/>
                        <ListItem title={this.props.action?.javaData?.activityList?.join(",")}/>
                    </List>
                </Block>
            </>
        )
    }
}


export function CreateToMainActivityUIContent(action) {
    return <UI action={action}/>
}