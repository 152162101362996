
import {BlockFooter, Button, Checkbox, f7, Navbar, Page, PageContent} from "framework7-react";
import React from "react";
import Dom7 from "dom7";
import appJsBridge from "../../androidjsbridge/AppJsBridge";
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import {getQueryVariable, isMobile} from "../../../../libs/fanfanlo/utils/utils";
import {isDev} from "../../../../confs/env";
import TMs from "../../../../remotes/TMs";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";
import {showSimpleToast} from "../../../../ui-components/toast/toast";
import {visitModel, VisitModel} from "../../../../mc/VisitModel";
import {mainModelBase, MainModelBase} from "../../../../mc/MainModelBase";
import Member from "../../../../libs/fanfanlo/remotes/Member";
import {routeMap} from "../../routes";
import {storage} from "../../../../common/storage/storage";
import i18n from "i18next";
import {Trans} from "react-i18next";
import {websiteConf} from "../../../../confs/local.conf";
import {PlayLoginContent} from "./play-login";

const logTypeCode = "code";
const logTypePw = "pw";





export class LoginPageContent extends ComponentBase{
    constructor(props) {
        super(props);
        this.codeMember = null;
        this.loginMember = null;
        this.state = {mobile:isDev ? "1312112956" : "", pw:"", isLegalMobile:false, isFetchingCode:false, code:"", logType:logTypeCode};
    }

    addEvents = ()=> {
        visitModel.addEventListener(VisitModel.events.logChanged, this.onUserLoggedChanged);
    }
    onUserLoggedChanged =(event) =>{
        if(visitModel.isLogged){
            f7.view.main.router.back();
        }
    }
    _onMobileChange = (e)=>{
        let v = e.target.value;
        this["state"].mobile = v;
        this.setState({mobile: v, isLegalMobile:false})
        if(!v)return;
        if(v.length < 11)return;
        if(!isMobile(v)){
            // f7.dialog.alert('不正确的手机号，请重新填写。', ()=>{
            f7.dialog.alert(i18n.t("components.sign.incorrectMobileNumber"), ()=>{
                Dom7('#mobile').focus();
            });
            return;
        }
        this.setState({isLegalMobile:true});
    }
    _onCodeChange = (e)=>{
        let v = e.target.value;
        this.setState({code:v});
    }
    _onPwChange = (e)=>{
        let pw = e.target.value;
        this.setState({pw});
        console.log("on pw change", pw);
    }
    _onFetchCodeClick = ()=>{
        if(!this.state.isLegalMobile)return;
        this._fetchCode();
    }
    _fetchCreateLoginCode = ()=>{
        let member = new Member(TMs.TUser, TMs.MUserCreateRegCode, {}, (res)=>{
            console.log(`tms.TUser, tms.MUserCreateLoginCode res is`, res)
            if(!res || !res.code){
                console.log("_fetchCreateLoginCode no code", res);
                return;
            }
            appJsBridge.callOnCreateUserLoginCode(res.code)
        }, (member)=>{
            console.log(`tms.TUser, tms.MUserCreateLoginCode net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`tms.TUser, tms.MUserCreateLoginCode data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            this.loginMember = null;
        }, null, true, true);
        MainModelBase.instance.dispatchEvent(new DataEvent(MainModelBase.events.addWaitingMember, member.send));
    }
    _onLoginClick = ()=>{
        if(this.loginMember)return;
        const checked = Dom7("#readCheckBox").is(":checked");
        if(!checked){
            f7.dialog.alert('请勾选"我已同意并阅读了《软件许可及服务协议》和《隐私政策》"', '提示');
            return
        }
        this._checkLogin();
    }
    _checkLogin =()=>{
        if(!isMobile(this.state.mobile)){
            // f7.dialog.alert('不正确的手机号，请重新填写。', ()=>{
            f7.dialog.alert(i18n.t("components.sign.incorrectMobileNumber"), ()=>{
                Dom7('#mobile').focus();
            });
            return;
        }
        if(this.state.logType == logTypeCode){
            this._codeLogin();
        }else{
            this._pwLogin();
        }
    }
    _pwLogin = ()=>{
        if(this.state.pw.length < 8){
            // f7.dialog.alert('密码长度不够。', ()=>{
            f7.dialog.alert(i18n.t("components.sign.passwordLengthNotEnough"), ()=>{
                Dom7('#pw').focus();
            });
            return;
        }
        console.log("pw is ", this.state.pw)
        let pw = VisitModel.createPw(this.state.pw)
        let member = new Member(TMs.TVisitor, TMs.MVisitorLoginByPw, {mobile:this.state.mobile, pw}, (res)=>{
            console.log(`TMs.TVisitor, TMs.MVisitorLoginByPw res is`, JSON.stringify(res))
            if(res.msg){
                return showSimpleToast(res.msg)
            }
            visitModel.dispatchEvent(new DataEvent(VisitModel.events.fetchLogin));
            this._fetchCreateLoginCode();
        }, (member)=>{
            console.log(`TMs.TVisitor, TMs.MVisitorLoginByPw net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`TMs.TVisitor, TMs.MVisitorLoginByPw data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            console.log(`TMs.TVisitor, TMs.MVisitorLoginByPw complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        }, null, true, true);
        MainModelBase.instance.addWaitMemberSend(member.send)
    }
    _codeLogin =()=>{
        if(this.loginMember)return;
        if(!this.regCodeInfo){
            showSimpleToast("没有申请验证码")
            return;
        }
        let member = this.loginMember = new Member(TMs.TUser, TMs.MUserLoginByCode, {code:this.state.code, account_type:"web", mobile:this.regCodeInfo.mobile}, (res)=>{
            console.log(`tms.TVisitor, tms.MVisitorLoginByCode res is`, res)
            // visitModel.dispatchEvent(new Event2(VisitModel.events.fetchLogin));
            // this._fetchCreateLoginCode();
            if(res && res.user_token){
                storage.setItem("user_token", res.user_token);
                visitModel.tryLogin();
                f7.views.main.router.back()
            }
        }, (member)=>{
            console.log(`tms.TVisitor, tms.MVisitorLoginByCode net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`tms.TVisitor, tms.MVisitorLoginByCode data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            this.loginMember = null;
            // console.log(`tms.TVisitor, tms.MVisitorLoginByCode complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        }, null, true, true);
        // MainModelBase.instance.addWaitMemberSend(member.send)
        mainModelBase.addWaitingMember(member.send);
    }
    _checkLastRegCodeInfoIsInTime =()=>{
        let info = this.regCodeInfo;
        if(!info)return false;
        let d = new Date(info.endtime);
        let now = Date.now();
        let diff = d.getTime() - now;
        if(diff > 0){
            // showSimpleToast(`验证码已发送，请注意接收。 ${(diff/1000).toFixed(0)}秒后可以重新发送`);
            showSimpleToast(i18n.t("components.sign.captchaIsSentOrResent", {sec:(diff/1000).toFixed(0)}));
            return true
        }
        return false;
    }
    _fetchCode(){
        if(!this.state.isLegalMobile){
            // f7.dialog.alert('不正确的手机号，请重新填写。', ()=>{
            f7.dialog.alert(i18n.t("components.sign.incorrectMobileNumber"), ()=>{
                Dom7('#mobile').focus();
            });
            return;
        }
        if(this._checkLastRegCodeInfoIsInTime()){
            // showSimpleToast("验证码已发送，请注意接收。");
            return;
        }
        if(this.codeMember)return;
        let mobile = this.state.mobile;
        let member = this.codeMember = new Member(TMs.TUser, TMs.MUserCreateRegCode, {mobile}, (res)=>{
            console.log(`tms.TVisitor, tms.MVisitorVerificationCode res is`, res)
            if(res && res.reg_code_info && res.reg_code_info.endtime){
                let d = new Date(res.reg_code_info.endtime);
                this.regCodeInfo = res.reg_code_info;
                if(!this.regCodeInfo){
                    // showSimpleToast("获取验证码失败，请刷新后重试。")
                    showSimpleToast(i18n.t("components.sign.getCaptchaFailed"))
                    return;
                }
                this.regCodeInfo.mobile = mobile;
                // showSimpleToast("验证码已发送，请注意接收。")
                showSimpleToast(i18n.t("components.sign.captchaIsSent"))
            }else{
                // showSimpleToast(`验证码发送失败。 ${JSON.stringify(res)}`)
                showSimpleToast(`${i18n.t("components.sign.captchaSendFailed")} ${JSON.stringify(res)}`)
            }
        }, (member)=>{
            console.log(`tms.TVisitor, tms.MVisitorVerificationCode net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`tms.TVisitor, tms.MVisitorVerificationCode data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            // console.log(`tms.TVisitor, tms.MVisitorVerificationCode complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
            this.codeMember = null;
        }, null, true, true);
        // this.codeMember.send();
        // MainModelBase.instance.addWaitMemberSend(member.send)
        mainModelBase.addWaitingMember(member.send);
        this.setState({isFetchingCode:true});
    }
    _onLoginTypeChangeClick =()=>{
        this.setState({pw:'', code:'', logType:this.state.logType == logTypePw ? logTypeCode : logTypePw})
    }

    render = () => {

        const title = `${i18n.t("components.sign.signin.name")} ${i18n.t("components.sign.conjunction")} ${i18n.t("components.sign.signup.name")}`
        const mobileNumberPlaceHolder = i18n.t("components.sign.mobileNumberPlaceHolder")
        const captchaInputPlaceHoulder = i18n.t("components.sign.captchaInputPlaceHoulder")
        const change = i18n.t("components.sign.change")
        const signinByPw = i18n.t("components.sign.signinByPw")
        const signinByCaptcha = i18n.t("components.sign.signinByCaptcha")
        return
        // <Page  pageContent={false} className={"no-toolbar"}>
        //     <Navbar title={title}  backLink={"back"}/>
            <PageContent>
                <form className="list" id="my-form">
                    <ul>
                        <li>
                            <div className="item-content item-input">
                                <div className="item-inner">
                                    <div className="item-title item-label">
                                        <Trans>
                                            components.sign.mobileNumber
                                        </Trans>
                                    </div>
                                    <div className="item-input-wrap">
                                        <input id={"mobile"} maxLength={11} onChange={this._onMobileChange}
                                               value={this.state.mobile}
                                               type="number" name="mobile" placeholder={mobileNumberPlaceHolder}/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        {this.state.logType==logTypeCode &&

                        <li>
                            <div className="item-content item-input">
                                <div className="item-inner col">
                                    <div className="item-title item-label ">
                                        <Trans>
                                            components.sign.captcha
                                        </Trans>
                                    </div>
                                    <div className="item-input-wrap ">
                                        <input onChange={this._onCodeChange} maxLength={6} type="number"
                                               disabled={!this.state.isLegalMobile ? "disabled":""}
                                               name="code" placeholder={captchaInputPlaceHoulder}/>
                                    </div>
                                </div>
                                <div className={"item-media"}>
                                    <Button className={this.state.isLegalMobile ? "" :'color-gray'} onClick={this._onFetchCodeClick} >
                                        <Trans>
                                            components.sign.fetchCaptcha
                                        </Trans>
                                    </Button>
                                </div>
                            </div>
                        </li>
                        }
                        {this.state.logType == logTypePw &&

                        <li>
                            <div className="item-content item-input">
                                <div className="item-inner">
                                    <div className="item-title item-label">登录密码</div>
                                    <div className="item-input-wrap">
                                        <input id={"pw"} maxLength={11} onChange={this._onPwChange}
                                               value={this.state.pw}
                                               type="password" name="pw" placeholder="请输入您的登录密码"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        }
                        <li>
                            <div  className="item-content" style={{paddingTop:"20px"}}>
                                <span style={{lineHeight:"40px"}}>
                                    <label className="checkbox">
                                        <input id={"readCheckBox"} type="checkbox"/>
                                        <i className="icon-checkbox"></i>
                                    </label>
                                    <span>&nbsp;</span>
                                    {/*<Checkbox  id={"termsPrivacyRead"} name="checkbox-1"></Checkbox>*/}
                                    <span>  我已同意并阅读了</span>
                                    <a href={routeMap.TermsRouter.path}>《软件许可及服务协议》</a>和<a href={routeMap.Privacy2Router.path}>《隐私政策》</a>
                                </span>
                            </div>
                        </li>
                        <li>
                            <div style={{align:"center"}}>
                                <Button className={this.state.isLegalMobile ? "" :'color-gray'} onClick={this._onLoginClick} >
                                    <Trans>
                                        components.sign.signin.name
                                    </Trans>
                                </Button>
                            </div>
                        </li>
                    </ul>
                </form>
                <div style={{align:"center"}}>
                    <Button  onClick={this._onLoginTypeChangeClick} >{change} {this.state.logType==logTypeCode ? signinByPw:signinByCaptcha}</Button>
                </div>
            </PageContent>
        {/*</Page>*/}
    }
}

let pageIndex = 0;
export class LoginPage extends ComponentBase{
    constructor(props) {
        super(props);
        this.pageIndex = pageIndex++;
        console.log("pppppppppppplogin page create", this.pageIndex)
    }
    render(){
        const title = `${i18n.t("components.sign.signin.name")} ${i18n.t("components.sign.conjunction")} ${i18n.t("components.sign.signup.name")}`;
        const platform = websiteConf.platformConf.platform;
        console.log("platform is", platform);
        return(
            <Page  pageContent={false} className={"no-toolbar"}>
                <Navbar title={title}  backLink={"back"}/>

                {platform == "play" && <PlayLoginContent />}
                {platform == "cn" && <LoginPageContent />}
            </Page>

        )
    }
}

export const LoginRouter = {
    id:"login",
    path: "/login/",
    component: LoginPage,
    name: "login"
}
