import {MainRouter} from "./pages/main/Main";
import {f7} from "framework7-react";
import {MineRouter} from "./pages/me/Me";
import {RhinoScriptEdtorRouter} from "./pages/RhinoScriptEdtor/RhinoScriptEdtor";
import {TermsRouter} from "./pages/terms/Terms";
import {Privacy2Router} from "./pages/privacy/Privacy2";
import {UnsubscribeRouter} from "./pages/unsubscribe/Unsubscribe";
import {PrivacyTermsRouter} from "./pages/privacyTerms/PrivacyTerms";
import {LoginRouter} from "./pages/login/login";
import {actionListRouter} from "./pages/actionList/ActionList";
import {actionSelectOpenAppRouter} from "./pages/actionSelectOpenApp/ActionSelectOpenApp";
import {browserPerformanceRouter} from "#/htmls/index/pages/browserPerformance/BrowserPerformance";
import {
    dangerousPermissionA11yAllowPermissionRouter,
    dangerousPermissionA11yRouter
} from "#/htmls/index/pages/dangerousPermissionA11y/dangerous-permission-a11y";


const tabs = [
    MainRouter,
    MineRouter,
];

const routeMap = {
    MainRouter,
    MineRouter,
    RhinoScriptEdtorRouter,
    TermsRouter,
    Privacy2Router,
    UnsubscribeRouter,
    PrivacyTermsRouter,
    LoginRouter,
    actionListRouter,
    actionSelectOpenAppRouter,
    browserPerformanceRouter,
    dangerousPermissionA11yRouter,
    dangerousPermissionA11yAllowPermissionRouter,
}
const routes = [
];

for(let name in routeMap){
    routes.push(routeMap[name])
}
const hrefSegment = "#";
const href = document.location.href;
const hrefPath = href.slice(href.indexOf(hrefSegment) + hrefSegment.length);
let activeTab = tabs[0];
for(let i = 0; i < tabs.length; i ++){
    if(tabs[i].path == hrefPath){
        activeTab = tabs[i];
        break;
    }
}

let onActiviteTabChange;
function setOnActiveTabChangeFunction(f){
    onActiviteTabChange = f;
}
const tabInfo = {
    tabs:tabs,
    activite:activeTab.id,
    setActivitePage:function (page){
        if(this.activite == page.id)return;
        this.activite = page.id
        this.navigate(page)

        onActiviteTabChange({activiteTab:this.activite})
        // 改为app注入
        // const event = new Event2(MainModel.tabEvents.tabChanged, {activiteTab:this.activite});
        // mainModel.dispatchEvent(event)
    },
    navigate:function (parameters, options  ){
        // https://framework7.io/docs/view#method-router.navigate(url,%20options)
        // ignoreCache (boolean) - If set to true then it will ignore if such URL in cache and reload it using XHR again
        // options {ignoreCache:true}
        let res = f7.view.main.router.navigate(parameters, options  );
    }
}
export {routes, tabs, tabInfo, hrefSegment, routeMap, setOnActiveTabChangeFunction}