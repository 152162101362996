
import React from "react";
import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {IProject} from "#/htmls/index/vo/project/base/IProject";
import {Button} from "framework7-react";
import i18n from "i18next";
import {ActionMulti} from "#/htmls/index/vo/project/base/ActionMulti";
import {IActionAlias} from "#/htmls/index/vo/project/base/IAction";

class AddActionMultiProps {
    project!:IProject
}

class AddActionMultiState {

}

export class AddActionMultiButton extends ComponentBase<AddActionMultiProps, AddActionMultiState>{
    constructor(props:AddActionMultiProps) {
        super(props);
        this.state = new AddActionMultiState();
    }
    addEvents() {
        super.addEvents();
    }

    removeEvents() {
        super.removeEvents();
    }

    renderEmpty(){
        return(
            <div>empty</div>
        );
    }
    onClick=()=>{
        let action = new ActionMulti();
        action.setEmptyData();
        this.props.project.editingTask?.addAction(action as unknown as IActionAlias)

    }
    render(){
        if(!this.props.project)return this.renderEmpty();
        return(
            <>
                <Button onClick={this.onClick}>{i18n.t("pageContents.scriptEditor.action.addMultiAction.buttonName")}</Button>
            </>
        );
    }
}