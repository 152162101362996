
import {AccordionContent, Block, Link, List, ListItem, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import {ComponentBase} from "../../../../../../libs/fanfanlo/react/components/ComponentBase";
import {CreateDeleteActionButton} from "./del-action-button";
import {Trans} from "react-i18next";
import i18n from "i18next";


export class ActionUIListItem extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        let title = this.props.action.getUITitle();
        return (
            <ListItem  accordionItem className={"action-list-item"}>
                <div slot={"title"}>
                    {title}
                </div>

                <AccordionContent>
                    {this.props.action.createUI()}
                </AccordionContent>
            </ListItem>
        )
    }
}

export function CreateActionListUI(props){
    const actionList = props.actionList;
    if(!actionList){
        return <></>
    }
    return (

        <List accordionList style={{margin:"2px"}}>
            <ul>
                {actionList.map((actionData, index)=>{
                    return <ActionUIListItem action={actionData} key={index.toString()} />
                })}
            </ul>
        </List>
    )
}