import {DataEvent, Event2Any} from "#/libs/fanfanlo/events/DataEvent";
import _ from "lodash";
import {createActionByData} from "#/htmls/index/vo/project/base/ActionMap";
import {Log} from "#/libs/fanfanlo/log/Log";
import {ulid} from "ulid";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import {ITask, ITaskJavaData, ITaskShellData} from "#/htmls/index/vo/project/base/ITask";
import {ProjectBase} from "#/htmls/index/vo/project/base/ProjectBase";
import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {editorModel} from "#/htmls/index/mc/editor/EditorModel";
import {IAction, IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";
import {autobind, nonenumerable} from "core-decorators";
import {
    ActionSelectOpenAppSelectedEvent
} from "#/htmls/index/events/actionSelectOpenApp/ActionSelectOpenAppSelectedEvent";
import {OpenApp} from "#/htmls/index/vo/project/a11y/actions/OpenApp";
import {WebViewJs} from "#/android/WebViewJs";
import {androidDispatchers} from "#/android/androidDispatchers";
import {ActionA11yNode} from "#/htmls/index/vo/project/a11y/actions/ActionA11yNode";
import {ActionGroupListener} from "#/htmls/index/vo/project/base/ActionGroupListener";
import {createMyProjectsEventType, ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {IMissionData} from "#/htmls/index/vo/project/base/IMissionData";
import {mergeActionListPermissions} from "#/htmls/index/vo/project/base/mergeActionListPermissions";


let taskIndex = 0;
@autobind
export class TaskBase extends EventDispatcher implements ITask {
    static type = "com.fanfanlo.droidlib.auto.command.task.Task"
    static eventTypes = {
        taskUpdated:"taskUpdated",
        actionUpdated:"actionUpdated"
    }

    type = this.constructor.prototype.constructor.type
    tIndex = taskIndex++;
    javaData?: ITaskJavaData = this.returnOrCreateJavaData();
    shellData?: ITaskShellData = this.returnOrCreateShellData();
    actionList: IAction<IActionShellData, IActionJavaData>[] = [];
    @nonenumerable
    logger = Log.createCountedLogger(false, this.constructor.name);
    @nonenumerable
    project!: ProjectBase
    @nonenumerable
    editingAction?: IAction<IActionShellData, IActionJavaData>

    private intialized = false
    private WebViewJsDispatcherListenerIndex:any;
    private addActionListener = new ActionGroupListener(this);
    constructor() {
        super();
        this.tIndex = taskIndex++;
    }

    init = () => {
        if(this.intialized)return;
        this.addEvents()
        this.intialized = true;
    }

    addEvents(){
        this.addActionListener.startListen();
    }
    getEventType(type:ProjectEditEnum){
        let event = createMyProjectsEventType(type, this.project.javaData.id, this.javaData?.id)
        return event;
    }
    onA11yNodeSelected(s:any){
        console.log("onA11yNodeSelectedxxx", s)
        let tprint = this.logger.sub(false, "onA11yNodeSelected-try").print;
        try{
            let info = JSON.parse(s);
            let action = new ActionA11yNode();
            action.setNodeData(info)
            this.addAction(action as unknown as IAction<IActionShellData, any>);
        }catch (e){ tprint("onA11yNodeSelected-try error", e) }
        finally {}
    }
    protected removeEvents(){
        if(this.addActionListener){
            this.addActionListener.removeEvents();
        }
        editorModel.myProjects.removeDomain(this);
        WebViewJs.instance.shellRemoveListen(androidDispatchers.WebViewJsDispatcher.name, this.WebViewJsDispatcherListenerIndex)
        this.intialized = false;
    }
    protected onDelAction =(event:Event2Any)=>{

    }
    protected onAddAction=(event:Event2Any)=>{
        let action:IAction<IActionShellData, IActionJavaData>|undefined;
        if(event instanceof ActionSelectOpenAppSelectedEvent){
            let data:any = {packageName: event.data.packageName}
            action  = new OpenApp() as unknown as IAction<IActionShellData, IActionJavaData>;
            action.updateJavaData(data)
        }
        if(action){
            this.addAction(action)
        }
    }
    initAction = (action: IAction<IActionShellData, IActionJavaData>) => {
        let {print, error, warn, logger} = this.logger.sub(false, "fn_initAction");
        action.project = this.project;
        action.task = this;
        action.actionGroup = this;
        action.init();
        action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onActionUpdated);
    }
    onActionUpdated = (e: Event2Any) => {
        this.dispatchActionUpdate(e.target)
    }
    addAction(action: IAction<IActionShellData, IActionJavaData>, index?: number ){
        let print = this.logger.sub(false, "fn_addAction").print;
        let i = this.actionList.indexOf(action);
        if (i > -1) {
            this.actionList.splice(i, 1);
        }
        if (index === undefined || index === null) index = this.actionList.length;
        index = Math.max(0, Math.min(this.actionList.length, index));
        this.initAction(action)
        this.actionList.splice(index, 0, action);
        this.dispatchActionUpdate(action);
    }
    removeAction = (action: IAction<IActionShellData, IActionJavaData>) => {
        if (!action) return;
        action.removeDomain(this);
        let index = this.actionList.indexOf(action);
        this.actionList.splice(index, 1);
        this.dispatchUpdateEvent();
    }
    setEditAction = (action: IAction<IActionShellData, IActionJavaData>) => {
        // this.initAction(action)
        this.editingAction = action;
    }
    dispatchUpdateEvent = () => {
        this.dispatchEvent(new DataEvent(TaskBase.eventTypes.taskUpdated));
        // this.project.dispatchTaskUpdate(this);
    }
    dispatchActionUpdate = (action: IAction<IActionShellData, IActionJavaData>) => {
        // this.dispatchEvent(new Event2(TaskBase.eventTypes.taskUpdated, action));
        this.dispatchUpdateEvent()
    }

    get data() {
        let res = {javaData: _.merge({}, this.javaData), shellData: _.merge({}, this.shellData)}
        res.javaData.actionList = [];
        for (let i = 0; i < this.actionList.length; i++) {
            let a = this.actionList[i];
            let d = a.data;
            res.javaData.actionList.push(d)
        }
        return res;
    }

    set data(v){
        if(!v)return;
        this.javaData = this.returnOrCreateJavaData(v.javaData)
        this.shellData = this.returnOrCreateShellData(v.shellData)
        for (let i = 0; i < v.javaData.actionList.length; i++) {
            let a = createActionByData(this.project, this, v.javaData.actionList[i]) as unknown as IAction<IActionShellData, IActionJavaData>
            this.addAction(a);
        }
    }

    get scriptData() {
        let list = this.actionList;
        let actionList = [];
        for (let i = 0; i < list.length; i++) {
            actionList.push(list[i].getMissionData())
        }
        let o = {
            "type": this.javaData?.type,
            "actionList": actionList
        }
        return o;
    }

    getMissionData(){
        let list = this.actionList;
        let actionList = [];
        for (let i = 0; i < list.length; i++) {
            actionList.push(list[i].getMissionData())
        }
        let o = {
            "type": this.javaData?.type || this.type,
            "actionList": actionList
        }
        return o;
    }
    protected returnOrCreateJavaData(v?: ITaskJavaData): ITaskJavaData {
        return _.merge(this.createJavaData(), v)
    }
    protected createJavaData():ITaskJavaData{
        return {actionList: [], id: ulid(), type: this.type || this.constructor.prototype.constructor.type}
    }
    protected returnOrCreateShellData(v?: ITaskShellData): ITaskShellData {
        return _.merge(this.createShellData(), v)
    }
    protected createShellData():ITaskShellData{
        return {id: ulid()}
    }

    getPermissions(): string[] {
        return mergeActionListPermissions(this.actionList)
    }
}
