import {gateway} from "./env";
import {logs} from "#/htmls/index/utils/log";

// const zjGateway = "//192.168.177.180:14040/r/";
// const prodGateway = "//baobeidaola.com/r/"
// const testGateway = "//xiaochengxu.fanfanlo.com/r/"
const version = require('./version.json')
// console.log("version is ", version);
logs.globalInfo.print("version is", version);
const websiteConf = {
    // gateway:platformIsZjLocal ? zjGateway : platformIsProd ? prodGateway : testGateway
    gateway:gateway,
    // version:version
    platformConf:version
}
export {websiteConf}
