import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import React, {ChangeEvent} from "react";
import i18n from "i18next";
import {logSetting} from "#/libs/fanfanlo/log/logSetting";
import {AccordionContent, Block, Button, List, ListItem} from "framework7-react";
import {logs} from "#/htmls/index/utils/log";

class PrintLogModelUIProps {

}

class PrintLogModelUIState {

}

export class PrintLogModelUI extends ComponentBase<PrintLogModelUIProps, PrintLogModelUIState> {

    constructor(props: PrintLogModelUIProps) {
        super(props);
        this.state = new PrintLogModelUIState();
    }

    addEvents() {
        super.addEvents();
    }

    removeEvents() {
        super.removeEvents();
    }

    renderEmpty() {
        return (
            <div></div>
        )
    }

    onPrintChromeChange = (e: ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked;
        logSetting.printLogToConsole = checked;
    }
    onPrintLogcatChange = (e: ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked;
        logSetting.printLogToCatlog = checked;
    }
    onTestClick = () => {
        console.log("test print")
    }

    render() {
        if (!logSetting.isInApp) {
            return (<></>)
        }
        return (
            <List>
                <ListItem accordionItem>
                    <div slot={"title"}>
                        {i18n.t("console.accordion.title")}
                    </div>
                    <AccordionContent>
                        <div>
                            <label className="item-checkbox item-content">
                                <input type="checkbox" onChange={this.onPrintChromeChange}
                                       defaultChecked={logSetting.printLogToConsole}/>
                                <i className="icon icon-checkbox"></i>
                                <div className="item-inner">
                            <span>
                                {i18n.t("console.printToConsole.name")}
                            </span>
                                </div>
                            </label>

                            <Block>
                                {i18n.t("console.printToConsole.explain")}
                            </Block>
                        </div>
                        <div>
                            <label className="item-checkbox item-content">
                                <input type="checkbox" onChange={this.onPrintLogcatChange}
                                       defaultChecked={logSetting.printLogToCatlog}/>
                                <i className="icon icon-checkbox"></i>
                                <div className="item-inner">
                            <span>
                                {i18n.t("console.printToCatlog.name")}
                            </span>
                                </div>
                            </label>
                            <Block>
                                {i18n.t("console.printToCatlog.explain")}
                            </Block>
                        </div>
                        <div>
                            <Button onClick={this.onTestClick}>{i18n.t("console.buttonTest.name")}</Button>
                        </div>
                    </AccordionContent>
                </ListItem>
            </List>
        )
    }
}