import i18n, {Resource} from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json'
import zhCN from './locales/zh-CN.json'
import gpt from "./locales/zh-CN.gpt.json"
import * as _ from 'lodash'
import gCn from '#/i18n/locales/zh-CN.json'
import gEn from "#/i18n/locales/en.json"
const resources:Resource = {
    "en": {
        translation: _.merge({}, en, gEn)
    },
    "zh-CN": {
        translation: _.merge({}, zhCN, gCn)
    }
}
export let i18nData:any
i18n.on('languageChanged', function(lng) {
    console.log("languagechangeddddd", lng, resources[lng]);
    i18nData = resources[lng].translation;
})
i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "en",
    lng: "zh-CN",
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }
});

setTimeout(()=>{
    changeLanguage("en")
}, 1000 * 3);
setTimeout(()=>{
    changeLanguage("zh-CN")
}, 1000 * 3);

i18n.on('missingKey', function(lngs, namespace, key, res) {
    console.error("i18n mssing key, lngs=%s, namespace=%s, key=%s, res=%s", lngs, namespace, key, res)
})

i18n.on('loaded', function(loaded) {})

i18n.on('failedLoading', function(lng, ns, msg) {})

export default i18n;

// mergeJson(zhCN, en)
// mergeJson(zhCN, gpt)
function mergeJson(){
    const r = {}
    _.merge(r, ...arguments)
    console.log("json is", JSON.stringify(r))
}
export function changeLanguage(lng:string){
    i18n.changeLanguage(lng, ()=>{

    })
}
