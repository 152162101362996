import React, { useState, useEffect } from 'react';

import {
    f7,
    f7ready,
    App,
    Views,
    View,
    Toolbar, Page, PageContent,
} from 'framework7-react';

import { withTranslation } from "react-i18next";
import {routes, tabs, tabInfo, hrefSegment, setOnActiveTabChangeFunction} from './routes';
import {ComponentBase} from "../../libs/fanfanlo/react/components/ComponentBase";
import {MainModel, mainModel} from "./mc/main/MainModel";
import {Trans} from "react-i18next";
import * as i18n from "i18next";
import {logs} from "#/htmls/index/utils/log";
import {mainModelBase} from "#/mc/MainModelBase";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";


class Shell extends ComponentBase{
    constructor(props) {
        super(props);
        this.state = {f7IsReady:false}
        f7ready(()=>{
            mainModelBase.f7IsReady = true;
            console.log("f7 ready")
            this.setState({f7IsReady: true})
            f7.view.main.router.on("routeChanged", this.onPageUrlUpdated)
            f7.on('toolbarShow',()=>{
                this.refreshTabbar()
            });
        })
        setOnActiveTabChangeFunction(function (data){
            const event = new DataEvent(MainModel.tabEvents.tabChanged, data);
            mainModel.dispatchEvent(event)
        })
    }
    addEvents() {
        super.addEvents();
        mainModel.addDomainEventListener(this, MainModel.tabEvents.tabChanged, this.onTabChanged);
    }
    onTabChanged =(event)=>{
        this.setState({activiteTab:event.data.activiteTab})
    }
    removeEvents() {
        super.removeEvents();
        mainModel.removeDomain(this);
        console.log("aaaaaaaaaaaaaappp removeevents")
    }

    onPageUrlUpdated =(now, prev, router)=>{
        // console.log("oooooooooooooooooooopage url updated", "now is",  now?.url, "prev is" , prev?.url, router);
        // logs.router.print("fn onPageUrlUpdated", "now is", now?.url, "prev is", prev?.url, "history is", router?.history, "router is", router);
        this.refreshTabbar();
    }
    refreshTabbar =()=>{
        // this.showHideTabbar();
        setTimeout(()=>{
            this.showHideTabbar();
        }, 100 * 2);
        setTimeout(()=>{
            this.showHideTabbar();
        }, 1000 * 2);
    }
    showHideTabbar = ()=>{
        let path = f7?.views?.main?.router["url"];
        for(let i = 0; i < tabs.length; i++){
            let tab = tabs[i];
            if(tab.path != path)continue;
            if(tabInfo.activite == tab.id)return;
            tabInfo.setActivitePage(tab);
            return;
        }
        f7.toolbar.hide(".toolbar-bottom")
    }
    render(){
        const f7params = {
            initOnDeviceReady:true,
            name: 'droid', // App name
            theme: 'auto', // Automatic theme detection
            dialog:{
                buttonOk:i18n.t("f7.dialog.buttons.ok"),
                buttonCancel:i18n.t("f7.dialog.buttons.cancel"),
            },
            toolbar:{
                showOnPageScrollEnd:false,
                showOnPageScrollTop:false,
                hideOnPageScroll:false,
            },
            mdSwipeBack:true,
            iosSwipeBack:true,
        };
        const view = {
            // url:"/h/",
            routes: routes,
            allowDuplicateUrls:true,
            // stackPages:true,
            pushState: true,
            history:true,
            browserHistory:true,
            browserHistorySeparator:hrefSegment,
            browserHistoryStoreHistory:true,
            browserHistoryOnLoad:true,
            browserHistoryAnimateOnLoad:true,
            browserHistoryAnimate:true,
            mdSwipeBack:true,
            iosSwipeBack:true,
        }
        // f7ready(() => {
        //     f7.view.main.router.on("routeChanged", this.onPageUrlUpdated)
        //     f7.on('toolbarShow',()=>{
        //         this.onPageUrlUpdated()
        //     });
        // });

        // console.log("aaaaaaaaaaaaapp render", this.state.f7IsReady)
        // tabs.main.name
        return (

            <App {...f7params}>
                <Views>
                    <View {...view} main className="safe-areas" url="/" >
                        {!this.state.f7IsReady &&
                            <Page>
                            <PageContent></PageContent>
                        </Page>}
                        {this.state.f7IsReady &&

                            <Toolbar tabbar labels bottom>
                                {
                                    tabs.map((item, index)=>{
                                        return (<a id={item.id} key={index} href={`#tab-${index}`}
                                                   onClick={()=>{tabInfo.setActivitePage(item)}}
                                                   className={`tab-link ${item.id == tabInfo.activite ? 'tab-link-active' : ''}`}>
                                            <Trans>
                                                {`tabs.${item.id}.name`}
                                            </Trans>
                                        </a>);
                                    })
                                }
                            </Toolbar>
                        }
                    </View>
                </Views>
            </App>
        )
    }
}
// export default Shell;
export default withTranslation()(Shell);