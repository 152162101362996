import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {f7, List, ListItem, Navbar, Page, PageContent,} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import {actionDataConf} from "./data";
import i18n from "i18next";
import {EditorModel, editorModel} from "../../mc/editor/EditorModel";

class ParentItem extends ComponentBase{
    constructor(props) {
        super(props);
        this.state = {item:props.item}
    }

    render(){
        return(
            <>
                <ListItem style={{width:"100%"}} title={i18n.t(`${actionDataConf.treePrefix}${this.props.prefix}.name`)} groupTitle >
                </ListItem>
                    {this.state.item.children.map((item, index)=>{
                        let prefix = `${this.props.prefix ? this.props.prefix + "." : ""}children.${item.name}`
                        return createItem(item, index, prefix)
                    })}
            </>
        )
    }

}

class ChildItem extends ComponentBase{
    constructor(props) {
        super(props);
        this.state = {item:props.item}
    }

    doAction =()=>{
        let item = this.state.item;
        // let event = new ActionSelectedEvent(item)
        // let project = getProjectByRouterCaller();
        // if(project){
        //     project.dispatchEvent(event)
        // }
        // actionListModel.dispatchEvent(event);
        // if(event.handled){
        //     return;
        // }
        // console.log("qqqqqqqqqqqqqq2", event.handled)
        // return;
        let opts = {
            history:false,
            browserHistory: false
        }
        let link = item.link || item.router?.
        console.log(item.name, item.link, item.router);
        if(item.navigate){
            item.navigate()
        }else if(item.link){
            f7.views.main.router.navigate(item.link, opts);
        }else if(item.router){
            f7.views.main.router.navigate(item.router,opts);
        }

    }
    render(){
        return(
            <>
                <ListItem style={{paddingLeft:"1rem"}}
                          title={i18n.t(`${actionDataConf.treePrefix}${this.props.prefix}.name`)} link={"#"}
                          onClick={this.doAction}></ListItem>
            </>
        )
    }
}

function createItem(item, index, prefix){
    return item.children ? <ParentItem slot={"media"} prefix={prefix} item={item} key={`${index}`}/> : <ChildItem  prefix={prefix} item={item} key={`${index}`}/>
}
class ActionListContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {conf:actionDataConf}
    }

    render() {
        return (
            <>
                <List>
                    <ul>
                        {this.state.conf.tree.map((item, index)=>{

                            return createItem(item, index, item.name)
                        })}
                    </ul>
                </List>
            </>
        )
    }
}

let pageCount = 0;

class ActionList extends ComponentBase {
    constructor(props) {
        super(props);
        this.pageId = `actionListRouter-` + (++pageCount);
        this.pageSelectId = "#" + this.pageId;
        editorModel.itemEditType = EditorModel.itemEditType.add
    }

    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    onPageInit = (e, page) => {
        if (!page) return;
        this.setState({pageParams: page.route.params, pageQuery:page.route?.query})
    }

    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={i18n.t(`routes.${actionListRouter.id}`)} backLink="返回"/>
                <PageContent>
                    <ActionListContent/>
                </PageContent>
            </Page>
        )
    }
}

//<Link href={actionListRouter.path}>{actionListRouter.name}</Link>
export const actionListRouter = {
    browserHistory:false,
    history:false,
    id: "ActionList",
    p: "/ActionList/",
    path: "/ActionList/",
    component: ActionList,
    name: "ActionList"
}
