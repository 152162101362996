import {htmlOrigin} from "#/confs/env";
import {ulid} from "ulid";
import {createWindowConfCopy} from "#/android/win/WindowConf";
import {createFloatA11yPermissionGroup} from "#/android/components/permissionGroupCreator";
import {PermissionGroup} from "#/android/components/PermissionGroup";
import i18n from "i18next";
import {Window} from "#/android/win/Window";
import {WebViewJs} from "#/android/WebViewJs";
import {androidDispatchers} from "#/android/androidDispatchers";

export interface PnAnResultData {
    pn:string,
    an:string
}
function getHtmlPnAnInfoWindowConf(windowId:string, selectEventType:string){

    let conf = {
        htmlConf:{
            url:`${htmlOrigin}htmls/accessibility-pn-an-info/`,
            webViewJsParams:{
                javaToJsDataString:JSON.stringify({eventType:selectEventType})
            }
        },
        floatWindowConf:{
            floatConf:{
                positionSaveName:"a11y-pn-a-an-info-panel"
            }
        },
        windowId:windowId
    }
    return createWindowConfCopy(conf)
    // return _.merge({}, windowConf, conf)
}

export async function openPnAnInfoWindow():Promise<PnAnResultData>{

    return new Promise(function (resolve, reject){
        let isSuccess = false;
        let group = createFloatA11yPermissionGroup()
        group.addEventListener(PermissionGroup.eventComplete, ()=>{
            if(group.isSuccess){
                openWindow()
            }else{
                reject(i18n.t("scripts.pnAnOpen.error.noPermission"));
            }
        });
        group.run();

        function openWindow(){
            let windowId = ulid();
            let eventType = `openAppBackToMainActivitySelected-${windowId}`
            const eventBackType = `${eventType}back`;
            let listenIndex = WebViewJs.instance.shellListen(androidDispatchers.WebViewJsDispatcher.name, eventType, eventBackType, true, onSelectApp)
            let conf = getHtmlPnAnInfoWindowConf(windowId, eventType);
            let win = Window.create(conf);
            win.init().open().addEventListener(Window.eventTypeDismiss, onDismiss)
            function onSelectApp(s:string){
                let o = JSON.parse(s) as PnAnResultData
                isSuccess = true;
                resolve(o)
            }

            function onDismiss(){
                WebViewJs.instance.shellRemoveListen(androidDispatchers.WebViewJsDispatcher.name, listenIndex);
                if(!isSuccess){
                    reject(i18n.t("scripts.pnAnOpen.error.noPnSelected"))
                }
            }
        }
    })

}