import {ActionBase} from "../../base/ActionBase";
import {CreateOpenAppUIContent} from "./OpenAppUI";
import _ from "lodash";
import {IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";
import {autobind, override} from "core-decorators";
import React from "react";
import {Event2Any} from "#/libs/fanfanlo/events/DataEvent";

export interface IOpenAppJavaData extends IActionJavaData{
    packageName:string
}

export interface IOpenAppShellData extends IActionShellData{}
@autobind
export class OpenApp extends ActionBase<IOpenAppShellData, IOpenAppJavaData>{
    static type = "com.fanfanlo.droidlib.auto.command.action.ActionOpenApp"
    constructor() {
        super();
        this.type = OpenApp.type;

    }
    getMissionData() {
        let o = {
            "type": `${this.type}`,
            "schema": `${this.javaData?.packageName}`
        }
        return _.merge({}, o, super.getMissionData())
    }

    protected onEditUpdate(event: Event2Any) {
        let {print, error, warn, logger} = this.logger.sub(false, "fn_onEditUpdate");
        let data:any = {packageName:event.data.packageName}
        print("data is", JSON.stringify(data))
        print("java data before change is", JSON.stringify(this.javaData))
        this.updateJavaData(data)
        print("java data after change is", JSON.stringify(this.javaData))
    }

    get data() {
        let d = super.data;
        delete d.javaData.iconBase64;
        return d;
    }

    set data(v){
        super.data = v;
    }

    createUI(): React.JSX.Element {
        return CreateOpenAppUIContent(this);
    }
}