import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import React, {ChangeEvent} from "react";
import {ActionA11yNode} from "#/htmls/index/vo/project/a11y/actions/ActionA11yNode";
import i18n from "i18next";

class A11yNodeWaitProps {
    action!:ActionA11yNode
}

class A11yNodeWaitState {

}

export class A11yNodeWaitUI extends ComponentBase<A11yNodeWaitProps, A11yNodeWaitState> {
    constructor(props: A11yNodeWaitProps) {
        super(props);
        let state = new A11yNodeWaitState()
        this.state = state;
    }

    addEvents() {
        super.addEvents();
    }

    removeEvents() {
        super.removeEvents();
    }

    renderEmpty() {
        return (
            <div>empty</div>
        )
    }

    onTimeChange=(e:ChangeEvent<HTMLInputElement>)=>{
        try{
            let n = Number(e.target.value)
            this.props.action.updateWait({time:n})
        }catch (e){
            console.log("action a11y node wait set time error", e)
        }
    }

    onIntervalChange=(e:ChangeEvent<HTMLInputElement>)=>{
        try{
            let n = Number(e.target.value)
            this.props.action.updateWait({interval:n})
        }catch (e){
            console.log("action a11y node wait set interval error", e)
        }
    }
    onInvisibleChange=(e:ChangeEvent<HTMLInputElement>)=>{
        try{
            let n = e.target.checked
            this.props.action.updateWait({invisible:n})
        }catch (e){
            console.log("action a11y node wait set invisible error", e)
        }
    }
    render() {
        return (
            <>
                <div>
                    <div className={"padding-12"}>
                        {i18n.t("project.task.action.node.wait.name")}
                    </div>

                    <div>
                        <label className=" item-content">
                            <input type={"number"} onChange={this.onTimeChange} style={{width:"7rem"}}
                                   max={100000} min={0}
                                   defaultValue={this.props.action.shellData.wait.time}/>
                            <div className="item-inner">
                            <span>
                                {i18n.t("project.task.action.node.wait.time.name")}
                            </span>
                            </div>
                        </label>
                    </div>

                    <div>
                        <label className=" item-content">
                            <input type={"number"} onChange={this.onIntervalChange} style={{width:"7rem"}}
                                   max={100000} min={0}
                                   defaultValue={this.props.action.shellData.wait.interval}/>
                            <div className="item-inner">
                            <span>
                                {i18n.t("project.task.action.node.wait.interval.name")}
                            </span>
                            </div>
                        </label>
                    </div>

                    <div>
                        <label className="item-checkbox item-content">
                            <input type={"checkbox"} onChange={this.onInvisibleChange} style={{width: "7rem"}}
                                   defaultChecked={this.props.action.shellData.wait.invisible}/>
                            <i className="icon icon-checkbox"></i>
                            <div className="item-inner">
                            <span>
                                {i18n.t("project.task.action.node.wait.invisible.name")}
                            </span>
                            </div>
                        </label>
                    </div>

                </div>
            </>
        )
    }
}