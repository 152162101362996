import {
    IAction,
    IActionAlias,
    IActionJavaData,
    IActionShellData
} from "#/htmls/index/vo/project/base/IAction";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import {CreateActionMultiUI} from "#/htmls/index/vo/project/base/ActionMultiUI";
import React from "react";
import {autobind} from "core-decorators";
import {Event2Any} from "#/libs/fanfanlo/events/DataEvent";
import {ActionGroupListener} from "#/htmls/index/vo/project/base/ActionGroupListener";
import _ from "lodash";
import {createActionByData} from "#/htmls/index/vo/project/base/ActionMap";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {IActionGroup} from "#/htmls/index/vo/project/base/IActionGroup";
import {IMissionData} from "#/htmls/index/vo/project/base/IMissionData";
import i18n from "i18next";
import {mergeActionListPermissions} from "#/htmls/index/vo/project/base/mergeActionListPermissions";

export interface IActionMultiJavaData extends IActionJavaData{
    actionList:any[]
    complateCond:number[][];
}
export interface IActionMultiShellData extends IActionShellData{
    actionList:IAction<IActionShellData, IActionJavaData>[]
    complateCond:boolean[][];

}

@autobind
export class ActionMulti extends ActionBase<IActionMultiShellData, IActionMultiJavaData> implements IActionGroup{
    static type = "com.fanfanlo.droidlib.auto.command.action.ActionMulti";
    actionList: IAction<IActionShellData, IActionJavaData>[] = [];
    private addActionListener = new ActionGroupListener(this)
    constructor() {
        super();
    }

    protected addEvents() {
        super.addEvents();
        this.addActionListener.startListen();
    }

    protected removeEvents() {
        super.removeEvents();
        this.addActionListener.removeEvents();
    }

    createUI(): React.JSX.Element {
        return CreateActionMultiUI(this)
    }

    initChildAction(action:IActionAlias){
        action.actionGroup = this;
        action.task = this.task;
        action.project = this.project;
        action.init();
        action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onChildActionUpdated);
    }
    onChildActionUpdated (e: Event2Any) {
        console.log("onChildActionUpdated onChildActionUpdated", e);
        this.dispatchUpdateEvent()
    }

    getMissionData(): { type: any } {
        let list = this.actionList;
        let actionList = [];
        for (let i = 0; i < list.length; i++) {
            actionList.push(list[i].getMissionData())
        }
        let conds:number[][] = [];
        for (let i = 0; i < this.shellData.complateCond.length; i++) {
            let la = this.shellData.complateCond[i];
            let cond:number[] = []
            conds.push(cond);
            for (let j = 0; j < la.length; j++) {
                if(la[j]){
                    cond.push(j);
                }
            }
            if(cond.length == 0){
                alert(i18n.t("project.task.action.actionMulti.completeCondition.minimumSetOneInConditionGroup"))
            }
        }
        if(conds.length == 0 && actionList.length > 0){
            alert(i18n.t("project.task.action.actionMulti.completeCondition.minimumSetOneInConditionGroupList"))
        }
        let o = {
            "type": this.javaData?.type || this.type,
            "actionList": actionList,
            complateCond:conds
        }
        return o;
    }

    removeAction(action: IAction<IActionShellData, IActionJavaData>): void {

        if (!action) return;
        action.removeDomain(this);
        let index = this.actionList.indexOf(action);
        this.actionList.splice(index, 1);
        this.dispatchUpdateEvent();

    }
    addAction(action:IAction<IActionShellData, IActionJavaData>, index?: number ):void{

        let i = this.actionList.indexOf(action);
        if (i > -1) {
            this.actionList.splice(i, 1);
        }
        if (index === undefined || index === null) index = this.actionList.length;
        index = Math.max(0, Math.min(this.actionList.length, index));
        this.actionList.push(action);
        this.initChildAction(action);
        if(this.shellData.complateCond.length == 0){
            let complatedCond:boolean[] = new Array(this.actionList.length).fill(true) ;
            this.shellData.complateCond.push(complatedCond)
        }
        this.dispatchUpdateEvent()
    }

    protected returnOrCreateShellData(v?: IActionMultiShellData): IActionMultiShellData {
        let o = super.returnOrCreateShellData(v);
        let d = {actionList: [], complateCond:[]};
        return _.merge(d, o);
    }

    protected returnOrCreateJavaData(v?: IActionMultiJavaData): IActionMultiJavaData {
        let o = super.returnOrCreateJavaData(v);
        let d = {complateCond: [], id: "", actionList: [], type: ""}
        return _.merge(d, o);
    }


    get data() {
        let res = {javaData: _.merge({}, this.javaData), shellData: _.merge({}, this.shellData)}
        res.javaData.actionList = [];
        for (let i = 0; i < this.actionList.length; i++) {
            let a = this.actionList[i];
            let d = a.data;
            res.javaData.actionList.push(d)
        }
        return res;
    }

    set data(v){
        if(!v)return;
        this.javaData = this.returnOrCreateJavaData(v.javaData)
        this.shellData = this.returnOrCreateShellData(v.shellData)
        for (let i = 0; i < v.javaData.actionList.length; i++) {
            let a = createActionByData(this.project, this, v.javaData.actionList[i]) as unknown as IAction<IActionShellData, IActionJavaData>
            this.addAction(a);
        }
    }


    get scriptData() {
        let list = this.actionList;
        let actionList = [];
        for (let i = 0; i < list.length; i++) {
            actionList.push(list[i].getMissionData())
        }
        let o = {
            "type": this.javaData?.type,
            "actionList": actionList
        }
        return o;
    }
    getPermissions(): string[] | undefined {
        return  mergeActionListPermissions(this.actionList)
    }
}