import React from 'react';
import {WebViewJs} from "#/android/WebViewJs";
export function AndroidAppIcon(props){
    let base64 = props.base64;

    if(!WebViewJs.instance.isInApp){

        base64 = `/9j/4AAQSkZJRgABAQAAAQABAAD//gAXR2VuZXJhdGVkIGJ5IFNuaXBhc3Rl/9sAhAAKBwcIBwYKCAgICwoKCw4YEA4NDQ4dFRYRGCMfJSQiHyIhJis3LyYpNCkhIjBBMTQ5Oz4+PiUuRElDPEg3PT47AQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAAvAEYDAREAAhEBAxEB/8QBogAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoLEAACAQMDAgQDBQUEBAAAAX0BAgMABBEFEiExQQYTUWEHInEUMoGRoQgjQrHBFVLR8CQzYnKCCQoWFxgZGiUmJygpKjQ1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4eLj5OXm5+jp6vHy8/T19vf4+foBAAMBAQEBAQEBAQEAAAAAAAABAgMEBQYHCAkKCxEAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD2OmMwIPHPh241j+yItQLXvmtD5XkuPnGQRnGOx70AUbT4i2N3JGq6NrCiaKSWFjbqRKqfe24Yk+n40AEvxF0+3UNNo+uRKzBQXsGALE4A5PUmgDU1fxbougx2r6rcta/a0LRK0LsSBjOQoOCMigDP1D4g6ZZLFJBZX9/DJafbPNtYQVSLJBZtxBHI9KAHy+OrSJgP7G1uQMiuGjsGZSGUEcj60AaHh/xHa+I7eWe0t7uJIn2E3EPl5POceuCMH3oA1qAM2HUr+TxFcadJpMkdnFEHS+MgKyMcfKBj69+3vQByFp/x76V/2M9z/wChTUCM7Q7uSy0c3qOYrmz8OzyWRIBDN5jl2544Kx8e9Ay1Pdau9u1rq2pi/MV9pksbi3WLb5kmSML16CgDptS/5H3Rf+vK7/nFQBwMf/IHhRiywvoEaTuoyUiN1iRsd8Lk0AdPdarraeKZltdVWPTLbULO1FoLdG3pIiEnf1HU0AbHgf8A5AEn/X9df+j3oA6GgBA6FzGHXeBkrnkCgDzzRbLXL+9hVbO3XTbPXLm4M5m/eH55ARtx/tUAZPlXWo+EdKtrGwunkttOvnkmWI7CjxyKEB/iYtt+Uc8ZoEac1nq0diL3VbSK1Nxe6YkaRy7+EkAyeBg8jigZs+KJNUi8YaK+k2kN1cfZbobJpCi4zHk5weaAMOC2l0GYWOoWUt1Inh0wyQWyGUsWmYYAHUc0wG6Ho3iP+0FtJbFfJin0+a4uJZCrZjhj3BRj5uQRnPUUAdX4H/5AEn/X9df+j3pAdDQBmweH9Ot/ENxr0cTC+uYhFI+8kFRjt0/hH5UAaVAGd4e0ptE0K1015RM0CkFwMA5Ynp+NAGfq+havq2qQl9Vhj0uK4hnFuLfMhKENjfnuR6UAdDQBm/2S3/CUHWfOG02QtfLxznfuzmgCTV4NTntAmk3kNpcBwS80PmKV5yMZHtQBF4d0h9E0dLKW4FzL5kkskoTaGZ3LHA7DmgDToAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoAKACgAoA/9k=`
        base64 = `/9j/4AAQSkZJRgABAQAAAQABAAD//gAXR2VuZXJhdGVkIGJ5IFNuaXBhc3Rl/9sAhAAKBwcIBwYKCAgICwoKCw4YEA4NDQ4dFRYRGCMfJSQiHyIhJis3LyYpNCkhIjBBMTQ5Oz4+PiUuRElDPEg3PT47AQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAAkACcDAREAAhEBAxEB/8QBogAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoLEAACAQMDAgQDBQUEBAAAAX0BAgMABBEFEiExQQYTUWEHInEUMoGRoQgjQrHBFVLR8CQzYnKCCQoWFxgZGiUmJygpKjQ1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4eLj5OXm5+jp6vHy8/T19vf4+foBAAMBAQEBAQEBAQEAAAAAAAABAgMEBQYHCAkKCxEAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD1e91nS9NkWO/1K0tHYblWedUJHqATTGVv+Eq8Of8AQf0z/wADI/8AGgDSkmihhM0sqJEoyXZgFA9c0ANtby1vYfOtLmK4jJxvicOv5igCWgDNu9E0fXPKutQ0y1u38sbGmiDFVPOOfrQBxniXw1oV54l0jw5p+jWcLSN9rvZIolVlgX+HI5+Y8fhQIua7aQ+IfiPY6DqAZtOtbA3f2fcQkz79oyB1AH9aBkV/ptt4O8daHc6PELW11V2tbq3jOEY4G1gvQHJ/T3NAHfUARM8NjZl5ZFjhgjyzscBVA5J/AUAcl4Bik1SXUvFtygEmqTFbYHqkCcKPbJHP0FAGj4j8LPrF9aapp+ovpup2YKx3CoHDKeqsp6j/ABNAHJ3Ok31x8RtAtb3Wm1a9td1zcMsaxpbxrgqAg6Fj1J56UAem0AUta0qLXNJuNNmnmgiuF2u8BAfGeQCQRz06UAT2dpDYWUFnbpshgjWNF9ABgUAVta0iPW7H7JLd3dqu8OXtZfLc47Z9KAItD8NaV4djkXTrcq8xzLK7F5JD7seaANSgAoAKACgAoAKAP//Z`
    }
    return <img width={30} src={"data:image/gif;base64," + base64}
                style={{ borderRadius: '8px' }}
    />
}