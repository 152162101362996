import {IAction, IActionAlias, IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";
import {DataEvent, Event2Any} from "#/libs/fanfanlo/events/DataEvent";
import {
    ActionSelectOpenAppSelectedEvent
} from "#/htmls/index/events/actionSelectOpenApp/ActionSelectOpenAppSelectedEvent";
import {OpenApp} from "#/htmls/index/vo/project/a11y/actions/OpenApp";
import {editorModel} from "#/htmls/index/mc/editor/EditorModel";
import {autobind} from "core-decorators";
import {ActionA11yNode} from "#/htmls/index/vo/project/a11y/actions/ActionA11yNode";
import {Log} from "#/libs/fanfanlo/log/Log";
import {WebViewJs} from "#/android/WebViewJs";
import {androidDispatchers} from "#/android/androidDispatchers";
import {BackToMainSelectedEvent} from "#/htmls/index/events/action/add/BackToMainSelectedEvent";
import {ActionA11yToMainActivity} from "#/htmls/index/vo/project/a11y/actions/ActionA11yToMainActivity";
import {IActionGroup} from "#/htmls/index/vo/project/base/IActionGroup";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import {taskConst} from "#/htmls/index/vo/project/base/taskConst";

@autobind
export class ActionGroupListener {
    WebViewJsDispatcherListenerIndex = -1;
    logger = Log.createCountedLogger(false, this.constructor.name)
    constructor(private group:IActionGroup) {}
    startListen(){
        let addType = this.group.getEventType(ProjectEditEnum.Add);
        this.group.project?.myProjects.addDomainEventListener(this, addType, this.onAddAction);

        let addNodeType = taskConst.getA11yNodeActionEventType(addType , taskConst.actionType.node)
        // console.log("add type is", addNodeType, this.group.constructor.name)
        this.WebViewJsDispatcherListenerIndex = WebViewJs.instance.shellListen(androidDispatchers.WebViewJsDispatcher.name, addNodeType, addNodeType, false, this.onA11yNodeSelected);
    }
    removeEvents(){
        editorModel.myProjects.removeDomain(this);
        WebViewJs.instance.shellRemoveListen(androidDispatchers.WebViewJsDispatcher.name, this.WebViewJsDispatcherListenerIndex)
    }
    onAddAction(event:DataEvent<any>){
        let action:IAction<IActionShellData, IActionJavaData>|undefined;
        let data:any = event.data;
        switch (event.constructor){
            case ActionSelectOpenAppSelectedEvent:
                action  = new OpenApp() as unknown as IAction<IActionShellData, IActionJavaData>;
                break;
            case BackToMainSelectedEvent:
                action = new ActionA11yToMainActivity() as unknown as IActionAlias;
                break;
            default:
                console.log("lost")
        }
        if(action){
            action.updateJavaData(data);
            this.group.addAction(action)
        }
        return;
    }
    onA11yNodeSelected(s:any){
        let {print, error, warn, logger} = this.logger.sub(false, "fn_onA11yNodeSelected");
        console.log("oooooooooooooooooo a11y selected", this.group.constructor.name, s);
        try{
            let info = JSON.parse(s);
            let action = new ActionA11yNode();
            console.log("ooooooooo create action")
            action.setNodeData(info)
            console.log("aaaaaaaaaaaaaction set node data")
            this.group.addAction(action as unknown as IAction<IActionShellData, IActionJavaData>);
            console.log("aaaaaaaaaaaadded action")
            WebViewJs.instance.openSelfByPackage();
        }catch (e){ error("onA11yNodeSelected-try error", e) }
        finally {}
    }

    onUpdateAction(event:Event2Any){

    }

    onDelAction(event:Event2Any){

    }
}