import {TaskBase} from "./TaskBase";
import {DataEvent, Event2Any} from "#/libs/fanfanlo/events/DataEvent";
import _ from "lodash";
import {createTaskByData} from "#/htmls/index/vo/project/base/TaskMap";
import {Log} from "#/libs/fanfanlo/log/Log";
import {projectConst} from "#/htmls/index/vo/project/base/project-const";
import {OpenApp} from "#/htmls/index/vo/project/a11y/actions/OpenApp";
import {ActionA11yToMainActivity} from "#/htmls/index/vo/project/a11y/actions/ActionA11yToMainActivity";
import {ulid} from "ulid";
import {IProject, IProjectJavaData, IProjectShellData} from "#/htmls/index/vo/project/base/IProject";
import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {autobind, nonenumerable} from "core-decorators";
import {PnAnResultData} from "#/htmls/accessibility-pn-an-info/PnAnInfoWindow";
import {IActionAlias} from "#/htmls/index/vo/project/base/IAction";
import {IEventDispatcher} from "#/libs/fanfanlo/events/IEventDispatcher";
import {ITask} from "#/htmls/index/vo/project/base/ITask";
import {mergeActionListPermissions} from "#/htmls/index/vo/project/base/mergeActionListPermissions";

let projectIndex = 0;
function watchLog (old:any, now:any){
    console.log(`old is`, old, `now is`, now, `this is `)
}
@autobind
export class ProjectBase extends EventDispatcher implements IProject {
    static type = "com.fanfanlo.droidlib.auto.command.project.Project"
    static eventTypes = {
        projectUpdated:"projectUpdated",
        actionUpdated:"actionUpdated",
        taskUpdated:"taskUpdated",
    }
    myProjects!:IEventDispatcher;
    logger = Log.createCountedLogger(false, this.constructor.name);
    pIndex = projectIndex ++;
    javaData: IProjectJavaData = this.returnOrCreateJavaData()
    shellData: IProjectShellData = this.returnOrCreateShellData();
    @nonenumerable
    private type = this.constructor.prototype.constructor.type;
    taskList: ITask[] = [];
    changeOpenAppAction?:IActionAlias;
    changeA11yToMainActivityAction?:ActionA11yToMainActivity
    // private _editingTask?:TaskBase
    // get editingTask(){return this._editingTask}
    // set editingTask(v:TaskBase|undefined) {
    //     this._editingTask = v;
    //     console.trace("ffffffffffffffffffffff", this.javaData.id, v);
    // }
    editingTask?: ITask

    constructor() {
        super();

        // Object.defineProperty(this, "editingTask", { writable: true })
        this._addEvents();
    }
    _addEvents=()=>{
        this.addEventListener(projectConst.action.openAppSelected, this._onOpenAppSelected);
        this.addEventListener(projectConst.action.backToMainSelected, this._onBackToMainSelected);
    }
    _onBackToMainSelected =(e:DataEvent<PnAnResultData>)=>{
        let action = this.changeA11yToMainActivityAction || new ActionA11yToMainActivity();
        // action.updateJavaData(e.data);
        action.updateJavaDataFromFloatTools(e.data);
        if(this.changeA11yToMainActivityAction){
            // 这个if条件应该是用不上了，
            this.changeA11yToMainActivityAction = undefined;
            action.dispatchUpdateEvent()
        }else{
            this.editingTask?.addAction(action as unknown as IActionAlias)
        }
    }
    _onOpenAppSelected =(e:Event2Any)=>{
        let print = this.logger.sub(false, "fn__onOpenAppSelected").print;
        let action = this.changeOpenAppAction || new OpenApp();
        // action.javaData = e.data;
        action.updateJavaData(e.data);
        print("action is", action, "event is", e);
        if(this.changeOpenAppAction){
            this.changeOpenAppAction = undefined;
            // action.dispatchUpdateEvent()
        }else{
            this.editingTask?.addAction(action as IActionAlias);
        }
    }
    setChangeOpenAppAction = (action: IActionAlias) => {
        this.changeOpenAppAction = action;
    }
    setChangeA11yToMainActivityAction = (action: ActionA11yToMainActivity) => {
        this.changeA11yToMainActivityAction = action;
    }

    static create(myProjects:IEventDispatcher){
        let p = new ProjectBase();
        p.myProjects = myProjects
        let task = new TaskBase();
        task.project = p;
        p.editingTask = task;
        p.addTask(task);
        return p
    }

    init = () => {

    }

    get id() {
        return this.javaData.id;
    }

    get name() {
        return this.javaData.name;
    }

    initTask =(task:ITask)=>{
        task.project = this;
        task.addDomainEventListener(this, TaskBase.eventTypes.taskUpdated, this.onTaskUpdated)
        task.init();
    }
    addTask = (task: ITask, index: number | undefined = undefined) => {
        let i = this.taskList.indexOf(task);
        if (i > -1) this.taskList.splice(i, 1);
        if (index === undefined || index === null) index = this.taskList.length
        index = Math.max(0, Math.min(this.taskList.length, index));
        this.taskList.splice(index, 0, task);
        this.initTask(task);
        this.dispatchProjectUpdate();
    }
    onTaskUpdated = (e: Event2Any) => {
        this.dispatchProjectUpdate()
    }
    dispatchActionUpdate =(action:IActionAlias)=>{
        this.dispatchEvent(new DataEvent(ProjectBase.eventTypes.actionUpdated, action))
        this.dispatchProjectUpdate();
    }
    dispatchTaskUpdate =(task:TaskBase)=>{
        this.dispatchEvent(new DataEvent(ProjectBase.eventTypes.taskUpdated, task));
        this.dispatchProjectUpdate();
    }
    dispatchProjectUpdate = () => {
        this.dispatchEvent(new DataEvent(ProjectBase.eventTypes.projectUpdated))
    }

    get data() {
        let res = {
            javaData: _.merge({}, this.javaData),
            shellData: _.merge({}, this.shellData)
        }
        res.javaData.taskList = this.taskList.map(task => task.data);
        return res;
    }

    set data(v){
        this.javaData = v.javaData;
        this.shellData = v.shellData;
        for (let i = 0; i < v.javaData.taskList.length; i++) {
            let t = createTaskByData(this, v.javaData.taskList[i]);
            this.addTask(t);
        }
    }

    get projectScript() {
        // let taskList = this.taskList.map((task) => task.scriptData);
        let taskList = this.taskList.map((task) => task.getMissionData());
        let missionJson = {
            "type": this.javaData.type,
            "taskList": taskList
        }
        let script = `
let missionString = '${JSON.stringify(missionJson)}'
let project = com.fanfanlo.droidlib.auto.command.project.ProjectUtils.createByJsonString(missionString)
project.a11yModel = a11yModel;
project.start();
`

        let injects = [["dispatcher"], ["quicker"], ["a11yModel"]]
        let conf = {script, injects}
        let s = JSON.stringify(conf)
        return s;
    }

    static getProjectListData =(list:ProjectBase[])=>{
        return list.map((proj)=>{
            return proj.data;
        })
    }

    get actionListCode() {
        let code = "";
        let list = this.editingTask?.actionList;
        if (!list) return "";
        for (let i = 0; i < list.length; i++) {
            code += `\n${list[i].getMissionCode()}`
        }
        return code ? `[${code}]` : '';
    }

    protected returnOrCreateJavaData(v?: IProjectJavaData): IProjectJavaData {
        return _.merge(this.createJavaData(), v);
    }

    protected createJavaData():IProjectJavaData{
        return {type: this.constructor.prototype.constructor.type, taskList: [], id: ulid()}
    }
    protected returnOrCreateShellData(v?: IProjectShellData): IProjectShellData {
        return _.merge(this.createShellData(), v)
    }
    protected createShellData():IProjectShellData{
        return {id: ulid()}
    }

    getPermissions(){
        return  mergeActionListPermissions(this.taskList)
    }
}

