import {MainControllerBase} from "../../../../mc/MainControllerBase";
import {visitModel} from "../../../../mc/VisitModel";
import {editorController} from "../editor/EditorController";

export class MainController extends MainControllerBase{
    async init(){
        // userController.init();
        this.start();
        visitModel.start();
        editorController.init();
    }
    start =()=>{
        super.start();
        this.addEvents();
    }
    addEvents =()=>{

    }

    _startAll = ()=>{
        super._startAll();
        // userController.start();
    }
    // _onBrowserFingerSavedToServer = (event:Event2) => {
    //     mainModel.saveBrowderFinger();
    // }
    // _onAddWaitingMember = (event:Event2)=>{
    //     if(_.isFunction(event.data) === false){
    //         console.error('_onAddWaitingMember is not a function', event);
    //         return;
    //     }
    //     mainModel.waitingMembers.push(event.data);
    //     this._runWaitingMembers();
    // }
    // _onTrackingUuidChanged = (event:Event2) =>{
    //     // console.log("main ctrollder on tracking uuid changed")
    //     this._startAll();
    //     this._runWaitingMembers();
    // }
    // _runWaitingMembers(){
    //     if(!trackingModel.trackingUuid)return;
    //     if(mainModel.waitingMembers.length <= 0)return;
    //     let runMemberFunc = mainModel.waitingMembers.shift();
    //     runMemberFunc();
    //     setTimeout(()=>{
    //         this._runWaitingMembers();
    //     }, 50);
    // }
}

export const mainController = new MainController()