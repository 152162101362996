import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {Link, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import {autobind} from "core-decorators";
import i18n from "i18next";
function TotalSize(){
    let size = 0;
    var resource = window.performance.getEntriesByType('resource');
    let temp = {"name":"http://192.168.177.180:18001/@fs/C:/work/android-droid/html/droid-website/node_modules/.vite/deps/react.js?v=fd2a8c0c","entryType":"resource","startTime":1426.699999988079,"duration":58.400000005960464,"initiatorType":"other","deliveryType":"cache","nextHopProtocol":"","renderBlockingStatus":"non-blocking","workerStart":0,"redirectStart":0,"redirectEnd":0,"fetchStart":1426.699999988079,"domainLookupStart":1426.699999988079,"domainLookupEnd":1426.699999988079,"connectStart":1426.699999988079,"secureConnectionStart":0,"connectEnd":1426.699999988079,"requestStart":1430.3999999761581,"responseStart":1481.5999999940395,"firstInterimResponseStart":1481.5999999940395,"responseEnd":1485.0999999940395,"transferSize":0,"encodedBodySize":309,"decodedBodySize":309,"responseStatus":200,"serverTiming":[]}
    // Note: Performance 对象暴露给了 Window 和 Worker. 同时该对象扩展了几个其他对象的属性，包括 PerformanceMark, PerformanceMeasure, PerformanceFrameTiming, PerformanceNavigationTiming 以及 PerformanceResourceTiming.
    let totalSize = resource.reduce((num, entry)=>{
        if(entry instanceof  PerformanceMark){

        }else if(entry instanceof PerformanceResourceTiming){

        }else if(entry instanceof PerformanceMeasure){

        }else if(entry instanceof PerformanceNavigationTiming){

        }else {

        }
        switch (entry.entryType){
            case "frame":
                case "navigation":
                const nav = entry as PerformanceNavigationTiming
                break;
            case "resource":
                const resource = entry as PerformanceResourceTiming;
                break;
            case "mark":
                const mark = entry as PerformanceMark;
                break;
            case "measure":
                const measure = entry as PerformanceMeasure;
                break;
            case "paint":
                const paint = entry as PerformancePaintTiming;
                break;
            default :
                console.log("unknow performance", entry);
        }
        if(!("decodedBodySize" in entry))return num;
        return num + (entry as any)["decodedBodySize"];
    },0);
    size = Number((totalSize / 1024).toFixed(0));
    return (
        <div>
            <span>
                <span>total size:</span>
                <span>{size}KB</span>
            </span>
        </div>
    );
}
class BrowserPerformanceContentProps {

}

class BrowserPerformanceContentState {

}

class BrowserPerformanceContent extends ComponentBase<BrowserPerformanceContentProps, BrowserPerformanceContentState> {
    constructor(props: BrowserPerformanceContentProps) {
        super(props);
    }

    render() {
        return (
            <>
                <TotalSize />
            </>
        )
    }
}

let pageCount = 0;

class BrowserPerformanceProps {

}

class BrowserPerformanceState {

}

class BrowserPerformance extends ComponentBase<BrowserPerformanceProps, BrowserPerformanceState> {
    pageId = `browserPerformanceRouter-` + (++pageCount);
    pageSelectId = "#" + this.pageId;

    constructor(props: BrowserPerformanceProps) {
        super(props);
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    @autobind
    onPageInit(e: Event) {
        const page = arguments[1];
        if (!page) return;
        this.setState({pageParams: page.route.params})
    }

    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={browserPerformanceRouter.name} backLink="返回"/>

                <PageContent>
                    <BrowserPerformanceContent/>
                </PageContent>
            </Page>
        )
    }
}

export function BrowserPerformanceRouterLink(){
    return (<Link href={browserPerformanceRouter.path}>
        {browserPerformanceRouter.name}
    </Link>)
}
//<Link href={browserPerformanceRouter.path}>{browserPerformanceRouter.name}</Link>
export const browserPerformanceRouter = {
    id: "BrowserPerformance",
    p: "/BrowserPerformance/",
    path: "/BrowserPerformance/",
    component: BrowserPerformance,
    name: i18n.t(`routes.browserPerformance`)
}
