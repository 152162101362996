import {ProjectBase} from "#/htmls/index/vo/project/base/ProjectBase";

var projectMap = {
    // [ProjectBase.type]:ProjectBase
}

export function initProjectMap(){
    projectMap[ProjectBase.type]=ProjectBase
}
export {projectMap}

export function createProjectBase(myProjects){
    return ProjectBase.create(myProjects);
}