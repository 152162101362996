import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {createA11yPermission} from "#/android/components/A11yPermission";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import React from "react";
import {Button} from "framework7-react";
import {documentEventTypeDocumentIsShow} from "#/libs/fanfanlo/utils/documentUtils";
import {checkPermission} from "#/droid/java_js/fanfanlo/javajs/v1/permission/permission.java";
import {WebViewJs} from "#/android/WebViewJs";
import {routeMap} from "#/htmls/index/routes";
import {checkOpenA11yPermissionWithAsk} from "#/htmls/index/androidjsbridge/a11yPermission";
import {
    dangerousPermissionA11yAllowPermissionRouter
} from "#/htmls/index/pages/dangerousPermissionA11y/dangerous-permission-a11y";

class A11yPermissionCheckButtonProps {

}

class A11yPermissionCheckButtonState {
    hasPermission = false
    hasService = false
    isAlive = false
    pingTime = 0
}

export class A11yPermissionCheckButtonUI extends ComponentBase<A11yPermissionCheckButtonProps, A11yPermissionCheckButtonState>{
    constructor(props:A11yPermissionCheckButtonProps) {
        super(props);
        const state = new A11yPermissionCheckButtonState();
        this.state = state;
    }
    addEvents() {
        super.addEvents();
        document.addEventListener(documentEventTypeDocumentIsShow, this.checkPermission);
    }
    onResume() {
        super.onResume();
        this.checkPermission();
    }
    checkPermission=()=>{
        // let p = createA11yPermission()
        // let hasPermission = !!p.check()
        // this.setState({hasPermission});
        let {err, data, errMsg} = AutoWebViewJs.instance.onCallGetA11yServiceLiveInfo();
        // console.log("eeeeeeeeeeeeeeeeeeee", err, data, errMsg)
        if(err)return;
        if(!data)return;
        // let {"hasPermission":false,"hasService":false,"isAlive":false,"pingTime":0}
        // let {hasPermission, hasService, isAlive, pingTime} = data;
        this.setState(data)

    }
    removeEvents() {
        super.removeEvents();
        document.removeEventListener(documentEventTypeDocumentIsShow, this.checkPermission);
    }
    onSetClick =()=>{
        if(checkOpenA11yPermissionWithAsk()){
            AutoWebViewJs.instance.onCallOpenA11yPermission();
        }
        // AutoWebViewJs.instance.onCallOpenA11yPermission();
    }
    render(){
        let {hasPermission, isAlive, hasService} = this.state;
        let crashed = hasPermission ? isAlive ? false : true : false;
        let stateText = crashed ? "已崩溃" : "正常";
        let stateColor = crashed ? "#FF0000" : "#075218";
        let permissionColor = hasPermission ? "#075218" : "#FF0000";
        let serviceColor = crashed ? "#FF0000" : hasPermission ? "#075218" : "#000000";
        let text = ""
        let color = ""
        if(!WebViewJs.instance.isInApp){
            text = "非安卓环境"
            color = "#0"
        }else if(hasPermission){
            if(isAlive){
                text = "已授权"
                color = "#075218"
            }else{
                text = "已崩溃"
                color = "#FF0000"
            }
        }else{
            text = "未授权"
            color = "#000000"
        }
        return(
            <span>
                <span>
                    <span>辅助功能:
                        <span> </span>
                        <span style={{color: color}}>{text}</span>
                    </span>
                    <span> </span>
                    {/*<a onClick={this.onSetClick}>设置</a>*/}
                    <span> </span>
                    {routeMap.dangerousPermissionA11yAllowPermissionRouter.linkButton()}
                </span>
            </span>
        );
    }
}