import {OpenApp} from "#/htmls/index/vo/project/a11y/actions/OpenApp";
import {ActionA11yToMainActivity} from "#/htmls/index/vo/project/a11y/actions/ActionA11yToMainActivity";
import {ActionA11yNode} from "#/htmls/index/vo/project/a11y/actions/ActionA11yNode";
import {ActionMulti} from "#/htmls/index/vo/project/base/ActionMulti";

export const actionMap = {
    [OpenApp.type]:OpenApp,
    [ActionA11yToMainActivity.type]:ActionA11yToMainActivity,
    [ActionA11yNode.type]:ActionA11yNode,
    [ActionMulti.type]:ActionMulti,
}


export function createActionByData(project, task, data){
    // let print = logs.myProjectsInitInfo.sub(false, "fn_createActionByData").print;
    // console.log("action map type is", data.javaData.type)
    let action = new actionMap[data.javaData.type]();
    action.project = project;
    action.task = task
    // action.setData2(data);
    action.data = data;
    // print("action is", action, "data is", data);
    return action
}