import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {f7, ListItem} from "framework7-react";
import React from 'react';
import {showSimpleToast} from "../../../../ui-components/toast/toast";
import {editorModel} from "../../mc/editor/EditorModel";
import {addNewRecentApp} from "./common";
import {
    ActionSelectOpenAppSelectedEvent
} from "#/htmls/index/events/actionSelectOpenApp/ActionSelectOpenAppSelectedEvent";

function tryBack(url){
    let now = f7.views.main.router.currentRoute.url;
    if(url == now){
        return
    }
    let router =f7.views.main.router;
    router.once("routeChanged", ()=>{
        tryBack(url);
    })
    f7.views.main.router.back(url);
}
class Render2 extends ComponentBase{
    constructor(props) {
        super(props);
        this.packageId = "packagename_" + props.data.index;
    }
    onCopyPackageNameClick =()=>{
        showSimpleToast(`package name copied ${this.props.data.packageName}`);
        addNewRecentApp(this.props.data);
        let route = f7.views.main.router.currentRoute;
        let query = route?.query;
        let caller = query?.caller;
        let event = query?.event;
        editorModel.myProjects.dispatchEvent(new ActionSelectOpenAppSelectedEvent(event, this.props.data))
        tryBack(caller)
    }
    render(){
        let data = this.props.data;
        return (
            <ListItem title={data.label} data-clipboard-text={data.packageName} id={this.packageId} onClick={this.onCopyPackageNameClick} >
                <img width={30} src={"data:image/gif;base64," + data.iconBase64}
                     slot="media"
                     style={{ borderRadius: '8px' }}
                />
            </ListItem>)
    }
}


export const actionSelectOpenAppRender2Conf = {
    type:2,
    render:(data, index)=>{return <Render2 data={data} key={`${index}`} />}
}