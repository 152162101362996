import {TaskBase} from "#/htmls/index/vo/project/base/TaskBase";


export const taskMap ={
    [TaskBase.type]:TaskBase
}


export function createTaskByData(project, data){
    // let print = logs.myProjectsInitInfo.sub(false, "fn_createTaskByData").print;
    let task = new taskMap[data.javaData.type]();
    task.project = project;
    task.data = data;
    // print("task is", task, "data is", data);
    return task;
}