import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {f7, Link, ListItem, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import TMs from "#/remotes/TMs";
import {Member} from "#/libs/fanfanlo/remotes/Member";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {MainModel, mainModel} from "../../mc/main/MainModel"
import appJsBridge from "../../androidjsbridge/AppJsBridge";



const ClipboardJS = require('clipboard')
class Render1 extends ComponentBase{
    constructor(props) {
        super(props);
        this.packageId = "packagename_" + props.data.index;
    }
    onClick =()=>{
        f7.dialog.confirm(`确定要操作【${this.props.data.label}】吗？`, ()=>{

        }, ()=>{

        })
    }
    onCopyPackageNameClick =()=>{
        showSimpleToast(`包名已复制 ${this.props.data.packageName}`)
    }
    onOpenAppClick=()=>{
        const script = `com.fanfanlo.lib.intent.Funcs.openApp("${this.props.data.packageName}")`
        appJsBridge.callScript(script);
        // appJsBridge.openApp(this.props.data.packageName);
    }
    onResume() {
        super.onResume();
        new ClipboardJS(`#${this.packageId}`);
    }

    render(){
        let data = this.props.data;
        return (
            <ListItem title={data.label} >
                <img width={30} src={"data:image/gif;base64," + data.iconBase64}
                     slot="media"
                     style={{ borderRadius: '8px' }}
                />
                <div className={"display-flex"} slot={"after"} >
                    <Link data-clipboard-text={data.packageName} id={this.packageId} onClick={this.onCopyPackageNameClick}>包名</Link>
                    <span style={{width:"1rem"}}></span>
                    <Link data-clipboard-text={data.packageName} id={this.packageId} onClick={this.onOpenAppClick}>启动</Link>
                    <span style={{width:"1rem"}}></span>
                    <Link onClick={this.onClick}>使用</Link>
                </div>
            </ListItem>)
    }
}


export const actionSelectOpenAppRender1Conf = {
    type:1,
    render:(data, index)=>{return <Render1 data={data} key={`$index`} />}
}