import {Button, f7} from "framework7-react";
import {Trans} from "react-i18next";
import React from 'react';
import i18n from "i18next";
import {showSimpleToast} from "../../../../../../ui-components/toast/toast";
import {editorModel} from "../../../../mc/editor/EditorModel";
import {IAction, IActionAlias, IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";

export class IDeleteActionButtonProps{
    action!:IAction<any, any>
}
export function CreateDeleteActionButton(props:IDeleteActionButtonProps){

    function onClick(){
        let t = i18n.t
        let s = t(`pageContents.actionUI.deleteButton.confirm.text`,{action:t(`project.task.action.${props.action.type}.name`)})
        f7.dialog.confirm(s, ()=>{
            let action = props.action;
            // action.task.removeAction(action);
            if(action.actionGroup){
                action.actionGroup?.removeAction(action)
            }else{
                action.task?.removeAction(action)
            }
            // editorModel.removeAction(props.action);
            showSimpleToast(t(`pageContents.actionUI.deleteButton.confirm.toast`,{action:t(`project.task.action.${props.action.type}.name`)}))
        })
    }
    return <Button onClick={onClick}>
        <Trans>
            {`pageContents.actionUI.deleteButton.label`}
        </Trans>
    </Button>
}