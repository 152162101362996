import {Log} from "#/libs/fanfanlo/log/Log";

export const logs ={
    myProjectsLog:new Log(false, "_myProjects"),
    router:new Log(false, "_router"),
    actionList:new Log(false, "_actionList"),
    myProjectsInitInfo:new Log(false, "_myProjectsInitInfo"),
    scriptEditFlow: new Log(false, "_scriptEditFlow"),
    globalInfo:new Log(false, "_globalInfo")
}

logs.myProjectsLog.childrenPaused = true;
logs.router.childrenPaused = true;
logs.actionList.childrenPaused = true;
logs.myProjectsInitInfo.childrenPaused = false;
logs.scriptEditFlow.childrenPaused = true;