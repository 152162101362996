
import {ActionBase} from "../../base/ActionBase";
import { CreateToMainActivityUIContent} from "./ActionA11yToMainActivityUI";
import {IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";
import _ from "lodash";
import React from "react";
import {autobind} from "core-decorators";

export interface IActionA11yToMainActivityJavaData extends IActionJavaData{
    pn:string
    activityList:string[]
    trigger:{
        type:string
        pn:string
    }
}

export interface IActionA11yToMainActivityShellData extends IActionShellData{
    maxBackTimes:number
}
export interface IActionA11yToMainActivityShellData extends IActionShellData{}
@autobind
export class ActionA11yToMainActivity extends ActionBase<IActionA11yToMainActivityShellData, IActionA11yToMainActivityJavaData>{
    static type = "com.fanfanlo.droidlib.auto.command.a11y.action.ActionA11yToMainActivity"

    constructor() {
        super();
        this.type = ActionA11yToMainActivity.type;
    }

    updateJavaDataFromFloatTools(data:any){
        data.activityList = [data.an]
        this.updateJavaData(data)
    }
    updateJavaData(data: IActionA11yToMainActivityJavaData) {
        data.activityList = [data.an]
        super.updateJavaData(data);
    }

    getMissionData() {
        let o = {
            "type": `${this.type}`,
            "trigger": {
                "type": "com.fanfanlo.droidlib.auto.command.a11y.trigger.TriggerA11yPackage",
                "pn": this.javaData?.pn
            },
            "activityList": this.javaData?.activityList,
            maxBackTimes:this.shellData.maxBackTimes,
        }
        return _.merge({}, o, super.getMissionData())
    }

    protected createJavaData(): IActionA11yToMainActivityJavaData {
        let o = super.createJavaData();
        let d:IActionA11yToMainActivityJavaData = {
            activityList: [],
            id: "",
            pn: "",
            trigger: {pn: "", type: ""},
            type: ""
        }
        return  _.merge(d, o);
    }

    protected createShellData(): IActionA11yToMainActivityShellData {
        return _.merge({maxBackTimes:10}, super.createShellData());
    }

    createUI(): React.JSX.Element {
        return CreateToMainActivityUIContent(this)
    }

    updateMaxBackTimes(times:number){
        this.shellData.maxBackTimes = times;
        this.dispatchUpdateEvent();
    }
}