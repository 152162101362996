
export const projectConst = {
    action:{
        openAppSelected:"openAppSelected",
        backToMainSelected:"backToMainSelected",
    },
    project:{
        codeChange:"codeChange"
    }
}

export const projectEvents = {

    projectUpdated:"projectUpdated",
    actionUpdated:"actionUpdated",
    taskUpdated:"taskUpdated",
}