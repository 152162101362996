import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {WebViewJs} from "#/android/WebViewJs";
import {androidDispatchers} from "#/android/androidDispatchers";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {WindowConf} from "#/android/win/WindowConf";
import {Log} from "#/libs/fanfanlo/log/Log";

export class Window extends EventDispatcher{
    logger = Log.createCountedLogger(false, this.constructor.name)
    dismissListenIndex = ""
    conf!:WindowConf
    static getDismissEventType(ulid:string):string{
        return `window-${ulid}-dismiss`
    }
    static eventTypeDismiss = "dismiss"

    static create(conf:WindowConf){
        let w = new Window(conf)
        return w;
    }
    constructor(conf:WindowConf) {
        super();
        this.conf = conf;
    }
    init=()=>{
        let {print, error, warn, logger} = this.logger.sub(false, "fn_init");
        let type = Window.getDismissEventType(this.conf.windowId!!);
        print("type is", type)
        this.dismissListenIndex = WebViewJs.instance.shellListen(androidDispatchers.Window.name, type, type, true, this.onDismiss);
        print("dismissListenIndex is", this.dismissListenIndex)
        return this;
    }
    open=()=>{
        if(!this.conf){
            throw Error("no window conf")
        }
        WebViewJs.instance.createWindow(JSON.stringify(this.conf))
        return this;
    }
    onDismiss=()=>{
        let {print, error, warn, logger} = this.logger.sub(false, "fn_onDismiss");
        print("dismiss", Window.getDismissEventType(this.conf.windowId!!))
        this.dispatchEvent(new DataEvent(Window.eventTypeDismiss))
    }
}