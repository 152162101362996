import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {List, ListItem, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import i18n from "i18next";
import {websiteConf} from "../../../../confs/local.conf";
import {LoginByMailContent} from "./login-by-mail";


export class PlayLoginContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {googleWidth:182, googleHeight:200}
    }
    addEvents =()=>{
        window.addEventListener("message", this.onWindowMessage)
    }
    removeEvents = ()=> {
        window.removeEventListener("message", this.onWindowMessage)
    }
    onWindowMessage =(e)=>{
        // "google-login"
        console.log("onWindowMessage is", e);
        try{
            const data = JSON.parse(e.data);
            switch (data.from){
                case "google-login":
                    this.handleLogin(data)
                    break;
                case "google-login-onloaded":
                    this.handleGoogleSize(data)
            }
        }catch (e){ console.log("err", e) }finally {}
    }
    handleLogin = (data)=>{
        console.log("lllllllllllllogin google", data);
    }
    handleGoogleSize =(data)=>{
        this.setState({googleWidth2:data.width, googleHeight:data.height})
    }
    render() {
        return (
            <>
                <PageContent>
                    <List mediaList>
                        {/*<ListItem >*/}
                        {/*    <iframe src={websiteConf.platformConf.googlelogin}*/}
                        {/*            width={this.state.googleWidth} height={this.state.googleHeight}*/}
                        {/*            frameBorder="no" border="0" marginWidth="0" marginHeight="0" scrolling="no"*/}
                        {/*            />*/}
                        {/*</ListItem>*/}
                        <ListItem>
                            <LoginByMailContent />
                        </ListItem>
                    </List>
                </PageContent>
            </>
        )
    }
}

let pageCount = 0;

class PlayLogin extends ComponentBase {
    constructor(props) {
        super(props);
        this.pageId = `playLoginRouter-` + (++pageCount);
        this.pageSelectId = "#" + this.pageId;
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    onPageInit = (e, page) => {
        if (!page) return;
        this.setState({pageParams: page.route.params})
    }

    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={i18n.t(`routes.playLogin}`)} backLink="返回"/>

                <PageContent>
                    <PlayLoginContent/>
                </PageContent>
            </Page>
        )
    }
}

//<Link href={playLoginRouter.path}>{playLoginRouter.name}</Link>
export const playLoginRouter = {
    id: "play-login",
    p: "/play-login/",
    path: "/play-login/",
    component: PlayLogin,
    name: "授权操作"
}
