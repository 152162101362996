import React from 'react';
import 'reflect-metadata';
import 'es6-shim';
import "./i18n/i18nSetting"
import {createRoot} from 'react-dom/client';
// import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';
// import loader from '@monaco-editor/loader';

import "./css/css.scss";
import "../../css/app.scss";
import "../../css/icons.css";
import i18n from "i18next";
import 'framework7/css/bundle';

import Framework7 from 'framework7/lite/bundle';
import Framework7React from 'framework7-react';
import {formatDate} from "#/libs/fanfanlo/utils/utils";
import {javaCaller} from "#/htmls/index/android/javaCaller";
import MyApp from "./app";
import {mainController} from "./mc/main/MainController";
import {isDev} from "#/confs/env";

// import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
// import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
// import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
// import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
// import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';

// self.MonacoEnvironment = {
//     getWorker(v, label) {
//         console.log("vvvvvvvvvvvv", v)
//         console.log("ssssssssssssss", label);
//         if (label === 'json') {
//             return new jsonWorker();
//         }
//         if (label === 'css' || label === 'scss' || label === 'less') {
//             return new cssWorker();
//         }
//         if (label === 'html' || label === 'handlebars' || label === 'razor') {
//             return new htmlWorker();
//         }
//         if (label === 'typescript' || label === 'javascript') {
//             return new tsWorker();
//         }D
//         return new editorWorker();
//     },
// };
//
// loader.config({ monaco });
//
// loader.init().then(v=>{
//     console.log("vvvvvvvvvvvvvvvvvvv", v);
// });
// import {testjavajsDemo} from "#/droid/java_js/templetes/testjavajs.demo";
// testjavajsDemo();
// javaProjectBuilder.init();
javaCaller.init()
// Init F7 React Plugin
Framework7.use(Framework7React)

document.title = i18n.t("appName")
mainController.init();
const root = createRoot(document.getElementById('root'));
root.render(React.createElement(MyApp));

window.android?.onDocumentReady();
// 因为vite手动设置的根目录在src，所以这里的路径需要去掉src
// http://localhost:18001/apps/main/index/index.html/
// http://localhost:18001/htmls/index/#
// /
// testII()
// testProto()
// testQueueMicroTaskDecorator();
// testCallLaterDecoratro();

const showUpdateTime = isDev && false
if (showUpdateTime) {
    const p = document.getElementById('root')
    const d = document.createElement("div")
    p.parentNode.appendChild(d)
    update();

    function update() {
        d.innerHTML = `<div style="position: absolute;z-index:10000;top:0px;left:100px">${formatDate(new Date(), "hh:mm:ss")}</div>`
    }

    if (import.meta.hot) {
        import.meta.hot.on('vite:afterUpdate', () => {
            update();
        })
    }
}


