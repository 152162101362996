import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {Button, List, Navbar, Page, PageContent, Segmented, Tab, Tabs} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import {actionSelectOpenAppRender1Conf} from "./ItemRender1";
import {actionSelectOpenAppRender2Conf} from "./ItemRender2";
import {InstalledAppsModel, installedAppsModel} from "../../mc/installedapp/InstalledAppsModel";
import {getRecentlyList} from "./common";
import * as i18n from "i18next";
import {WebViewJs} from "#/android/WebViewJs";

const renders = {};
(()=>{
    function addRender(conf){
        renders[conf.type] = conf.render;
    }
    addRender(actionSelectOpenAppRender1Conf);
    addRender(actionSelectOpenAppRender2Conf);
})()

class ActionSelectOpenAppContent extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {connecting:false,packages:installedAppsModel.appList,
            packagesCopy:installedAppsModel.appList, parseError:null, isInApp:WebViewJs.instance.isInApp,
            render:renders[props.type],
            recentList: getRecentlyList(),
            tabSelectedIndex:0
        };
        setTimeout(installedAppsModel.checkPackagesFromCatch, 100 * 1);
    }

    addEvents(){
        installedAppsModel.addDomainEventListener(this, InstalledAppsModel.events.appListUpdated, this.onAppListUpdated);
        installedAppsModel.addDomainEventListener(this, InstalledAppsModel.events.appListFetching, this.onAppListFetching);
    }
    removeEvents(){
        installedAppsModel.removeDomain(this);
    }

    onAppListUpdated =(e)=>{
        let list = installedAppsModel.appList;
        this.setState({packages: installedAppsModel.appList, packagesCopy: installedAppsModel.appList, connecting: false})
    }
    onAppListFetching =(e)=>{
        this.setState({connecting: true})
    }

    componentDidMount(){
        super.componentDidMount();
        // 将子组件指向父组件的变量
        this.props.onRef && this.props.onRef(this);
    }
    onSearchBarChange =(e)=>{
        let sb = document.getElementById("searchBar")
        console.log("sb", sb.value);
        this.filter(sb.value, this.state.packagesCopy);
    }
    filter =(words, packages)=>{
        if(!words)this.setState({packages})
        let list = [];
        for (let i = 0; i < packages.length; i++) {
            let item = packages[i];
            if(item.label.indexOf(words) > -1){
                list.push(item);
            }
        }
        this.setState({packages:list})
    }
    onClearButtonClick =()=>{
        this.filter("", this.state.packagesCopy);
    }
    onTabButtonClick =(index)=>{
        this.setState({tabSelectedIndex:index})
    }
    render() {
        if(this.state.parseError){
            return <>
                <div>{i18n.t("pageContents.appList.render.parseError.content")}</div>
                <div>{this.state.parseError.toString()}</div>
            </>
        }
        if(this.state.connecting){
            return <>
            <div>{i18n.t("pageContents.appList.render.fetchingAppList.content")}</div>
            </>
        }
        let ps = this.state.packages;
        if(!ps){
            return <>
            <div>
                {i18n.t("pageContents.appList.render.listIsEmpty.content")}
            </div>
            </>
        }
        if(!this.state.render){
            return <>
            <div>{i18n.t("pageContents.appList.render.noRender.content")}</div>
            </>
        }
        const tabs = [
            {name:i18n.t("pageContents.appList.tabs.total.name")},
            {name:i18n.t("pageContents.appList.tabs.recent.name")}
        ]
        return (
            <>

                <div>

                    <form data-search-container=".search-list" data-search-in=".item-title"
                          className="searchbar searchbar-init app-list-searchbar2">
                        <div className="searchbar-inner">
                            <div className="searchbar-input-wrap">
                                <input id={"searchBar"} onChange={this.onSearchBarChange} type="search"
                                       placeholder={i18n.t("pageContents.appList.tabs.recent.name")}/>
                                <i className="searchbar-icon"></i>
                                <span onClick={this.onClearButtonClick} className="input-clear-button"></span>
                            </div>
                            <span className="searchbar-disable-button">Cancel</span>

                        </div>
                    </form>
                    {/*<Subnavbar inner={false} style={{padding:"0px"}}>*/}
                        <Segmented raised>
                            {tabs.map((data, index)=>{
                                return (<Button tabLinkActive={index == this.state.tabSelectedIndex}
                                                key={index.toString()} tablink={"link" + index}
                                                onClick={()=>
                                                {this.onTabButtonClick(index)}}>
                                    {data.name}
                                </Button>)
                            })}
                        </Segmented>
                    {/*</Subnavbar>*/}
                </div>

                <Tabs>
                    <Tab key={"0"} tabActive={this.state.tabSelectedIndex==0}>

                        <div className="searchbar-backdrop"></div>

                        <div
                            className="list list-strong-ios list-outline-ios list-dividers-ios simple-list searchbar-not-found">
                            <ul>
                                <li>Nothing found</li>
                            </ul>
                        </div>

                        {<List mediaList className={"search-list2 searchbar-found2"} >
                            <ul>
                                {ps.map((data, index)=>{
                                    return this.state.render(data, index)
                                    // return <PackageItem data={data} key={index.toString()} />
                                })}
                            </ul>
                        </List>}
                    </Tab>
                    <Tab key={"1"} tabActive={this.state.tabSelectedIndex==1}>

                        {<List mediaList  >
                            <ul>
                                {this.state.recentList.map((data, index)=>{
                                    return this.state.render(data, index)
                                    // return <PackageItem data={data} key={index.toString()} />
                                })}
                            </ul>
                        </List>}
                    </Tab>
                </Tabs>
            </>
        )
    }
}

let pageCount = 0;

class ActionSelectOpenApp extends ComponentBase {
    constructor(props) {
        super(props);
        this.pageId = `actionSelectOpenAppRouter-` + (++pageCount);
        this.pageSelectId = "#" + this.pageId;
        this.state = {render:null, c:0}
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    onPageInit = (e, page) => {
        if (!page) return;
        let type = page.route?.params?.type || "1"
        this.setState({type})
        this.refreshClicked = false;
    }
    renderNoItemRender(){
        return <div>
            没有渲染器
        </div>
    }
    onRefreshClick =()=>{
        installedAppsModel.refreshPackages()
    }
    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={i18n.t("routes.ActionSelectOpenApp")} backLink="返回">
                    <Button onClick={this.onRefreshClick}>
                        {i18n.t("pageContents.appList.refreshList.buttonName")}
                    </Button>
                </Navbar>
                <PageContent>
                    {this.state.type ?
                        <ActionSelectOpenAppContent
                            type={this.state.type}
                            onRef={ node => this.content = node }/> :
                        this.renderNoItemRender()}

                </PageContent>
            </Page>
        )
    }
}

//<Link href={actionSelectOpenAppRouter.path}>{actionSelectOpenAppRouter.name}</Link>
export const actionSelectOpenAppRouter = {
    browserHistory:false,
    history:false,
    id: "ActionSelectOpenApp",
    p: "/ActionSelectOpenApp/",
    path: "/ActionSelectOpenApp/:type",
    component: ActionSelectOpenApp,
    name: "ActionSelectOpenApp"
}
