import {createWindowConfCopy, HtmlConf, webViewConf, WebViewConf, WebViewJsParams} from "#/android/win/WindowConf";
import {runPermissionGroup} from "#/android/components/permissionGroupCreator";
import to from "await-to-js";
import {Manifest} from "#/android/components/Manifest";
import {showSimpleToast} from "#/ui-components/toast/toast";
import {htmlOrigin} from "#/confs/env";
import {WebViewJs} from "#/android/WebViewJs";


function createCopy(callbackEventType:string){
    let windowConf = {
        htmlConf:{
            url:`${htmlOrigin}htmls/accessibility-info-buttons/#/`,
            webViewJsParams:{
                javaToJsDataString:JSON.stringify({event:callbackEventType})
            }
        }
    }
    return createWindowConfCopy(windowConf)
}

function openWindow(callbackEventType:string){
    let conf = createCopy(callbackEventType);
    WebViewJs.instance.createWindow(JSON.stringify(conf))
}
export async function openHtmlShowA11yNodeInfoButton(callbackEventType:string){
    let [err, group] = await to(runPermissionGroup(Manifest.Permission.SYSTEM_ALERT_WINDOW,
        Manifest.Permission.BIND_ACCESSIBILITY_SERVICE))
    if(err){
        showSimpleToast(err.toString())
        return;
    }
    openWindow(callbackEventType);
}