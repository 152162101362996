import {IScriptConfOptional, mergeScriptConf} from "#/droid/java_js/fanfanlo/javajs/v1/script/runner/ScriptConf";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {javaLib} from "#/droid/java_js_parsed/javaLibDataMap";
import {
    libFooterCode,
    libHeaderCode,
    libLoggerCode
} from "#/droid/java_js/fanfanlo/javajs/v1/script/runner/scriptHeaderFooterCode";

export type LibPathKey = keyof typeof javaLib

function prepareDependencies(dependencies:LibPathKey[], impSet:Set<LibPathKey> = new Set<LibPathKey>(), impList:string[] = []){
    for (let i = 0; i < dependencies.length; i++) {
        let path = dependencies[i];
        if(impSet.has(path))continue;
        let file = javaLib[path];
        if(!file){
            let p2 = "fanfanlo/javajs/v1/project/components/trigger/a11y/PackageInOut";
            console.log("error script runner prepare dependencies error, path is", path)
            console.log("--------------------")
            console.log(path);
            console.log("============== java lib")
            console.log(javaLib)
            console.log("================== java lib end")
            console.log(dependencies)
            console.log("-------------------dependencies")
            throw new Error(`no path in javaLib, path is ${path}`)
        }
        impSet.add(path);
        impList.push(`"${path}":${file.content}`);
        if(file.dependencies && file.dependencies.length > 0){
            let list = file.dependencies.map(d=>d.path) as LibPathKey[]
            prepareDependencies(list, impSet, impList)
        }
    }
    return impList;
}

function createLibScript(dependencies:LibPathKey[]){
    let list = prepareDependencies(dependencies);
    let content = `let javaLib = {
        ${list.join(",")}
        }`
    return content;
}

/**
 *
 * @param conf
 * @param dependencies
 * @param beforeJavaLibCreateScript 创建javalib之前执行，例如可以创建全局变量和屏蔽一些log的打印等
 *
 * projectFlowLogger.paused = true;
 * projectStoreLogger.paused = true;
 *
 */
function runJavaLibScript(conf:IScriptConfOptional, dependencies:LibPathKey[],beforeJavaLibCreateScript:string){
    try{

        let c = mergeScriptConf(conf);
        let s = createLibScript(dependencies);
        // 不用顶级类的想法是将来顶级类多了，而脚本可能就几行，这样会比较浪费
        c.script = `
    ${libLoggerCode}
    ;
    ${beforeJavaLibCreateScript}
    ;
    ${libHeaderCode}
    ;
    ${s}
    ;
    ${libFooterCode}
    ;
    ${c.script}
    ;
    `
        let script = AutoWebViewJs.confToScript(c);
        // console.log("-------------------------1")
        // WebViewJs.instance.printString(c.script)
        // console.log("=======================1")
        return AutoWebViewJs.instance.callScriptRunner(script);
    }catch (e:any){
        console.log("runJavaLibScript catch error", e?.message, e?.stack)
        console.log("-------------------")
        console.log(conf);
        console.log("=====================")
        console.log(beforeJavaLibCreateScript)
    }
}

export {runJavaLibScript}