import React from 'react';
import {Block, f7, Link} from "framework7-react";
import {CreateDeleteActionButton} from "./del-action-button";
import {EditorModel, editorModel} from "../../../../mc/editor/EditorModel";
import {openAppNavigate} from "../../../../pages/actionList/data";
import {Trans} from "react-i18next";
import {AndroidAppIcon} from "../../../../pages/components/AndroidAppIcon";
import {InstalledAppsModel, installedAppsModel} from "../../../../mc/installedapp/InstalledAppsModel";
import {ComponentBase} from "../../../../../../libs/fanfanlo/react/components/ComponentBase";
import {Log} from "#/libs/fanfanlo/log/Log";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import {createSubUrlParams} from "#/htmls/index/pages/RhinoScriptEdtor/projectEditorUtils";
import {actionSelectOpenAppRender2Conf} from "#/htmls/index/pages/actionSelectOpenApp/ItemRender2";
import {actionSelectOpenAppRouter} from "#/htmls/index/pages/actionSelectOpenApp/ActionSelectOpenApp";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";

class ActionOpenAppUI extends ComponentBase{
    constructor(props) {
        super(props);
        this.logger = Log.createCountedLogger(false, this.constructor.name);
        let appInfo = installedAppsModel.getInstalledAppByPackageName(this.props.action.javaData.packageName);
        this.state = {appInfo, base64:installedAppsModel.getInstalledAppByPackageName(this.props.action.javaData.packageName)?.iconBase64}
    }
    addEvents() {
        super.addEvents();
        installedAppsModel.addDomainEventListener(this, InstalledAppsModel.events.appListUpdated, this.onAppListUpdated);
        this.props.action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onJavaDataUpdated);
    }
    removeEvents() {
        super.removeEvents();
        installedAppsModel.removeDomain(this);
        this.props.action.removeDomain(this);
    }
    onJavaDataUpdated =()=>{
        this.updateAppInfo();
    }
    updateAppInfo =()=>{
        this.onAppListUpdated()
    }
    onAppListUpdated =()=>{
        let appInfo = installedAppsModel.getInstalledAppByPackageName(this.props.action.javaData.packageName);
        this.setState({appInfo, base64:installedAppsModel.getInstalledAppByPackageName(this.props.action.javaData.packageName)?.iconBase64})
    }
    onChangeAppClick =()=>{
        let url = f7.views.main.router.currentRoute.url;
        let event = this.props.action.getEventType(ProjectEditEnum.Update)
        let query = createSubUrlParams(url, event);
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeevent is", event)
        let nps = {
            query:query,
            params:{type:actionSelectOpenAppRender2Conf.type},
            name:actionSelectOpenAppRouter.name
        }
        f7.views.main.router.navigate(nps)
    }
    render(){
        return (
            <>
                <Block>
                    <div>
                        {this.state.base64 && <AndroidAppIcon base64={this.state.base64} />}
                        {this.state.appInfo?.label}
                    </div>
                    <div className={"grid grid-gap  grid-cols-2"}>
                        <CreateDeleteActionButton action={this.props.action} />
                        <Link onClick={this.onChangeAppClick} href={"#"}>
                            <Trans>
                                pageContents.actionUI.changeButton.label
                            </Trans>
                        </Link>
                    </div>
                </Block>
            </>
        )
    }
}

/**
 * @param {IAction<IOpenAppShellData, IOpenAppJavaData>} action
 */
export function CreateOpenAppUIContent(action){
    return <ActionOpenAppUI action={action} />
}
