import {ActionBase} from "../../base/ActionBase";
import {CreateA11yNodeUI} from "./ActionA11yNodeUI";
import {IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";
import {autobind} from "core-decorators";
import {
    SFAction,
    SFActionClick, SFActionGlobalClick,
    SFActionLongClick,
    SFActionSetText, SFActionTryClick,
    SFCond, SFCondClickable,
    SFCondDesc,
    SFCondId, SFCondMatchText,
    SFCondRoot,
    SFCondText,
    SFData, SFWait
} from "#/htmls/index/vo/project/a11y/actions/sfdata";
import _ from "lodash";
import {
    a11yNodePropertyKeys,
    a11yNodeProperyEnum,
    IA11yNodeInfo,
    NodeAction,
    NodeActionKeys
} from "#/htmls/index/vo/project/a11y/actions/A11yNode";
import React from "react";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {WebViewJs} from "#/android/WebViewJs";
import {androidDispatchers} from "#/android/androidDispatchers";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {Manifest} from "#/android/components/Manifest";
import {TypePartial} from "#/libs/fanfanlo/tsii/IObject";

export interface IActionA11yNodeJavaData extends IActionJavaData {
    sfData:SFData
}

export interface IA11yNodeRegexProperties{
    text?:string
    contentDescription?:string
    hintText?:string
}
export interface IA11yNodeWait{
    time:number
    interval:number
    invisible:boolean
}

export interface IActionA11yNodeShellData extends IActionShellData{
    info?:IA11yNodeInfo
    action?:NodeAction
    setText?:string
    selectedProperties:a11yNodePropertyKeys[]
    regexProperties:IA11yNodeRegexProperties
    wait:IA11yNodeWait
}
@autobind
export class ActionA11yNode extends ActionBase<IActionA11yNodeShellData, IActionA11yNodeJavaData> {
    static type = "com.fanfanlo.droidlib.auto.command.a11y.action.ActionA11yNode2"

    static eventType = {
        selectePropertiesChanged:"selectePropertiesChanged",
        nodeActionChanged:"nodeActionChanged",
        nodeWaitChanged:"nodeWaitChanged",
    }
    private WebViewJsUpdateListenIndex!:number;
    constructor() {
        super();
    }

    protected addEvents() {
        super.addEvents();
        let type = this.getEventType(ProjectEditEnum.Update);
        this.WebViewJsUpdateListenIndex = WebViewJs.instance.shellListen(androidDispatchers.WebViewJsDispatcher.name, type, type, false, this.onUpdateNodeSelected);
    }

    onUpdateNodeSelected(s:string){
        console.log("oooooooooooooooooooon update node selected", s);
        let info = JSON.parse(s);
        this.resetNodeInfo();
        this.setNodeData(info);
        WebViewJs.instance.openSelfByPackage();
    }
    getMissionData() {
        let sfData = this.getSfData();
        return {
            "type": `${this.type}`,
            sfData:sfData.data,
            "trigger": {
                "type": "com.fanfanlo.droidlib.auto.command.a11y.trigger.TriggerA11yNode",
                "sfRoot": sfData.root?.getCondRoot()
            },
        }
    }
    updateNodeAction(action:NodeAction){
        this.shellData.action = action;
        this.dispatchEvent(new DataEvent(ActionA11yNode.eventType.nodeActionChanged))
        this.dispatchUpdateEvent();
    }
    private resetNodeInfo(){
        this.shellData.action = undefined;
        this.shellData.selectedProperties = [];
        this.shellData.setText = "";
    }
    setNodeData(info:IA11yNodeInfo){
        let data :any = {info: info}
        this.updateShellData(this.returnOrCreateShellData(data))
    }
    protected createJavaData():IActionA11yNodeJavaData{
        let superData = super.createJavaData();
        let data:IActionA11yNodeJavaData = _.merge({sfData: {}}, superData)
        return data;
    }

    protected createShellData():IActionA11yNodeShellData{
        let superData = super.createShellData();
        let data = {info: undefined, action:undefined, selectedProperties:[], regexProperties:{}, wait:{time:1000 * 7, interval:20 * 1, invisible:false}}
        return _.merge(data, superData)
    }
    createUI(): React.JSX.Element {
        return CreateA11yNodeUI(this);
    }

    dispatchSelectePropertiesChanged(){
        this.dispatchEvent(new DataEvent(ActionA11yNode.eventType.selectePropertiesChanged))
        this.dispatchUpdateEvent();
    }
    updateWait(wait:TypePartial<IA11yNodeWait>){
        _.merge(this.shellData.wait, wait)
        this.dispatchWaitChanged();
    }
    dispatchWaitChanged(){
        this.dispatchEvent(new DataEvent(ActionA11yNode.eventType.nodeWaitChanged))
        this.dispatchUpdateEvent();
    }

    private getNodeAction(){
        let sfAction:SFAction|undefined;
        if(!this.shellData.action)return sfAction
        switch (this.shellData.action.name){
            case NodeActionKeys.click:
                sfAction = new SFActionClick()
                break;
            case NodeActionKeys.longClick:
                sfAction = new SFActionLongClick()
                break;
            case NodeActionKeys.setText:
                let action = new SFActionSetText()
                action.value = this.shellData.setText ?? "";
                sfAction = action
                break;
            case NodeActionKeys.globalClick:
                sfAction = new SFActionGlobalClick();
                break;
            case NodeActionKeys.tryClick:
                sfAction = new SFActionTryClick();
                break;
            default:
                // not set yet
        }
        return sfAction
    }
    private getNodeActionCode(){
        let action = this.getNodeAction();
        let code = action?.data ?? "";
        return code;
    }

    private getSfData(){
        let sfData = new SFData();
        sfData.action = this.getNodeAction()
        sfData.root = this.getSfRoot()
        sfData.wait = this.getSfWait()
        return sfData
    }

    private getSfRoot(){
        let root = new SFCondRoot();
        let shellData = this.shellData;
        if(!shellData)return root;
        let info = shellData.info;
        if(!info)return root;
        root.pn = info.pn ?? ""
        // if(info.an){
        //     root.an = [info.an];
        // }
        if(info.activeActivity){
            root.an = [info.activeActivity];
        }
        let cond:SFCond|undefined = root;
        let node = info.node;
        for (let i = 0; i < shellData.selectedProperties.length; i++) {
            let p = shellData.selectedProperties[i];
            let next:SFCond|undefined;
            switch (p){
                case a11yNodeProperyEnum.viewIdResourceName:
                    if(!node.viewIdResourceName){break;}
                    let id = new SFCondId();
                    id.value = node.viewIdResourceName;
                    next = id;
                    break;
                case a11yNodeProperyEnum.text:
                    if(!node.text)break;
                    if(this.shellData.regexProperties.text){
                        let matchText = new SFCondMatchText()
                        matchText.value = `.*${this.shellData.regexProperties.text}.*`
                        next = matchText;
                    }else{
                        let text = new SFCondText();
                        text.value = node.text;
                        next = text;
                    }
                    break;
                case a11yNodeProperyEnum.contentDescription:
                    if(!node.contentDescription)break;
                    let desc = new SFCondDesc();
                    desc.value = node.contentDescription;
                    next = desc;
                    break;
                case a11yNodeProperyEnum.isClickable:
                    if(!node.isClickable)break;
                    let click = new SFCondClickable();
                    click.value = node.isClickable;
                    next = click;
                    break;

            }
            if(cond){
                cond.next = next;
            }
            cond = next;
        }
        return root;
    }
    private getSfWait(){
        let wait = new SFWait();
        _.merge(wait, this.shellData.wait)
        return wait;
    }
    getPermissions(): string[] | undefined {
        return [
            Manifest.Permission.BIND_ACCESSIBILITY_SERVICE
        ]
    }
}