import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {readProjectsFromLocalStorage, saveProjectListToLocalStorage} from "#/htmls/index/vo/project/base/projectsLocalData";
import {Log} from "#/libs/fanfanlo/log/Log";
import {createProjectBase, initProjectMap} from "#/htmls/index/vo/project/base/ProjectMap";
import {IProject} from "#/htmls/index/vo/project/base/IProject";
import {ITask} from "#/htmls/index/vo/project/base/ITask";
import _ from "lodash";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";

export class MyProjects extends EventDispatcher{
    static instance:MyProjects;
    static events = {
        projectListUpdated:"projectListUpdated"
    }
    static createEventType =(editType:ProjectEditEnum, projectId:string = "*",
                             taskId:string = "*", actionId:string = "*", childActionid:string = "*")=>{
        return `{"type":"${editType}","project":"${projectId}","task":"${taskId}","action":"${actionId}", "childAction":"${childActionid}"}`;
    }
    logger = Log.createCountedLogger(false, this.constructor.name);
    print = this.logger.print;
    projectList:IProject[] = []
    constructor() {
        super();
        if(MyProjects.instance){
            throw new Error("multi MyProjects")
        }
        MyProjects.instance = this;
    }
    init =()=>{
        initProjectMap()
        let p = readProjectsFromLocalStorage(this);
        this.projectList = p.projectList;
    }
    addProject=(proj:IProject, index?:number)=>{
        proj.myProjects = this;
        let bl = this._removeProject(proj);
        index = (index === undefined || index === null) ? this.projectList.length : index;
        index = Math.max(0, Math.min(this.projectList.length, index));
        this.projectList.splice(index, 0, proj);
        this.dispatchProjectListUpdated();
    }
    createProject=()=>{
        let p = createProjectBase(this);
        this.addProject(p);
        return p;
    }
    getProjectById =(id:string)=>{
        for (let i = 0; i < this.projectList.length; i++) {
            let p = this.projectList[i];
            if(p.id == id)return p;
        }
        return ;
    }
    removeProject=(proj:IProject)=> {
        let bl = this._removeProject(proj)
        if(bl)this.dispatchProjectListUpdated();
        return bl;
    }
    _removeProject=(proj:IProject)=>{
        let i = this.projectList.indexOf(proj);
        if (i == -1)return false;
        let arr = this.projectList.splice(i, 1);
        return true;
    }
    getProjectIndexById=(id:string)=>{
        for (let i = 0; i < this.projectList.length; i++) {
            let proj = this.projectList[i];
            if(proj.javaData.id == id)return i;
        }
        return -1;
    }
    dispatchProjectListUpdated =()=>{
        this.dispatchEvent(new DataEvent(MyProjects.events.projectListUpdated))
    }
    save=()=>{
        return saveProjectListToLocalStorage(this);
    }
    compareProjects=(list:IProject[])=>{
        let print = this.logger.sub(false, "compareProjects").print;
        let isSameLength = list.length == this.projectList.length;

        for (let i = 0; i < this.projectList.length; i++) {
            let p1 = this.projectList[i];
            let p2 = list[i];
            let p1id = p1?.javaData.id;
            let p2id = p2?.javaData.id;
            print("project is same", p1 == p2, "pid is same=",  p1id == p2id, "p1id", p1id, "p2id", p2id, "p1 is", p1, "p2 is", p2);
        }
    }


}