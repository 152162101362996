import {storage} from "../../../../common/storage/storage";

export const openAppPageRecentListItemKey = "openAppPageRecentList"


export function getRecentlyList(){
    const s = storage.getItem(openAppPageRecentListItemKey);
    return s || [];
}

export function flushRecentlyList(list){
    storage.setItem(openAppPageRecentListItemKey, list);
}

function laterAddNewRecentApp(app){
    setTimeout(()=>{

        let l = [app];
        let list = getRecentlyList();
        for (let i = 0; i < list.length && l.length < 20; i++) {
            let a = list[i];
            if(a.packageName != app.packageName){
                l.push(a);
            }
        }
        flushRecentlyList(l);
    }, 1000 * 3);
}
export function addNewRecentApp(app){
    laterAddNewRecentApp(app);
}