import {Block, f7, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import {MainModel, mainModel} from "../../mc/main/MainModel";
import {userModel} from "../../mc/user/UserModel";
import appJsBridge from "../../androidjsbridge/AppJsBridge";
import {ComponentBase} from "../../../../libs/fanfanlo/react/components/ComponentBase";
import Member from "../../../../libs/fanfanlo/remotes/Member";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";
import {visitModel} from "../../../../mc/VisitModel";

export class UnsubscribeContent extends ComponentBase {
    constructor(props) {
        super(props);
    }
    onClick =()=>{
        f7.dialog.confirm("您正在操作注销账号，确定注销请点击【确认】，否则点击【取消】", "提示", ()=>{
            console.log("ok clicked")
            this.onLogoutClick();
        }, ()=>{
            console.log("cancel clicked")
        })
    }

    onLogoutClick =() =>{

        let member = this._logoutMember = new Member(TMs.TSession, TMs.MSessionLogout, {}, (res)=>{
            console.log(`tms.TSession, tms.MSessionLogout res is`, res)
            this.onLogout();
        }, (member)=>{
            console.log(`tms.TSession, tms.MSessionLogout net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`tms.TSession, tms.MSessionLogout data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            console.log(`tms.TSession, tms.MSessionLogout complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        }, null, true, true);
        mainModel.dispatchEvent(new DataEvent(MainModel.events.addWaitingMember, member.send));
    }
    onLogout = ()=>{
        console.log("dialog alert open")
        f7.dialog.alert("已为您启动注销账号程序，请在60天冻结期内不要登录此账号了。", ()=>{
            console.log("dialog alert call back")
            f7.view.main.router.back();
            console.log("dialog alert backed")
            setTimeout(()=>{
                visitModel.logout();
                appJsBridge.callUserLogout();
            },1000);
        })
    }
    render() {
        return (
            <>
                <Block className="content-body clearfix">
                    <div className="box">
                        <div className="box-title" style={{textAlign:"center"}}><h3>帐号注销协议</h3></div>
                        <div className="box-article">
                            <p>您在申请注销流程中点击同意前，应当认真阅读《帐号注销协议》（以下简称“本协议”）。<strong>特别提醒您，当您成功提交注销申请后，即表示您已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果您不同意相关任何条款，请您立即停止帐号注销程序。</strong>
                                如您对本协议有任何疑问，可通过客服专区联系我们的客服。
                            </p><p>1. 如果您仍欲继续注销帐号，您的帐号需同时满足以下条件：</p><p>（1）帐号不在处罚状态中，且能正常登录；</p>
                            <p>（2）帐号最近一个月内并无修改密码、修改关联手机、绑定手机记录。</p>
                            <p>2.您应确保您有权决定该帐号的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何争议，由您自行承担。</p>
                            <p>3.您理解并同意，帐号注销后我们无法协助您重新恢复前述服务。请您在申请注销前自行备份您欲保留的本帐号信息和数据。</p>
                            <p>4.帐号注销后，已绑定的手机号将会被解除绑定。</p><p>
                            <strong>5.注销帐号后，您将无法再使用本帐号，也将无法找回您帐号中及与帐号相关的任何内容或信息，包括但不限于：</strong></p><p>
                            <strong>（1）您将无法继续使用该帐号进行登录；</strong></p><p>
                            <strong>（2）您帐号的个人资料和历史信息（包含昵称、头像、消息记录等）都将无法找回；</strong></p><p>
                            <strong>（3）您理解并同意，注销帐号后，您曾获得的礼品券及其他虚拟财产等将视为您自愿、主动放弃，无法继续使用，由此引起一切纠纷由您自行处理，我们不承担任何责任。</strong>
                        </p><p><strong>（4）以本帐号注册的所有内容、信息、记录均会被删除或匿名化，且无法恢复。</strong></p><p>
                            <strong>6.在帐号注销期间，如果您的帐号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，我们有权自行终止您的帐号注销程序，而无需另行得到您的同意。</strong>
                        </p>
                            <p><strong>7.请注意，注销您的帐号并不代表本帐号注销前的帐号行为和相关责任得到豁免或减轻。</strong></p>
                            <p><strong>8.账号注销后请在60日的锁定期内不要再登录这个账号进行操作，否则可能会导致注销流程的工作不能完成。</strong></p>
                        </div>
                    </div>
                    <div>
                        <a onClick={this.onClick}>注销</a>
                    </div>
                </Block>
            </>
        )
    }
}

export class Unsubscribe extends ComponentBase {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page pageContent={false} className={"no-toolbar"}>
                <Navbar title={UnsubscribeRouter.name} backLink="返回"/>
                <PageContent>
                    <UnsubscribeContent/>
                </PageContent>
            </Page>
        )
    }
}

export const UnsubscribeRouter = {
    path: "/Unsubscribe/",
    component: Unsubscribe,
    name: "Unsubscribe"
}
