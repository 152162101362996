import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import React from "react";
import {ActionMulti, IActionMultiJavaData, IActionMultiShellData} from "#/htmls/index/vo/project/base/ActionMulti";
import {IAction, IActionJavaData, IActionShellData} from "#/htmls/index/vo/project/base/IAction";
import {Button, f7, Link, List} from "framework7-react";
import {actionListRouter} from "#/htmls/index/pages/actionList/ActionList";
import {createSubUrlParamsString} from "#/htmls/index/pages/RhinoScriptEdtor/projectEditorUtils";
import {CreateActionListUI} from "#/htmls/index/vo/project/a11y/actions/ActionUIList";
import {ActionBase} from "#/htmls/index/vo/project/base/ActionBase";
import {Event2Any} from "#/libs/fanfanlo/events/DataEvent";
import i18n from "i18next";
import {ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {CreateDeleteActionButton} from "#/htmls/index/vo/project/a11y/actions/del-action-button";


class CompleteCondProps {
    action!:ActionMulti
    list:boolean[] = []
}

class CompleteCondState {
    list:boolean[] = []
}

export class CompleteCondUI extends ComponentBase<CompleteCondProps, CompleteCondState>{
    constructor(props:CompleteCondProps) {
        super(props);
        let state = new CompleteCondState();
        state.list = this.props.list
        this.state = state;
    }
    addEvents() {
        super.addEvents();
    }

    removeEvents() {
        super.removeEvents();
    }

    renderEmpty(){
        return(
            <div>empty</div>
        );
    }
    render(){
        return(
            <div className={"border-rect"}>
                <div>{i18n.t("project.task.action.actionMulti.completeCondition.condGroupTitle")}</div>
                <List>
                    {this.props.action.actionList.map((action, index) => {
                        const onChange = (e:any)=>{
                            this.state.list[index] = e.target.checked;
                            action.dispatchUpdateEvent()
                        }
                        let checked = this.state.list[index]
                        return (
                            <label key={index.toString()} className="item-checkbox item-content">
                                <input type="checkbox" onChange={onChange} defaultChecked={checked}/>
                                <i className="icon icon-checkbox"></i>
                                <div className="item-inner">
                                    <span>{action.getUITitle()}</span>
                                </div>
                            </label>
                        )
                    })}
                </List>
            </div>
        );
    }
}

class CompleteCondListProps {
    action!: ActionMulti
}

class CompleteCondListState {
    complateCond: boolean[][] = []
}

export class CompleteCondListUI extends ComponentBase<CompleteCondListProps, CompleteCondListState> {
    constructor(props: CompleteCondListProps) {
        super(props);
        let state = new CompleteCondListState();
        state.complateCond = this.props.action.shellData.complateCond
        this.state = state;
    }

    addEvents() {
        super.addEvents();
    }

    removeEvents() {
        super.removeEvents();
    }

    renderEmpty(){
        return(
            <div></div>
        );
    }
    onAddCondGroupClick=()=>{
        let list = this.props.action.shellData.complateCond;
        let cond:boolean[] = new Array(this.props.action.actionList.length).fill(true)
        list.push(cond);
        this.setState({complatedCond:list})
        this.props.action.dispatchUpdateEvent();
    }
    render(){
        let action = this.props.action
        if(!action || action.actionList.length == 0)return this.renderEmpty()
        return(
            <>
                <div>
                    <span>{i18n.t("project.task.action.actionMulti.completeCondition.title")}</span>
                    <Button onClick={this.onAddCondGroupClick}>{i18n.t("project.task.action.actionMulti.buttons.addCompleteConditions.name")}</Button>
                </div>
                <List>
                    {
                        this.state.complateCond.map((list, index)=>{
                            return <CompleteCondUI action={this.props.action} list={list} key={index.toString()} />
                        })
                    }
                </List>
            </>
        );
    }
}

class ActionMultiUIProps {
    action!:ActionMulti;
}

class ActionMultiUIState {
    list!:IAction<IActionShellData, IActionJavaData>[]
}

export class ActionMultiUI extends ComponentBase<ActionMultiUIProps, ActionMultiUIState>{
    constructor(props:ActionMultiUIProps) {
        super(props);
        let state = new ActionMultiUIState();
        state.list = this.props.action.shellData.actionList;
        this.state = state;
    }
    addEvents() {
        super.addEvents();
        this.props.action.addDomainEventListener(this, ActionBase.eventTypes.actionUpdated, this.onActionUpdated)
    }

    removeEvents() {
        super.removeEvents();
    }

    onActionUpdated = (e:Event2Any)=>{
        this.setState({list:this.props.action.shellData.actionList});
    }

    renderEmpty(){
        return(
            <div>empty</div>
        );
    }
    render(){
        let event = this.props.action.getEventType(ProjectEditEnum.Add)
        let link = `${actionListRouter.path}?${createSubUrlParamsString(f7.views.main.router.currentRoute.url, event)}`
        return(
            <div className={"padding-12"}>
                <div>
                    <Link href={link} >{i18n.t("project.task.action.actionMulti.buttons.actionList.name")}</Link>
                    <CompleteCondListUI action={this.props.action} />
                </div>
                <div>
                    <CreateActionListUI actionList={this.props.action.actionList}/>
                </div>

                <div>
                    <CreateDeleteActionButton action={this.props.action} />
                </div>
            </div>
        );
    }
}

export function CreateActionMultiUI<T extends IActionMultiShellData, U extends IActionMultiJavaData>(action:IAction<T, U>){
    return <ActionMultiUI action={action as unknown as ActionMulti} />
}
