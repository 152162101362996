import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import React, {ChangeEvent} from "react";
import {IProject} from "#/htmls/index/vo/project/base/IProject";
import {Block} from "framework7-react";
import Dom7 from "dom7";
import {Event2Any} from "#/libs/fanfanlo/events/DataEvent";
import {ProjectBase} from "#/htmls/index/vo/project/base/ProjectBase";
import {IActionAlias} from "#/htmls/index/vo/project/base/IAction";
import {autobind} from "core-decorators";

class ScriptCodeTextAreaProps {
    project!:IProject
}

class ScriptCodeTextAreaState {
    script = ""
}

@autobind
export class ScriptCodeTextAreaUI extends ComponentBase<ScriptCodeTextAreaProps, ScriptCodeTextAreaState> {
    constructor(props: ScriptCodeTextAreaProps) {
        super(props);
        this.state = new ScriptCodeTextAreaState();
        this.updateProjectData()
    }

    addEvents() {
        super.addEvents();
        this.props.project?.addDomainEventListener(this, ProjectBase.eventTypes.projectUpdated, this.onProjectUpdate);
    }

    removeEvents() {
        super.removeEvents();
        this.props.project?.removeDomain(this);
    }

    onProjectUpdate(e:Event2Any){
        this.updateProjectData()
    }
    updateProjectData(){
        let project = this.props.project;
        this.updateCode(project.actionListCode)
    }
    updateCode(script:string){
        this.setState({script})
    }
    taOnChange =(e:ChangeEvent<HTMLTextAreaElement>)=>{
        let value = e.target.value
        let code = Dom7("#ta").val();
        // editorModel.updateCode(code)
    }
    renderEmpty() {
        return (
            <>ccc</>
        )
    }

    render() {
        if(!this.props.project)return this.renderEmpty()
        return (
            <>
                <>
                    <div style={{backgroundColor:"#FFFFFF", borderStyle:"solid", padding:"3px", margin:"3px"}}>
                        <textarea disabled={true} id={"ta"}
                                  onChange={this.taOnChange}
                                  rows={25} style={{width:"100%"}}
                                  value={this.state.script}></textarea>
                    </div>
                    <div className={"display-flex"}>
                    </div>
                </>
            </>
        )
    }
}