import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import React from 'react';
import {Button, f7} from "framework7-react";
import {editorModel} from "#/htmls/index/mc/editor/EditorModel";
import {RhinoScriptEdtorRouter} from "#/htmls/index/pages/RhinoScriptEdtor/RhinoScriptEdtor";
import {Log} from "#/libs/fanfanlo/log/Log";
import i18n from "i18next";



export class CreateScriptButton extends ComponentBase {
    constructor(props) {
        super(props);
    }

    emptyRender() {
        return (<></>)
    }

    onClick =()=>{
        let proj = editorModel.myProjects.createProject();
        editorModel.setEditProject(proj);
        // RhinoScriptEdtorRouter.p+"-1"
        let url = `${RhinoScriptEdtorRouter.p}${proj.id}`
        f7.views.main.router.navigate(url);
    }
    render() {
        print("pppppppppppppppppppddddddddddddd")
        return (
            <>
                <Button onClick={this.onClick}>{i18n.t("pageContents.scriptEditor.buttonNewScript")}</Button>
            </>
        )
    }
}


var logger = Log.createCountedLogger(true, CreateScriptButton);
var print = logger.print;