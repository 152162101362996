import {EventDispatcher} from "../../../../libs/fanfanlo/events/EventDispatcher";

import {actionSelectOpenAppRouter} from "../actionSelectOpenApp/ActionSelectOpenApp";
import {actionSelectOpenAppRender2Conf} from "../actionSelectOpenApp/ItemRender2";
import {f7} from "framework7-react";
import {EditorModel, editorModel} from "../../mc/editor/EditorModel";
import {f7TryBack} from "#/libs/fanfanlo/f7/f7RouteUtils";
import {logs} from "#/htmls/index/utils/log";
import {getProjectByRouterCaller} from "#/htmls/index/mc/editor/scriptEditUtils";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import to from "await-to-js";
import {openPnAnInfoWindow, PnAnResultData} from "#/htmls/accessibility-pn-an-info/PnAnInfoWindow";
import {openHtmlShowA11yNodeInfoButton} from "#/htmls/accessibility-info-buttons/html-show-a11y-node-info-button-conf";
import {BackToMainSelectedEvent} from "#/htmls/index/events/action/add/BackToMainSelectedEvent";
import {taskConst} from "#/htmls/index/vo/project/base/taskConst";

const treePrefix = `pageContents.actionList.tree.`


function backToCaller(){
    let route = f7.views.main.router.currentRoute;
    let query = route.query;
    // let link = `${actionSelectOpenAppRouter.p}${actionSelectOpenAppRender2Conf.type}?caller=${query?.caller}`
    // console.log("data.js calllllllllllll back to caller", query?.caller)
    let caller = query?.caller;
    if(caller){
        f7TryBack(caller);
    }else{
        f7.views.main.router.back();
    }
    // f7.views.main.router.back(query?.caller);
}

export async function handleSelectOpenAppBackToMainActivity(navBack:boolean){
    const proj = getProjectByRouterCaller();
    let [err, res] = await to<any, PnAnResultData>(openPnAnInfoWindow());
    let eventType = f7.views.main.router.currentRoute.query.event;
    if(eventType && res){
        editorModel.myProjects.dispatchEvent(new BackToMainSelectedEvent(eventType, res))
    }
    if(res){
        // proj?.dispatchEvent(new Event2(projectConst.action.backToMainSelected, res))
    }
    if(navBack){
        backToCaller();
    }
    const script = `com.fanfanlo.lib.intent.Funcs.openSelfByPackage()`
    AutoWebViewJs.instance.callScript(script);
}

export function openAppNavigate(query?:any){
    let print = logs.actionList.sub(false, "fn_openAppNavigate-openAppNavigate").print;
    let route = f7.views.main.router.currentRoute;
    query = query || route.query;
    let nps = {
        // path:`${actionSelectOpenAppRouter.p}${actionSelectOpenAppRender2Conf.type}`,
        query:query,
        params:{type:actionSelectOpenAppRender2Conf.type},
        name:actionSelectOpenAppRouter.name
    }
    f7.views.main.router.navigate(nps)
}

export function openAppNavigate2(extraParams:any, extraQuery:any, caller:string){
    let print = logs.actionList.sub(false, "fn_openAppNavigate-openAppNavigate").print;
    let route = f7.views.main.router.currentRoute;
    let query = route.query;
    caller = caller || query?.caller || ""
    let event = query?.event || ""
    let projId = query?.projId;
    let link = `${actionSelectOpenAppRouter.p}${actionSelectOpenAppRender2Conf.type}?caller=${caller}&event=${event}`
    let nps = {
        // path:`${actionSelectOpenAppRouter.p}${actionSelectOpenAppRender2Conf.type}`,
        query:query,
        params:{type:actionSelectOpenAppRender2Conf.type},
        name:actionSelectOpenAppRouter.name
    }
    f7.views.main.router.navigate(nps)
    print("projId is", projId, "caller is", caller, "link is", link)
    // f7.views.main.router.navigate(link);
    return link;
}
export const actionIds ={
    openApp:"openApp",
    "a11yBackToMain":"a11yBackToMain",
    "a11yNodeOperation":"a11yNodeOperation",
}

const tree = [
    {
        name:"app",
        children: [
            {
                name:"openApp",
                id:actionIds.openApp,
                link:``,
                navigate:function (extraParams:any, extraQuery:any, caller:string){
                    const {print, logger} = logs.actionList.sub(false, "app_openApp_navigate")
                    logger.forcePrint = true;
                    editorModel.itemEditType = EditorModel.itemEditType.add;
                    openAppNavigate()
                },
            },
        ]
    },
    {
        name: "a11y",
        children: [
            {
                name:"backToMain",
                id:actionIds.a11yBackToMain,
                explain:true,
                navigate:function (extraParams:any, extraQuery:any){
                    // backToCaller();
                    // checkRunA11yPnNAnInfoPanel();
                    handleSelectOpenAppBackToMainActivity(true);
                }

            },
            // {
            //     name:"openAppNBackToMain",
            //     explain:true,
            //     navigate:function (extraParams:any, extraQuery:any){
            //         // backToCaller();
            //         // checkRunA11yPnNAnInfoPanel();
            //         handleSelectOpenAppBackToMainActivity(true);
            //
            //     }
            // },
            {
                name:"nodeOperation",
                id:actionIds.a11yNodeOperation,
                explain: true,
                navigate:function (extraParams:any, extraQuery:any){
                    let query = f7.views.main.router.currentRoute.query;
                    let event = query?.event;
                    if(!event){
                        alert("no add action event")
                        return;
                    }
                    event = taskConst.getA11yNodeActionEventType(event, taskConst.actionType.node)
                    openHtmlShowA11yNodeInfoButton(event);
                    backToCaller();
                }
            }
        ]
    },
    {
        name:"sendMsg",
        children:[
            {
                name:"email"
            },
            {
                name:"wechat"
            }
        ]
    },
    {
        name:"popOpenTools",
        children: [
            {
                name:"a11yNodeRec"
            }
        ]
    }
]
const items:any[] = [];
flatTree(tree);
function flatTree(children:any){
    for (let i = 0; i < children.length; i++) {
        let child = children[i];
        if(child.children){
            flatTree(child.children);
        }else{
            items.push(child)
        }
    }
}
const dispatcher = new EventDispatcher();
export const actionDataConf = {
    treePrefix,
    dispatcher,
    autoExpand:false,
    showType:"tree", // tree or list
    tree,
    items,
}
