import {ComponentBase} from "#/libs/fanfanlo/react/components/ComponentBase";
import {Button, Link, Navbar, Page, PageContent} from "framework7-react";
import React from 'react';
import Dom7 from "dom7";
import i18n from "i18next";
import {autobind} from "core-decorators";
import ButtonComponent from "framework7/components/button/button";
import {checkOpenA11yPermissionWithAsk} from "#/htmls/index/androidjsbridge/a11yPermission";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";

class DangerousPermissionA11yContentProps {

}

class DangerousPermissionA11yContentState {

}

class DangerousPermissionA11yContent extends ComponentBase<DangerousPermissionA11yContentProps, DangerousPermissionA11yContentState> {
    constructor(props: DangerousPermissionA11yContentProps) {
        super(props);
    }

    render() {
        return(
            <div>
                <p>
                    此项功能能够对您的手机进行包括而不仅限于的点击、打开应用等类似真人一般的操作，也能打开一些支付应用进行支付，因为具有被记录操作、进行支付等的风险，所以我们强烈建议您只有在完全理解脚本内容的情况下才执行它。
                    {/*<br/>我们也会在脚本中心为您提供一些经审核为的安全脚本供您使用和学习。*/}
                </p>
                <p>
                    如何关闭此功能：打开本应用的首页，可以在首页设置里停止使用辅助功能，也可以在首页点击“关闭全部任务并退出”的按钮，这个按钮讲强制关闭所有正在执行的任务，并强制关闭本app。
                </p>
                <p>
                    我们使用此功能的原因是我们的应用需要有能力打开其它应用，并对其进行一些操控，例如浏览instagram时能够视频播放完毕后自动上滑，查看某个商品是否开售，定时给在线的朋友发送信息等有趣或省力的操作。
                </p>
            </div>
        )
    }
}

let pageCount = 0;

class DangerousPermissionA11yProps {

}

class DangerousPermissionA11yState {

}

class DangerousPermissionA11y extends ComponentBase<DangerousPermissionA11yProps, DangerousPermissionA11yState> {
    pageId = `dangerousPermissionA11yRouter-` + (++pageCount);
    pageSelectId = "#" + this.pageId;

    constructor(props: DangerousPermissionA11yProps) {
        super(props);
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    @autobind
    onPageInit(e: Event) {
        const page = arguments[1];
        if (!page) return;
        this.setState({pageParams: page.route.params})
    }

    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={dangerousPermissionA11yRouter.name} backLink="返回"/>

                <PageContent style={{margin:'0.5rem'}}>
                    <DangerousPermissionA11yContent/>
                </PageContent>
            </Page>
        )
    }
}
class DangerousPermissionA11yAllowPermission extends ComponentBase<DangerousPermissionA11yProps, DangerousPermissionA11yState> {
    pageId = `dangerousPermissionA11yRouter-` + (++pageCount);
    pageSelectId = "#" + this.pageId;

    constructor(props: DangerousPermissionA11yProps) {
        super(props);
    }


    addEvents() {
        super.addEvents();
        Dom7(this.pageSelectId).on("page:init", this.onPageInit);
    }

    removeEvents() {
        super.removeEvents();
        Dom7(this.pageSelectId).off("page:init", this.onPageInit);
    }

    @autobind
    onPageInit(e: Event) {
        const page = arguments[1];
        if (!page) return;
        this.setState({pageParams: page.route.params})
    }
    onAllowPermissionClick(){

        if(checkOpenA11yPermissionWithAsk()){
            AutoWebViewJs.instance.onCallOpenA11yPermission();
        }
    }
    render() {
        return (
            <Page id={this.pageId} pageContent={false} className={"no-toolbar"}>
                <Navbar title={dangerousPermissionA11yRouter.name} backLink="返回"/>

                <PageContent style={{margin:'0.5rem'}}>
                    <DangerousPermissionA11yContent/>
                    <Button onClick={this.onAllowPermissionClick}>我已知风险-去设置</Button>
                </PageContent>
            </Page>
        )
    }
}

export function DangerousPermissionA11yRouterLink() {
    return (<Link href={dangerousPermissionA11yRouter.path} color={"red"}>
        {i18n.t(`routes.dangerousPermissionA11yLink`)}
    </Link>)
}
export function DangerousPermissionA11yAllowPermissionRouterLink() {
    return (<Link href={dangerousPermissionA11yAllowPermissionRouter.path} color={"red"}>
        设置-危险
    </Link>)
}

//<Link href={dangerousPermissionA11yRouter.path}>{dangerousPermissionA11yRouter.name}</Link>
export const dangerousPermissionA11yAllowPermissionRouter = {
    id: "DangerousPermissionA11yAllowPermission",
    p: "/dangerous-permission-a11y-allow-permission/",
    path: "/dangerous-permission-a11y-allow-permission/",
    component: DangerousPermissionA11yAllowPermission,
    name: i18n.t(`routes.dangerousPermissionA11yTitle`),
    linkButton:DangerousPermissionA11yAllowPermissionRouterLink
}

export const dangerousPermissionA11yRouter = {
    id: "DangerousPermissionA11y",
    p: "/dangerous-permission-a11y/",
    path: "/dangerous-permission-a11y/",
    component: DangerousPermissionA11y,
    name: i18n.t(`routes.dangerousPermissionA11yTitle`),
    linkButton:DangerousPermissionA11yRouterLink
}
