import {DataEvent, Event2Any} from "../../../../../libs/fanfanlo/events/DataEvent";
import _ from "lodash";
import {
    IAction,
    IActionAlias,
    IActionData,
    IActionJavaData,
    IActionShellData
} from "#/htmls/index/vo/project/base/IAction";
import {ulid} from "ulid";
import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {Log} from "#/libs/fanfanlo/log/Log";
import {callLater} from "#/libs/fanfanlo/utils/callLater/callLater";
import {autobind, nonenumerable, readonly} from "core-decorators";
import {queueMicroTaskDecorator} from "#/libs/fanfanlo/utils/callLater/callLaterDecorator";
import i18n from "i18next";
import {IProject} from "#/htmls/index/vo/project/base/IProject";
import {ITask} from "#/htmls/index/vo/project/base/ITask";
import {createMyProjectsEventType, ProjectEditEnum} from "#/htmls/index/mc/editor/ProjectEditEnum";
import {getProjectByRouterCaller} from "#/htmls/index/mc/editor/scriptEditUtils";
import {ProjectBase} from "#/htmls/index/vo/project/base/ProjectBase";
import {MainRouter} from "#/htmls/index/pages/main/Main";
import {backOrToUrl, f7TryBack} from "#/libs/fanfanlo/f7/f7RouteUtils";
import {actionListRouter} from "#/htmls/index/pages/actionList/ActionList";
import {actionDataConf} from "#/htmls/index/pages/actionList/data";
import {actionSelectOpenAppRouter} from "#/htmls/index/pages/actionSelectOpenApp/ActionSelectOpenApp";
import {actionSelectOpenAppRender2Conf} from "#/htmls/index/pages/actionSelectOpenApp/ItemRender2";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {RhinoScriptEdtorRouter} from "#/htmls/index/pages/RhinoScriptEdtor/RhinoScriptEdtor";
import {IActionGroup} from "#/htmls/index/vo/project/base/IActionGroup";

let actionIndex = 0;

function createUlid(){
    let id = ulid()
    // console.trace("create ulid", id);
    return id;
}
@autobind
export class ActionBase<T extends IActionShellData, U extends IActionJavaData> extends EventDispatcher implements IAction<T, U> {
    static eventTypes = {
        actionUpdated: "actionUpdated"
    }
    @nonenumerable
    @readonly
    logger = Log.createCountedLogger(false, this.constructor.name)
    type = this.constructor.prototype.constructor.type
    @nonenumerable
    private aIndex = actionIndex++;
    javaData: U = this.returnOrCreateJavaData()
    shellData: T = this.returnOrCreateShellData()
    // createUIContent!: (action: IAction<T, U>) => void;
    @nonenumerable
    project?: IProject;
    @nonenumerable
    task?: ITask;

    constructor() {
        super();
    }

    createUI(): React.JSX.Element {
        throw new Error("Method not implemented.");
    }

    actionGroup?: IActionGroup;

    getUITitle(): string {
        return i18n.t(`project.task.action.${this.type}.name`)
    }

    init () {
        callLater(this.addEvents, 1)
    }
    protected addEvents (){
        let dispatcher = this.project?.myProjects;
        dispatcher?.addDomainEventListener(this, this.getEventType(ProjectEditEnum.Update), this.onEditUpdate)
    }
    getEventType(editType:ProjectEditEnum){
        let action = this;
        let task = action.task;
        let project = task?.project;
        let group = this.actionGroup as unknown as IAction<IActionShellData, IActionJavaData>;
        let type = createMyProjectsEventType(editType, project?.javaData.id, task?.javaData?.id, action.javaData.id, group?.javaData.id)
        // console.log("action base event type is", type);
        return type;
    }
    // 子级应该覆写这个方法。
    protected onEditUpdate(event:Event2Any){
        throw new Error("subclass should override this function")
        // let data:any = {packageName:event.data.packageName}
        // this.updateJavaData(data)
    }
    protected removeEvents(){
        let dispatcher = this.project?.myProjects
        dispatcher?.removeDomain(this)
    }
    @queueMicroTaskDecorator()
    dispatchUpdateEvent = () => {
        this.dispatchEvent(new DataEvent(ActionBase.eventTypes.actionUpdated));
        this.project?.dispatchActionUpdate(this as unknown as IActionAlias);
    }

    setEmptyData(){
        this.updateJavaData(this.returnOrCreateJavaData())
        this.updateShellData(this.returnOrCreateShellData())
    }
    get data():IActionData<T, U> {
        let o = {javaData: _.merge({}, this.javaData), shellData: _.merge({}, this.shellData)};
        return o
    }

    set data(d:IActionData<T, U>){
        if(!d)return;
        this.updateJavaData(this.returnOrCreateJavaData(d.javaData))
        this.updateShellData(this.returnOrCreateShellData(d.shellData))

    }

    updateJavaData (v: U) {
        let old = this.javaData;
        let o = this.returnOrCreateJavaData(v);
        this.javaData = o
        // if(old && old.id != o.id){
        //     console.error("updateJavaData id changed")
        //     console.trace("updateJavaData id changed", old.id, o.id);
        // }
        this.dispatchUpdateEvent()
    }

    updateShellData(data:T){
        this.shellData = data;
        this.dispatchUpdateEvent();
    }

    getMissionData() {
        return {type: this.type}
    }

    getMissionCode() {
        return JSON.stringify(this.getMissionData())
    }

    protected returnOrCreateJavaData(v?:U):U{
        let old = this.javaData?.id
        v = this.mergeOldJavaData(v);
        // if(old && old != v?.id){
        //     console.error("returnOrCreateJavaData mergeOldJavaData old != v.id", old, v?.id);
        //     console.dir("returnOrCreateJavaData mergeOldJavaData mergeOldJavaData")
        // }
        return _.merge(this.createJavaData(), v)
    }
    // 更新javaData时可以覆盖该方法
    protected mergeOldJavaData(v?:U):U|undefined{
        let o =  _.merge({}, this.javaData, v)
        return o;
    }
    protected createJavaData():U{
        let {print, error, warn, logger} = this.logger.sub(false, "fn_createJavaData");
        // if(this.javaData){
        //     print("has old javaData", this.javaData.id, this.type)
        //     console.trace(this.javaData.id)
        // }
        return {id: createUlid(), type: this.type || this.constructor.prototype.constructor.type} as U
    }
    protected returnOrCreateShellData(v?:T):T{
        this.mergetOldShellData(v)
        return _.merge(this.createShellData(), v)
    }
    // 更新shellData时可以覆盖该方法
    protected mergetOldShellData(v?:T):T|undefined{
        return _.merge(this.shellData, v);
    }
    protected createShellData():T{
        return {id:ulid()} as T
    }

    dismiss():void{
        this.removeEvents()
    }

    getPermissions(): string[] | undefined {
        return undefined
    }
}
