import {ulid} from "ulid";
import _ from "lodash";

export type WebViewJsParams = {
    fitContentWidth?:true,
    fitContentHeight?:true,
    javaToJsDataString?:``
}

export type WebViewConf = {
    backgroundColor?:0
}


export type HtmlConf = {
    url?:``,
    webViewJsParams?:WebViewJsParams,
    webViewConf?:WebViewConf,
    jsBridgeName?:"android",
    webViewJsName?:"AutoWebViewJs",
    extra:{
        // javaRunnerId:javaRunner.id,
    }
}

export type FloatConf = {
    positionSaveName?:"${positionSaveName}",
    draggable?:true
}
export type FloatWindowConf = {
    floatConf?:FloatConf
}

export type WindowConf = {
    contentType?:"html",
    windowType?:"float",
    htmlConf?:HtmlConf,
    floatWindowConf?:FloatWindowConf,
    windowId?:string// ulid()
}


export const webViewJsParams:WebViewJsParams = {
    fitContentWidth:true,
    fitContentHeight:true,
    javaToJsDataString:``
}

export const webViewConf:WebViewConf = {
    backgroundColor:0
}

export const htmlConf:HtmlConf = {
    url:``,
    webViewJsParams:webViewJsParams,
    webViewConf:webViewConf,
    jsBridgeName:"android",
    webViewJsName:"AutoWebViewJs",
    extra:{
        // javaRunnerId:javaRunner.id,
    }
}

export const floatConf:FloatConf = {
    positionSaveName:"${positionSaveName}",
    draggable:true
}
export const floatWindowConf:FloatWindowConf = {
    floatConf:floatConf
}

export const windowConf:WindowConf = {
    contentType:"html",
    windowType:"float",
    htmlConf:htmlConf,
    floatWindowConf:floatWindowConf,
    windowId:ulid()
}

export function createWindowConfCopy(conf:any){
    return _.merge({}, windowConf, conf);
}