import _ from "lodash";
import {callLater, callQueueMicroTask} from "#/libs/fanfanlo/utils/callLater/callLater";

type isSame = boolean
export type compareArgumentsFunc = (old: any, now: any) => isSame;

type Fn = (c:()=>void, repeatable:boolean)=>void

function createDecorator(fn:Fn, compareArguments?: boolean | compareArgumentsFunc | string){
    return function (target: any, key: string) {
        let descriptor = arguments[2];
        if (!descriptor) throw new Error("decorator function | no descriptor")
        var that: any = target;
        var args: any[] = [];
        var value = descriptor.value;

        function call() {
            value.apply(that, args)
        }

        descriptor.value = function () {
            that = this;
            let repeatable = _.isFunction(compareArguments) ? compareArguments(args, arguments) :
                _.isString(compareArguments) ? this[compareArguments].call(this, args, arguments) :
                    _.isBoolean(compareArguments) ? compareArguments : false
            args = Array.from(arguments)
            let c = call;
            if (repeatable) {
                c = () => {
                    value.apply(this, arguments)
                }
            }
            fn(c, repeatable)
        }
        return descriptor;
    }
}

export function queueMicroTaskDecorator(compareArguments?: boolean | compareArgumentsFunc | string) {
    return createDecorator((c:()=>void, repeatable:boolean)=>{
        callQueueMicroTask(c, repeatable)
    }, compareArguments)
}


export function callLaterDecorator(delay:number, compareArguments?: boolean | compareArgumentsFunc | string){
    return createDecorator((c:()=>void, repeatable:boolean)=>{
        callLater(c, delay, repeatable)
    }, compareArguments)
}